Object.defineProperty(exports, "__esModule", { value: true });
exports.getResizedUrl = void 0;
var integration_service_1 = require("../integration-service");
var LEGACY_DOMAIN = 'ondemandkorea.com';
var ImageRatio = {
    thumbnail: 16 / 9,
    poster: 972 / 1440,
    backdrop: 16 / 9,
    banner: 16 / 5,
    channel_wide_logo: 4 / 3,
};
var ImageBaseWidth = {
    thumbnail: 444,
    poster: 427,
    backdrop: 2560,
    banner: 2560,
    channel_wide_logo: 427,
};
var isLegacy = function (host) { return host === null || host === void 0 ? void 0 : host.includes(LEGACY_DOMAIN); };
var isImaginary = function (url) {
    if (!url)
        return false;
    return /\?width=\d+(&|$)|&width=\d+(&|$)|\?height=\d+(&|$)|&height=\d+(&|$)/.test(url);
};
var toLegacyUrl = function (imagePath, width, height) {
    return "https://sp.ondemandkorea.com/includes/timthumb.php?w=".concat(width, "&src=").concat(imagePath);
};
var toFitInUrl = function (host, imagePath, width, height) {
    var pattern = '(^/fit-in/.+?x.+?/)';
    var currentFitIn = imagePath.match(pattern);
    var fitInExist = (currentFitIn === null || currentFitIn === void 0 ? void 0 : currentFitIn.length) > 0;
    if (fitInExist) {
        return "".concat(host).concat(imagePath.replace(currentFitIn[1], "/fit-in/".concat(width, "x0/")));
    }
    return "".concat(host, "/fit-in/").concat(width, "x0").concat(imagePath);
};
var toResizedImaginaryUrl = function (url, width, height) {
    url = url.replace(/([?&]width=)\d+(?=&|$)/, width ? "$1".concat(width) : "$1".concat(0));
    url = url.replace(/([?&]height=)\d+(?=&|$)/, height ? "$1".concat(height) : "$1".concat(0));
    return url;
};
var getResizedUrl = function (imageType, url) {
    var _a, _b;
    var urlInfo;
    try {
        urlInfo = new URL(url);
    }
    catch (e) {
        return '';
    }
    var imageSizePref = ((_a = integration_service_1.OdxIntegration.currentProvider) === null || _a === void 0 ? void 0 : _a.getImageSizePref)
        ? (_b = integration_service_1.OdxIntegration.currentProvider) === null || _b === void 0 ? void 0 : _b.getImageSizePref()
        : ImageBaseWidth;
    var legacy = isLegacy(urlInfo.host);
    var imaginary = isImaginary(url);
    var width = Math.round(imageSizePref[imageType]);
    var height = Math.round(width * (1 / ImageRatio[imageType]));
    if (legacy) {
        return toLegacyUrl(urlInfo.pathname, width, height);
    }
    if (imaginary) {
        return toResizedImaginaryUrl(url, width, height);
    }
    return toFitInUrl(urlInfo.origin, urlInfo.pathname, width, height);
};
exports.getResizedUrl = getResizedUrl;
