import { store } from '~store';
import { PaymentApiInterface, UserApiInterface } from '~vimai-api-adapter';
import {
  ApplyCouponForCustomerRequestSeriazlier,
  UpdateUserChatRequest,
} from '~vimai-api-adapter/api-adapters/api-adapter-vimai/models';
import { IAxiosResponseData } from '~core/models';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';
import { getAccessToken } from '~utils/utils';

const { userService, paymentService } = apiAdapter;

const postCouponCancel = (
  params: ApplyCouponForCustomerRequestSeriazlier,
): Promise<IAxiosResponseData<PaymentApiInterface['applyCouponForUser']> | void> => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return Promise.resolve();
  }
  const data = params;
  const { accountId = '', tenantSlug = '', authorization } = config;
  return paymentService
    .applyCouponForUser(accountId, tenantSlug, data, authorization)
    .then(axiosTakeDataFromResponse);
};

const getPaypalMethod = (): Promise<IAxiosResponseData<
  PaymentApiInterface['getPaypalPayers']
> | void> => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return Promise.resolve();
  }
  const { accountId = '', tenantSlug = '', authorization } = config;

  return paymentService
    .getPaypalPayers(accountId, tenantSlug, authorization)
    .then(axiosTakeDataFromResponse);
};

export const chatUserInfor = (): Promise<
  IAxiosResponseData<UserApiInterface['generateChatUserInfoApi']>
> => {
  const { auth } = store.getState() as any;
  const { account } = auth;

  const accountId = (account && account.profile && account.profile.id) || 'anonymous';
  const { tenantSlug = '' } = config;
  const accessToken = getAccessToken(false, auth);

  return userService
    .generateChatUserInfoApi(accountId, tenantSlug, accessToken)
    .then(axiosTakeDataFromResponse);
};

export const updateNicknameChat = (params: UpdateUserChatRequest): Promise<void> | void => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return;
  }
  const { accountId = '', tenantSlug = '', authorization } = config;

  const { nickname } = params;
  return userService
    .updateNicknameUserChatApi(
      accountId,
      tenantSlug,
      {
        nickname,
      },
      authorization,
    )
    .then(axiosTakeDataFromResponse);
};

export { postCouponCancel, getPaypalMethod };
