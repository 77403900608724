import { Login } from '~vimai-api-adapter/api-adapters/api-adapter-vimai/models';

export function hasSubscription(this: Login) {
  const self: Login = this;
  if (!self) {
    return false;
  }
  return self.profile && self.profile.has_subscription;
}

export function hasPassword(this: Login) {
  try {
    const { login_method = '' } = this;
    return ['email', 'mobile'].includes(login_method);
  } catch (error) {
    return false;
  }
}
