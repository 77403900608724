// import type {
//   AdsManagementApiInterface,
//   AppVersionApiInterface,
//   AuthenticationApiInterface,
//   ConfigApiInterface,
//   ContentApiInterface,
//   ContentListingApiInterface,
//   ClientEventsApiInterface,
//   DocumentApiInterface,
//   GeoBlockingApiInterface,
//   MultitenancyApiInterface,
//   PaymentApiInterface,
//   UserApiInterface,
//   UserContentApiInterface,
//   UserSurveyApiInterface,
//   AdminContentApiInterface,
//   AdminDocumentApiInterface,
// } from '../api-adapters/api-adapter-vimai/apis'
Object.defineProperty(exports, "__esModule", { value: true });
