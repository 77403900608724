var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OdxAdsManagementApi = void 0;
var integration_service_1 = require("../integration-service");
var content_utils_1 = require("../utils/content-utils");
var cacheable_requests_1 = require("../requests/cacheable-requests");
var constants_1 = require("../constants");
var utils_1 = require("../utils");
var OdxAdsManagementApi = /** @class */ (function (_super) {
    __extends(OdxAdsManagementApi, _super);
    function OdxAdsManagementApi() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getContentVideoAds = function () {
            return Promise.reject('Method not implemented');
        };
        return _this;
    }
    OdxAdsManagementApi.prototype.adsText = function (adsFileName, _var, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAdsManagementApi.prototype.getAdsBanners = function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, position, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAdsManagementApi.prototype.getContentVmapAdsTag = function (contentId, tenantPlatformSlug, tenantSlug, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAdsManagementApi.prototype.getCurrentAdsBanner = function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, options) {
        return Promise.reject('Method not implemented');
    };
    OdxAdsManagementApi.prototype.getListDisabledAds = function (options) {
        return Promise.reject('Method not implemented');
    };
    OdxAdsManagementApi.prototype.getPlatformVideoAdsInfo = function (tenantPlatformSlug, tenantSlug, authorization, acceptLanguage, contentId, adParams, options) {
        var _this = this;
        integration_service_1.OdxIntegration.currentService.setApiKey(authorization);
        var _a = (0, content_utils_1.getIdInfoFromContentIdentity)(contentId), id = _a.id, slug = _a.slug, type = _a.type;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var episodeId, _a, movieInfo, xAdParams, isLat, did, adid, playback, e_1;
            var _b, _c, _d, _e, _f, _g;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        _h.trys.push([0, 6, , 7]);
                        episodeId = void 0;
                        _a = type;
                        switch (_a) {
                            case 'show': return [3 /*break*/, 1];
                            case 'movie': return [3 /*break*/, 1];
                        }
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, cacheable_requests_1.CacheableRequests.getProgramInformation(slug, tenantSlug, acceptLanguage)];
                    case 2:
                        movieInfo = _h.sent();
                        episodeId = (_d = (_c = (_b = movieInfo.data) === null || _b === void 0 ? void 0 : _b.result) === null || _c === void 0 ? void 0 : _c.direct_play_episode) === null || _d === void 0 ? void 0 : _d.id;
                        return [3 /*break*/, 4];
                    case 3:
                        episodeId = id;
                        _h.label = 4;
                    case 4:
                        xAdParams = integration_service_1.OdxIntegration.currentProvider.get('adParams');
                        isLat = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'is_lat');
                        did = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'did');
                        adid = (0, utils_1.getValueOfKeyPairString)(xAdParams, 'adid');
                        return [4 /*yield*/, this.odxService.odxPlaybackApi.playbackRetrieve(episodeId.toString(), tenantSlug, undefined, acceptLanguage, isLat ? decodeURIComponent(isLat) : undefined, did ? decodeURIComponent(did) : undefined, adid ? decodeURIComponent(adid) : undefined)];
                    case 5:
                        playback = _h.sent();
                        resolve(__assign(__assign({}, playback), { data: {
                                name: constants_1.ODXKey.defaultUnknown,
                                ad_tag: {
                                    id: contentId,
                                    ad_tag_url: (_g = (_f = (_e = playback.data) === null || _e === void 0 ? void 0 : _e.result) === null || _f === void 0 ? void 0 : _f.ad_tag) === null || _g === void 0 ? void 0 : _g.ad_tag_url,
                                },
                            } }));
                        return [3 /*break*/, 7];
                    case 6:
                        e_1 = _h.sent();
                        reject((0, utils_1.transformError)(e_1));
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); });
    };
    return OdxAdsManagementApi;
}(integration_service_1.OdxIntegration));
exports.OdxAdsManagementApi = OdxAdsManagementApi;
