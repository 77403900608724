Object.defineProperty(exports, "__esModule", { value: true });
exports.VimaiApiAdapter = void 0;
var api_service_proxy_1 = require("../utils/api-service-proxy");
var authentication_api_1 = require("../../api-adapters/api-adapter-vimai/apis/authentication-api");
var content_listing_api_1 = require("../../api-adapters/api-adapter-vimai/apis/content-listing-api");
var content_api_1 = require("../../api-adapters/api-adapter-vimai/apis/content-api");
var client_events_api_1 = require("../../api-adapters/api-adapter-vimai/apis/client-events-api");
var document_api_1 = require("../../api-adapters/api-adapter-vimai/apis/document-api");
var geo_blocking_api_1 = require("../../api-adapters/api-adapter-vimai/apis/geo-blocking-api");
var ads_management_api_1 = require("../../api-adapters/api-adapter-vimai/apis/ads-management-api");
var multitenancy_api_1 = require("../../api-adapters/api-adapter-vimai/apis/multitenancy-api");
var payment_api_1 = require("../../api-adapters/api-adapter-vimai/apis/payment-api");
var app_version_api_1 = require("../../api-adapters/api-adapter-vimai/apis/app-version-api");
var user_content_api_1 = require("../../api-adapters/api-adapter-vimai/apis/user-content-api");
var user_api_1 = require("../../api-adapters/api-adapter-vimai/apis/user-api");
var config_api_1 = require("../../api-adapters/api-adapter-vimai/apis/config-api");
var user_survey_api_1 = require("../../api-adapters/api-adapter-vimai/apis/user-survey-api");
var admin_content_api_1 = require("../../api-adapters/api-adapter-vimai/apis/admin-content-api");
var admin_document_api_1 = require("../../api-adapters/api-adapter-vimai/apis/admin-document-api");
var VimaiApiAdapter = /** @class */ (function () {
    function VimaiApiAdapter(configs) {
        if (configs === void 0) { configs = {}; }
        this.configuration = configs.configuration;
        this.debug = configs.debug;
        this.baseUrl = configs.baseUrl;
        this.axiosInstance = configs.axiosInstance;
        this.parameterProvider = configs.parameterProvider;
        this.initializeServices();
    }
    VimaiApiAdapter.prototype.create = function (cls) {
        var provider = this.parameterProvider;
        var s = new cls(this.configuration, this.baseUrl, this.axiosInstance);
        return provider ? (0, api_service_proxy_1.applyProxy)(s, provider, this.debug) : s;
    };
    VimaiApiAdapter.prototype.initializeServices = function () {
        this.authenticationService = this.create(authentication_api_1.AuthenticationApi);
        this.contentListingService = this.create(content_listing_api_1.ContentListingApi);
        this.contentService = this.create(content_api_1.ContentApi);
        this.clientEventService = this.create(client_events_api_1.ClientEventsApi);
        this.documentService = this.create(document_api_1.DocumentApi);
        this.geoBlockingService = this.create(geo_blocking_api_1.GeoBlockingApi);
        this.adsManagementService = this.create(ads_management_api_1.AdsManagementApi);
        this.multitenancyService = this.create(multitenancy_api_1.MultitenancyApi);
        this.paymentService = this.create(payment_api_1.PaymentApi);
        this.appVersionService = this.create(app_version_api_1.AppVersionApi);
        this.userContentService = this.create(user_content_api_1.UserContentApi);
        this.userService = this.create(user_api_1.UserApi);
        this.configService = this.create(config_api_1.ConfigApi);
        this.userSurveyService = this.create(user_survey_api_1.UserSurveyApi);
        this.adminContentService = this.create(admin_content_api_1.AdminContentApi);
        this.adminDocumentService = this.create(admin_document_api_1.AdminDocumentApi);
    };
    return VimaiApiAdapter;
}());
exports.VimaiApiAdapter = VimaiApiAdapter;
