/* tslint:disable */
/* eslint-disable */
/**
 * ODX/V3 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserFavoritesApi = exports.UserFavoritesApiFactory = exports.UserFavoritesApiFp = exports.UserFavoritesApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * UserFavoritesApi - axios parameter creator
 * @export
 */
var UserFavoritesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary user favorites create
         * @param {'odk' | 'odc' | 'odv' | 'odl'} serviceName
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3FavoritesInputSchema} [odxV3FavoritesInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritesCreate: function (serviceName, debug, acceptLanguage, odxV3FavoritesInputSchema, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('userFavoritesCreate', 'serviceName', serviceName);
                            localVarPath = "/user/favorites/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(odxV3FavoritesInputSchema, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary user favorites delete
         * @param {string} slug
         * @param {'odk' | 'odc' | 'odv' | 'odl'} serviceName
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritesDelete: function (slug, serviceName, debug, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'slug' is not null or undefined
                            (0, common_1.assertParamExists)('userFavoritesDelete', 'slug', slug);
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('userFavoritesDelete', 'serviceName', serviceName);
                            localVarPath = "/user/favorites/{slug}/"
                                .replace("{".concat("slug", "}"), encodeURIComponent(String(slug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary user favorites list
         * @param {'odk' | 'odc' | 'odv' | 'odl'} serviceName
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {number} [page] The default value is &#x60;1&#x60;
         * @param {number} [pageSize] The default value is &#x60;10&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritesList: function (serviceName, acceptLanguage, debug, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('userFavoritesList', 'serviceName', serviceName);
                            localVarPath = "/user/favorites/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary user favorites remove all
         * @param {'odk' | 'odc' | 'odv' | 'odl'} serviceName
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritesRemoveAll: function (serviceName, debug, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'serviceName' is not null or undefined
                            (0, common_1.assertParamExists)('userFavoritesRemoveAll', 'serviceName', serviceName);
                            localVarPath = "/user/favorites/remove_all/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication RequiredToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication RequiredToken required
                            _a.sent();
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            if (serviceName !== undefined && serviceName !== null) {
                                localVarHeaderParameter['Service-Name'] = String(serviceName);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.UserFavoritesApiAxiosParamCreator = UserFavoritesApiAxiosParamCreator;
/**
 * UserFavoritesApi - functional programming interface
 * @export
 */
var UserFavoritesApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.UserFavoritesApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary user favorites create
         * @param {'odk' | 'odc' | 'odv' | 'odl'} serviceName
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3FavoritesInputSchema} [odxV3FavoritesInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritesCreate: function (serviceName, debug, acceptLanguage, odxV3FavoritesInputSchema, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userFavoritesCreate(serviceName, debug, acceptLanguage, odxV3FavoritesInputSchema, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary user favorites delete
         * @param {string} slug
         * @param {'odk' | 'odc' | 'odv' | 'odl'} serviceName
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritesDelete: function (slug, serviceName, debug, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userFavoritesDelete(slug, serviceName, debug, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary user favorites list
         * @param {'odk' | 'odc' | 'odv' | 'odl'} serviceName
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {number} [page] The default value is &#x60;1&#x60;
         * @param {number} [pageSize] The default value is &#x60;10&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritesList: function (serviceName, acceptLanguage, debug, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userFavoritesList(serviceName, acceptLanguage, debug, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary user favorites remove all
         * @param {'odk' | 'odc' | 'odv' | 'odl'} serviceName
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritesRemoveAll: function (serviceName, debug, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userFavoritesRemoveAll(serviceName, debug, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.UserFavoritesApiFp = UserFavoritesApiFp;
/**
 * UserFavoritesApi - factory interface
 * @export
 */
var UserFavoritesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.UserFavoritesApiFp)(configuration);
    return {
        /**
         *
         * @summary user favorites create
         * @param {'odk' | 'odc' | 'odv' | 'odl'} serviceName
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {OdxV3FavoritesInputSchema} [odxV3FavoritesInputSchema]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritesCreate: function (serviceName, debug, acceptLanguage, odxV3FavoritesInputSchema, options) {
            return localVarFp.userFavoritesCreate(serviceName, debug, acceptLanguage, odxV3FavoritesInputSchema, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary user favorites delete
         * @param {string} slug
         * @param {'odk' | 'odc' | 'odv' | 'odl'} serviceName
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritesDelete: function (slug, serviceName, debug, acceptLanguage, options) {
            return localVarFp.userFavoritesDelete(slug, serviceName, debug, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary user favorites list
         * @param {'odk' | 'odc' | 'odv' | 'odl'} serviceName
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {number} [page] The default value is &#x60;1&#x60;
         * @param {number} [pageSize] The default value is &#x60;10&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritesList: function (serviceName, acceptLanguage, debug, page, pageSize, options) {
            return localVarFp.userFavoritesList(serviceName, acceptLanguage, debug, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary user favorites remove all
         * @param {'odk' | 'odc' | 'odv' | 'odl'} serviceName
         * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
         * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFavoritesRemoveAll: function (serviceName, debug, acceptLanguage, options) {
            return localVarFp.userFavoritesRemoveAll(serviceName, debug, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.UserFavoritesApiFactory = UserFavoritesApiFactory;
/**
 * UserFavoritesApi - object-oriented interface
 * @export
 * @class UserFavoritesApi
 * @extends {BaseAPI}
 */
var UserFavoritesApi = /** @class */ (function (_super) {
    __extends(UserFavoritesApi, _super);
    function UserFavoritesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary user favorites create
     * @param {'odk' | 'odc' | 'odv' | 'odl'} serviceName
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {OdxV3FavoritesInputSchema} [odxV3FavoritesInputSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserFavoritesApi
     */
    UserFavoritesApi.prototype.userFavoritesCreate = function (serviceName, debug, acceptLanguage, odxV3FavoritesInputSchema, options) {
        var _this = this;
        return (0, exports.UserFavoritesApiFp)(this.configuration).userFavoritesCreate(serviceName, debug, acceptLanguage, odxV3FavoritesInputSchema, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary user favorites delete
     * @param {string} slug
     * @param {'odk' | 'odc' | 'odv' | 'odl'} serviceName
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserFavoritesApi
     */
    UserFavoritesApi.prototype.userFavoritesDelete = function (slug, serviceName, debug, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserFavoritesApiFp)(this.configuration).userFavoritesDelete(slug, serviceName, debug, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary user favorites list
     * @param {'odk' | 'odc' | 'odv' | 'odl'} serviceName
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {number} [page] The default value is &#x60;1&#x60;
     * @param {number} [pageSize] The default value is &#x60;10&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserFavoritesApi
     */
    UserFavoritesApi.prototype.userFavoritesList = function (serviceName, acceptLanguage, debug, page, pageSize, options) {
        var _this = this;
        return (0, exports.UserFavoritesApiFp)(this.configuration).userFavoritesList(serviceName, acceptLanguage, debug, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary user favorites remove all
     * @param {'odk' | 'odc' | 'odv' | 'odl'} serviceName
     * @param {string} [debug] If value is &#x60;\&quot;on\&quot;&#x60;, &#x60;debug&#x60; is included in response.
     * @param {'ko' | 'en' | 'es' | 'zh-Hans' | 'zh-Hant' | 'zh-hans' | 'zh-hant' | 'pt'} [acceptLanguage] &#x60;\&quot;zh-Hans\&quot;&#x60;, &#x60;\&quot;zh-Hant\&quot;&#x60; is deprecated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserFavoritesApi
     */
    UserFavoritesApi.prototype.userFavoritesRemoveAll = function (serviceName, debug, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserFavoritesApiFp)(this.configuration).userFavoritesRemoveAll(serviceName, debug, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UserFavoritesApi;
}(base_1.BaseAPI));
exports.UserFavoritesApi = UserFavoritesApi;
