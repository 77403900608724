import { store } from '~store';

import _ from 'lodash';
import { AuthenticationApiInterface, ContentListingApiInterface } from '~vimai-api-adapter';
import {
  LoginAccountByGoogleRequest,
  LoginSNRequest,
  LoginViaAppleOnOtherPlatformRequest,
} from '~vimai-api-adapter/api-adapters/api-adapter-vimai/models';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';
import { isNode } from '~utils/ssr';
import { IAxiosResponseData, Pagination } from '~core/models';

const { authenticationService, contentListingService } = apiAdapter;

const loginSSO = (
  data: { sso_token?: string } = {},
): Promise<IAxiosResponseData<AuthenticationApiInterface['loginWithSsoToken']>> => {
  const { sso_token } = data;
  const { tenantSlug = '', authorization } = config;
  return authenticationService
    .loginWithSsoToken(tenantSlug, authorization, sso_token)
    .then(axiosTakeDataFromResponse);
};

const getPaidContent = (
  params: { version: string; type: 'active' | 'all' | 'deactive' } & Pagination,
): Promise<
  | IAxiosResponseData<ContentListingApiInterface['getPaidContents']>
  | ContentListingApiInterface['getAccountPaidContents']
  | void
> => {
  const { account } = (store.getState() as any).auth;
  const { version = '', page, limit, type } = params || {};

  if (!account) return Promise.resolve();
  const { accountId = '', tenantSlug = '', authorization } = config;

  if (version) {
    return contentListingService
      .getPaidContents(accountId, tenantSlug, authorization, undefined, page, limit, type)
      .then(axiosTakeDataFromResponse);
  }

  return contentListingService
    .getAccountPaidContents(accountId, tenantSlug, authorization, undefined, page, limit, type)
    .then(axiosTakeDataFromResponse);
};

const loginGoogle = (
  data: { token?: LoginAccountByGoogleRequest['token'] } = {},
): Promise<IAxiosResponseData<AuthenticationApiInterface['loginWithGoogle']>> => {
  const { token } = data;
  const payload: LoginAccountByGoogleRequest = {
    token,
  };
  const { tenantSlug = '' } = config;
  return authenticationService.loginWithGoogle(tenantSlug, payload).then(axiosTakeDataFromResponse);
};

const loginGuestAccount = (): Promise<IAxiosResponseData<
  AuthenticationApiInterface['createGuestAccount']
> | null> => {
  if (isNode()) {
    return Promise.resolve(null);
  }
  const { tenantSlug = '' } = config;
  return authenticationService.createGuestAccount(tenantSlug).then(axiosTakeDataFromResponse);
};

const loginKaKaoTalk = (
  data: { token?: LoginSNRequest['token'] } = {},
  snType: any,
): Promise<IAxiosResponseData<AuthenticationApiInterface['loginWithSocialNetwork']>> | void => {
  // if (_.isEmpty(data) || !snType) {
  //   return;
  // }
  const { token } = data;
  const payload: LoginSNRequest = {
    token,
  };
  const { tenantSlug = '' } = config;
  return authenticationService
    .loginWithSocialNetwork(tenantSlug, snType, payload)
    .then(axiosTakeDataFromResponse);
};

const loginNaver = (
  data: { token?: LoginSNRequest['token'] } = {},
  snType: any,
): Promise<IAxiosResponseData<AuthenticationApiInterface['loginWithSocialNetwork']>> | void => {
  // if (_.isEmpty(data) || !snType) {
  //   return;
  // }
  const { token } = data;
  const payload: LoginSNRequest = {
    token,
  };
  const { tenantSlug = '' } = config;
  return authenticationService
    .loginWithSocialNetwork(tenantSlug, snType, payload)
    .then(axiosTakeDataFromResponse);
};

export const loginWithApple = (
  payload: LoginViaAppleOnOtherPlatformRequest,
): Promise<IAxiosResponseData<AuthenticationApiInterface['loginWithAppleOtherOs']>> => {
  const { tenantSlug = '' } = config;

  return authenticationService
    .loginWithAppleOtherOs(tenantSlug, payload)
    .then(axiosTakeDataFromResponse);
};

export { loginSSO, getPaidContent, loginGoogle, loginGuestAccount, loginKaKaoTalk, loginNaver };
