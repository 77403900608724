Object.defineProperty(exports, "__esModule", { value: true });
exports.filterRequestSelection = exports.isRequestDemanded = void 0;
var utils_1 = require("../utils");
var isRequestDemanded = function (select, proms) {
    if (!select) {
        return true;
    }
    var selection = JSON.parse(select);
    for (var _i = 0, proms_1 = proms; _i < proms_1.length; _i++) {
        var prom = proms_1[_i];
        var p = prom.split('.');
        var model = p[0];
        var field = p[1];
        if (model in selection && selection[model].includes(field)) {
            return true;
        }
    }
    return false;
};
exports.isRequestDemanded = isRequestDemanded;
var filterRequestSelection = function (select, filterModel, response) {
    if (!select) {
        return response;
    }
    var selection = JSON.parse(select);
    if (!(filterModel in selection)) {
        return response;
    }
    var fields = selection[filterModel];
    var obj = {};
    for (var _i = 0, fields_1 = fields; _i < fields_1.length; _i++) {
        var field = fields_1[_i];
        try {
            obj[field] = response[field];
        }
        catch (e) {
            (0, utils_1.noop)(e);
        }
    }
    return obj;
};
exports.filterRequestSelection = filterRequestSelection;
