Object.defineProperty(exports, "__esModule", { value: true });
exports.transformError = exports.raiseForPlaybackErrorResponse = exports.isAnonymousBlocked = exports.isPayPerViewBlocked = exports.isGeoBlocking = exports.isMembershipBlocked = exports.isConcurrentLimitBlocked = exports.getPageDisplayStyleFromNavigationItem = exports.arrEleMove = exports.getGenderNumber = exports.getValueOfKeyPairString = exports.defaultConfig = exports.noop = void 0;
var models_1 = require("../../api-adapter-vimai/models");
var errors_1 = require("../errors");
var noop = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
};
exports.noop = noop;
exports.defaultConfig = {
    isJsonMime: function (_) {
        return false;
    },
};
var getValueOfKeyPairString = function (s, key) {
    var _a;
    var keyValuePairs = (s === null || s === void 0 ? void 0 : s.split(',')) || [];
    return (_a = keyValuePairs.find(function (pair) { return pair.startsWith("".concat(key, "=")); })) === null || _a === void 0 ? void 0 : _a.substring("".concat(key, "=").length);
};
exports.getValueOfKeyPairString = getValueOfKeyPairString;
var getGenderNumber = function (gender) {
    switch (gender) {
        case 'm':
            return 1;
        case 'f':
            return 2;
        default:
            return 0;
    }
};
exports.getGenderNumber = getGenderNumber;
function arrEleMove(arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
        var k = newIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
}
exports.arrEleMove = arrEleMove;
var getPageDisplayStyleFromNavigationItem = function (navigation) {
    if (navigation.slug === 'odk-channels') {
        return models_1.MenuItemDetailDisplayStyleEnum.Channels;
    }
    if (navigation.type === 'category') {
        return models_1.MenuItemDetailDisplayStyleEnum.TabbedBase;
    }
    return models_1.MenuItemDetailDisplayStyleEnum.Normal;
};
exports.getPageDisplayStyleFromNavigationItem = getPageDisplayStyleFromNavigationItem;
var isConcurrentLimitBlocked = function (e) {
    var _a, _b, _c;
    var errors = ['simultaneous-watch-limit', 'concurrent-watching-limit'];
    var block = (_c = (_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.messages) === null || _c === void 0 ? void 0 : _c.__block;
    return errors.includes(block === null || block === void 0 ? void 0 : block.type) || errors.includes(block === null || block === void 0 ? void 0 : block.block_type);
};
exports.isConcurrentLimitBlocked = isConcurrentLimitBlocked;
var isMembershipBlocked = function (e) {
    var _a, _b, _c;
    var errors = ['membership', 'premium', 'ppv'];
    var block = (_c = (_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.messages) === null || _c === void 0 ? void 0 : _c.__block;
    return errors.includes(block === null || block === void 0 ? void 0 : block.type) || errors.includes(block === null || block === void 0 ? void 0 : block.block_type);
};
exports.isMembershipBlocked = isMembershipBlocked;
var isGeoBlocking = function (e) {
    var _a, _b, _c;
    var errors = ['region_block', 'region-block'];
    var block = (_c = (_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.messages) === null || _c === void 0 ? void 0 : _c.__block;
    return errors.includes(block === null || block === void 0 ? void 0 : block.type) || errors.includes(block === null || block === void 0 ? void 0 : block.block_type);
};
exports.isGeoBlocking = isGeoBlocking;
var isPayPerViewBlocked = function (e) {
    var _a, _b, _c;
    return !!((_c = (_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.messages) === null || _c === void 0 ? void 0 : _c.pay_per_view);
};
exports.isPayPerViewBlocked = isPayPerViewBlocked;
var isAnonymousBlocked = function (e) {
    var _a, _b, _c;
    var block = (_c = (_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.messages) === null || _c === void 0 ? void 0 : _c.__block;
    return (block === null || block === void 0 ? void 0 : block.type) === 'anonymous-block';
};
exports.isAnonymousBlocked = isAnonymousBlocked;
var raiseForPlaybackErrorResponse = function (e, nullSafe) {
    var _a, _b, _c, _d;
    if (nullSafe === void 0) { nullSafe = false; }
    if ((0, exports.isConcurrentLimitBlocked)(e)) {
        var devices = ((_d = (_c = (_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.messages) === null || _c === void 0 ? void 0 : _c.__block) === null || _d === void 0 ? void 0 : _d.cwl) || [];
        throw (0, errors_1.ConcurrentLimitBlockedError)(devices);
    }
    if ((0, exports.isMembershipBlocked)(e) || (0, exports.isPayPerViewBlocked)(e)) {
        throw (0, errors_1.MemberShipBlockedError)();
    }
    if ((0, exports.isGeoBlocking)(e)) {
        throw (0, errors_1.GeolocationBlockedError)();
    }
    if ((0, exports.isAnonymousBlocked)(e)) {
        throw (0, errors_1.AnonymousBlocked)();
    }
    if (nullSafe) {
        return null;
    }
    else {
        throw e;
    }
};
exports.raiseForPlaybackErrorResponse = raiseForPlaybackErrorResponse;
var transformError = function (e) {
    var _a, _b, _c, _d;
    var errorResponseData = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data;
    if ((errorResponseData === null || errorResponseData === void 0 ? void 0 : errorResponseData.code) === '900.403') {
        return (0, errors_1.GeolocationBlockedError)();
    }
    if (((_b = e === null || e === void 0 ? void 0 : e.response) === null || _b === void 0 ? void 0 : _b.status) > 399 && /.+\.401$/.test(errorResponseData === null || errorResponseData === void 0 ? void 0 : errorResponseData.code)) {
        return (0, errors_1.UnauthorizedError)();
    }
    if (['301.491', '291.491'].includes(errorResponseData === null || errorResponseData === void 0 ? void 0 : errorResponseData.code) ||
        (errorResponseData === null || errorResponseData === void 0 ? void 0 : errorResponseData.title) === 'Incorrect profile authentication credentials') {
        return (0, errors_1.InvalidProfileTokenError)();
    }
    if ((_c = errorResponseData === null || errorResponseData === void 0 ? void 0 : errorResponseData.messages) === null || _c === void 0 ? void 0 : _c.__default) {
        return (0, errors_1.ApiError)({
            message: (_d = errorResponseData === null || errorResponseData === void 0 ? void 0 : errorResponseData.messages) === null || _d === void 0 ? void 0 : _d.__default,
            rawError: e,
        });
    }
    return e;
};
exports.transformError = transformError;
