/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppVersionApi = exports.AppVersionApiFactory = exports.AppVersionApiFp = exports.AppVersionApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * AppVersionApi - axios parameter creator
 * @export
 */
var AppVersionApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Check current version
         * @summary Check current version
         * @param {string} tenantSlug slug of tenant
         * @param {string} platformSlug slug of platform
         * @param {string} version Current version of the Client.
         * @param {string} type Client type [STB, MOBILE, ATV]]
         * @param {number} release Choose [draft: 1, release: 2].
         * @param {string} [stage] rc/alpha/beta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCurrentVersion: function (tenantSlug, platformSlug, version, type, release, stage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('checkCurrentVersion', 'tenantSlug', tenantSlug);
                            // verify required parameter 'platformSlug' is not null or undefined
                            (0, common_1.assertParamExists)('checkCurrentVersion', 'platformSlug', platformSlug);
                            // verify required parameter 'version' is not null or undefined
                            (0, common_1.assertParamExists)('checkCurrentVersion', 'version', version);
                            // verify required parameter 'type' is not null or undefined
                            (0, common_1.assertParamExists)('checkCurrentVersion', 'type', type);
                            // verify required parameter 'release' is not null or undefined
                            (0, common_1.assertParamExists)('checkCurrentVersion', 'release', release);
                            localVarPath = "/tenants/{tenant_slug}/tenant_platforms/{platform_slug}/check_version"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)))
                                .replace("{".concat("platform_slug", "}"), encodeURIComponent(String(platformSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (version !== undefined) {
                                localVarQueryParameter['version'] = version;
                            }
                            if (type !== undefined) {
                                localVarQueryParameter['type'] = type;
                            }
                            if (release !== undefined) {
                                localVarQueryParameter['release'] = release;
                            }
                            if (stage !== undefined) {
                                localVarQueryParameter['stage'] = stage;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create new version api
         * @summary Create new version api
         * @param {string} platformSlug
         * @param {string} tenantSlug
         * @param {CreateNewVersion} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewAppVersion: function (platformSlug, tenantSlug, data, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'platformSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createNewAppVersion', 'platformSlug', platformSlug);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createNewAppVersion', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createNewAppVersion', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/tenant_platforms/{platform_slug}/app_versions"
                                .replace("{".concat("platform_slug", "}"), encodeURIComponent(String(platformSlug)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [STB only] Get minimum and latest version
         * @summary [STB only] Get minimum and latest version
         * @param {string} tenantSlug slug of tenant
         * @param {string} platformSlug slug of platform
         * @param {string} id one of [\&quot;minimum\&quot;, \&quot;latest\&quot;]
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [type] STB/ATV/MOBILE, default&#x3D;STB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppVersions: function (tenantSlug, platformSlug, id, acceptLanguage, type, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getAppVersions', 'tenantSlug', tenantSlug);
                            // verify required parameter 'platformSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getAppVersions', 'platformSlug', platformSlug);
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getAppVersions', 'id', id);
                            localVarPath = "/tenants/{tenant_slug}/tenant_platforms/{platform_slug}/app_versions"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)))
                                .replace("{".concat("platform_slug", "}"), encodeURIComponent(String(platformSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (type !== undefined) {
                                localVarQueryParameter['type'] = type;
                            }
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AppVersionApiAxiosParamCreator = AppVersionApiAxiosParamCreator;
/**
 * AppVersionApi - functional programming interface
 * @export
 */
var AppVersionApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AppVersionApiAxiosParamCreator)(configuration);
    return {
        /**
         * Check current version
         * @summary Check current version
         * @param {string} tenantSlug slug of tenant
         * @param {string} platformSlug slug of platform
         * @param {string} version Current version of the Client.
         * @param {string} type Client type [STB, MOBILE, ATV]]
         * @param {number} release Choose [draft: 1, release: 2].
         * @param {string} [stage] rc/alpha/beta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCurrentVersion: function (tenantSlug, platformSlug, version, type, release, stage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.checkCurrentVersion(tenantSlug, platformSlug, version, type, release, stage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create new version api
         * @summary Create new version api
         * @param {string} platformSlug
         * @param {string} tenantSlug
         * @param {CreateNewVersion} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewAppVersion: function (platformSlug, tenantSlug, data, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createNewAppVersion(platformSlug, tenantSlug, data, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [STB only] Get minimum and latest version
         * @summary [STB only] Get minimum and latest version
         * @param {string} tenantSlug slug of tenant
         * @param {string} platformSlug slug of platform
         * @param {string} id one of [\&quot;minimum\&quot;, \&quot;latest\&quot;]
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [type] STB/ATV/MOBILE, default&#x3D;STB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppVersions: function (tenantSlug, platformSlug, id, acceptLanguage, type, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAppVersions(tenantSlug, platformSlug, id, acceptLanguage, type, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.AppVersionApiFp = AppVersionApiFp;
/**
 * AppVersionApi - factory interface
 * @export
 */
var AppVersionApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AppVersionApiFp)(configuration);
    return {
        /**
         * Check current version
         * @summary Check current version
         * @param {string} tenantSlug slug of tenant
         * @param {string} platformSlug slug of platform
         * @param {string} version Current version of the Client.
         * @param {string} type Client type [STB, MOBILE, ATV]]
         * @param {number} release Choose [draft: 1, release: 2].
         * @param {string} [stage] rc/alpha/beta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCurrentVersion: function (tenantSlug, platformSlug, version, type, release, stage, options) {
            return localVarFp.checkCurrentVersion(tenantSlug, platformSlug, version, type, release, stage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create new version api
         * @summary Create new version api
         * @param {string} platformSlug
         * @param {string} tenantSlug
         * @param {CreateNewVersion} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewAppVersion: function (platformSlug, tenantSlug, data, options) {
            return localVarFp.createNewAppVersion(platformSlug, tenantSlug, data, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [STB only] Get minimum and latest version
         * @summary [STB only] Get minimum and latest version
         * @param {string} tenantSlug slug of tenant
         * @param {string} platformSlug slug of platform
         * @param {string} id one of [\&quot;minimum\&quot;, \&quot;latest\&quot;]
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {string} [type] STB/ATV/MOBILE, default&#x3D;STB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppVersions: function (tenantSlug, platformSlug, id, acceptLanguage, type, options) {
            return localVarFp.getAppVersions(tenantSlug, platformSlug, id, acceptLanguage, type, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AppVersionApiFactory = AppVersionApiFactory;
/**
 * AppVersionApi - object-oriented interface
 * @export
 * @class AppVersionApi
 * @extends {BaseAPI}
 */
var AppVersionApi = /** @class */ (function (_super) {
    __extends(AppVersionApi, _super);
    function AppVersionApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Check current version
     * @summary Check current version
     * @param {string} tenantSlug slug of tenant
     * @param {string} platformSlug slug of platform
     * @param {string} version Current version of the Client.
     * @param {string} type Client type [STB, MOBILE, ATV]]
     * @param {number} release Choose [draft: 1, release: 2].
     * @param {string} [stage] rc/alpha/beta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppVersionApi
     */
    AppVersionApi.prototype.checkCurrentVersion = function (tenantSlug, platformSlug, version, type, release, stage, options) {
        var _this = this;
        return (0, exports.AppVersionApiFp)(this.configuration).checkCurrentVersion(tenantSlug, platformSlug, version, type, release, stage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create new version api
     * @summary Create new version api
     * @param {string} platformSlug
     * @param {string} tenantSlug
     * @param {CreateNewVersion} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppVersionApi
     */
    AppVersionApi.prototype.createNewAppVersion = function (platformSlug, tenantSlug, data, options) {
        var _this = this;
        return (0, exports.AppVersionApiFp)(this.configuration).createNewAppVersion(platformSlug, tenantSlug, data, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [STB only] Get minimum and latest version
     * @summary [STB only] Get minimum and latest version
     * @param {string} tenantSlug slug of tenant
     * @param {string} platformSlug slug of platform
     * @param {string} id one of [\&quot;minimum\&quot;, \&quot;latest\&quot;]
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {string} [type] STB/ATV/MOBILE, default&#x3D;STB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppVersionApi
     */
    AppVersionApi.prototype.getAppVersions = function (tenantSlug, platformSlug, id, acceptLanguage, type, options) {
        var _this = this;
        return (0, exports.AppVersionApiFp)(this.configuration).getAppVersions(tenantSlug, platformSlug, id, acceptLanguage, type, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AppVersionApi;
}(base_1.BaseAPI));
exports.AppVersionApi = AppVersionApi;
