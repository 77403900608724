import { store } from '~store';
import { UserContentApiInterface } from '~vimai-api-adapter';
import { ReactUserContent } from '~vimai-api-adapter/api-adapters/api-adapter-vimai/models';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';
import { IAxiosResponseData } from '~core/models';

const { userContentService } = apiAdapter;

const reactContent = (
  params: ReactUserContent,
): Promise<IAxiosResponseData<UserContentApiInterface['reactContent']> | void> => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return Promise.resolve();
  }
  const { accountId = '', tenantSlug = '', authorization, acceptLanguage } = config;
  const { react_type, content_id } = params;
  const data: ReactUserContent = { react_type, content_id };
  return userContentService
    .reactContent(accountId, tenantSlug, data, authorization, acceptLanguage)
    .then(axiosTakeDataFromResponse);
};

const getReactedContent = (params: {}): Promise<IAxiosResponseData<
  UserContentApiInterface['getReactedContents']
> | void> => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return Promise.resolve();
  }
  const { accountId = '', tenantSlug = '', authorization, acceptLanguage } = config;
  return userContentService
    .getReactedContents(accountId, tenantSlug, authorization, acceptLanguage)
    .then(axiosTakeDataFromResponse);
};

export { getReactedContent, reactContent };
