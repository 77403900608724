Object.defineProperty(exports, "__esModule", { value: true });
exports.LanguageCode = exports.ContentAirStatus = exports.RibbonSlugFilter = exports.SubscriptionIcon = exports.RibbonDisplayStyle = exports.RibbonType = exports.ContentType = exports.ODXKey = void 0;
exports.ODXKey = {
    homePageSlug: 'odk-home-page-default',
    tvShowsSlug: 'tv-shows',
    tvGuideSlug: 'tv-guide',
    defaultUnknown: 'odk-unknown-default-value',
};
exports.ContentType = {
    MOVIE: 1,
    SHOW: 2,
    SEASON: 3,
    EPISODE: 4,
    CLIP: 6,
    TRAILER: 8,
    LIVE_CHANNEL: 5,
};
exports.RibbonType = {
    POSTER: 1,
    BANNER: 0,
    THUMBNAIL: 2,
    WITH_HEADER: 3,
    NEW_RELEASE: 4,
    RECENT_MOVIE: 5,
    TOP_CONTENTS: 6,
    REPLICATED: 7,
    AUTOMATIC: 8,
    PAID_CONTENTS: 9,
    FAVORITE_CONTENTS: 10,
    DATA_SOURCE: 11,
};
exports.RibbonDisplayStyle = {
    BANNER: 0,
    THUMBNAIL: 1,
    POSTER: 2,
    HEADERLESS_THUMBNAIL: 3,
    THUMBNAIL_GROUP: 4,
    POSTER_GROUP: 5,
    POSTER_GROUP_WITH_IMAGE: 6,
    SECONDARY_BANNER: 7,
    ILLUON_COLLECTION: 8,
    ILLUON_SPOTLIGHT: 9,
    THUMBNAIL_WITH_PROGRAM_INFO: 10,
    THUMBNAIL_WITH_TITLE: 11,
};
exports.SubscriptionIcon = {
    BASIC: 'https://vimai-demo-content-upload.s3.ap-southeast-1.amazonaws.com/tenants/odx/icons/basic_icon.png',
    PREMIUM: 'https://vimai-demo-content-upload.s3.ap-southeast-1.amazonaws.com/tenants/odx/icons/premium_icon.png',
    PLUS: 'https://vimai-demo-content-upload.s3.ap-southeast-1.amazonaws.com/tenants/odx/icons/plus.png',
    BOX_PREMIUM: 'https://vimai-demo-content-upload.s3.ap-southeast-1.amazonaws.com/tenants/odx/icons/premium_box.png',
    STB_PREMIUM: 'https://vimai-demo-content-upload.s3.ap-southeast-1.amazonaws.com/tenants/odx/icons/box_premium.png',
};
exports.RibbonSlugFilter = {
    PROVIDER: 'provider',
    GENRE: 'genre',
    SUB_CATEGORY: 'sub_category',
    ORDER: 'sort',
};
exports.ContentAirStatus = {
    AIR_STATUS_COMING_SOON: 0,
    AIR_STATUS_NOT_AIRED: 1,
    AIR_STATUS_ON_AIR: 2,
    AIR_STATUS_AIRED: 3,
};
exports.LanguageCode = {
    EN: 0,
    VN: 1,
    ES: 2,
    KO: 3,
};
