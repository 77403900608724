var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParsePageDetailFromOdxTvGuide = exports.ParsePageLiveSchedulesFromOdxChannels = exports.CombineRibbonInfoAndPageProgramsToRibbonDetailPaging = exports.ParsePageDetailFromOdxCategory = exports.CombineCoverAndCurationToPageDetail = void 0;
var models_1 = require("../../api-adapter-vimai/models");
var mappers_1 = require("../mappers");
var constants_1 = require("../constants");
var ribbon_utils_1 = require("../utils/ribbon-utils");
var factory_1 = require("../factory");
var integration_service_1 = require("../integration-service");
var messages_1 = require("../messages");
var ConvertEpisodeCoverToShowCover = function (item, episodeCoversData) {
    var _a, _b;
    if (item.content_type !== 'episode') {
        return { program: item, directPlayEpisode: null };
    }
    var r = episodeCoversData === null || episodeCoversData === void 0 ? void 0 : episodeCoversData.find(function (d) { return (d === null || d === void 0 ? void 0 : d.id) === item.episode_id; });
    var directPlayEpisode = null;
    item.content_type = 'program';
    if (r) {
        directPlayEpisode = {
            id: r.id,
            slug: r.slug,
            title: r.title,
        };
        item.content_id = (_a = r.program) === null || _a === void 0 ? void 0 : _a.id;
        item.content_slug = (_b = r.program) === null || _b === void 0 ? void 0 : _b.slug;
    }
    return { program: item, directPlayEpisode: directPlayEpisode };
};
var CombineCoverAndCurationToPageDetail = function (pageInfo, cover, curation, episodeCoversData) {
    var _a, _b, _c, _d, _e;
    var adapterFeatures = integration_service_1.OdxIntegration.currentProvider.get('adapterFeatures') || [];
    var excludeNotSupported = function (item) {
        return true; //item.logic_ordering !== 'episode-recent-updated-by-date'
    };
    var supportedContentType = function (item) {
        return (item.content_type === 'program' || item.content_type === 'episode' || item.content_type === 'channel');
    };
    var ribbons = (_a = curation === null || curation === void 0 ? void 0 : curation.contents) === null || _a === void 0 ? void 0 : _a.filter(excludeNotSupported).map(mappers_1.mapCurationToRibbonData);
    if (adapterFeatures.includes('HOME_PAGE_APPEND_EMPTY_CAROUSEL')) {
        var firstMatches = ribbons.findIndex(function (s) { var _a, _b, _c; 
        // @ts-ignore
        return (_c = (_b = (_a = s.metadata) === null || _a === void 0 ? void 0 : _a.source) === null || _b === void 0 ? void 0 : _b.logic_ordering) === null || _c === void 0 ? void 0 : _c.startsWith('episode-recent-updated'); });
        if (firstMatches >= 0) {
            var carousel = ribbons[firstMatches];
            var copy = __assign(__assign({}, carousel), { id: "".concat(carousel.id, "-duplicated"), slug: "".concat(carousel.slug, "-duplicated"), name: 'An Empty Carousel', items: [] });
            ribbons.splice(firstMatches + 1, 0, copy);
        }
    }
    return {
        name: pageInfo.name,
        display_style: pageInfo.display_style,
        page_options: pageInfo.page_options,
        banners: (_e = (_d = (_c = (_b = cover === null || cover === void 0 ? void 0 : cover.contents) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c.filter(supportedContentType)) === null || _d === void 0 ? void 0 : _d.map(function (cv) { return ConvertEpisodeCoverToShowCover(cv, episodeCoversData); })) === null || _e === void 0 ? void 0 : _e.map(function (cvData) { return (0, mappers_1.mapCoverDataToBannerItem)(cvData); }),
        ribbons: ribbons,
    };
};
exports.CombineCoverAndCurationToPageDetail = CombineCoverAndCurationToPageDetail;
var ParsePageDetailFromOdxCategory = function (pageSlug, category, style, coverData, episodeCoversData) {
    var _a, _b, _c, _d;
    if (style === void 0) { style = models_1.PageDetailDisplayStyleEnum.Normal; }
    var additional;
    var _e = (0, ribbon_utils_1.getListFilterOptionsAndFilterPrefix)(category), listFilter = _e.listFilter, prefix = _e.prefix;
    var supportedContentType = function (item) {
        return item.content_type === 'program' || item.content_type === 'episode';
    };
    // https://kipwise.com/app/teams/uIEaPsax/contents/700d104f-7ac7-4715-99fc-6739980c7910#792ca15e
    if (['movies', 'lifestyle', 'kocowa', 'korean-news'].includes(pageSlug)) {
        additional = (0, factory_1.createNewestRibbon)(pageSlug);
    }
    var ribbons = listFilter.map(function (item, index) {
        var filterOptions = { type: 'category', categorySlug: pageSlug };
        switch (prefix) {
            case constants_1.RibbonSlugFilter.SUB_CATEGORY:
                filterOptions.subCategorySlug = item.slug;
                break;
            case constants_1.RibbonSlugFilter.GENRE:
                filterOptions.genreSlug = item.slug;
                break;
            case constants_1.RibbonSlugFilter.PROVIDER:
                filterOptions.providerSlug = item.slug;
                break;
            default:
                break;
        }
        var identity = (0, ribbon_utils_1.createRibbonSlugByForCategoryFilter)(filterOptions);
        return (0, mappers_1.mapCategoryFilterOptionItemInnerToRibbonDetail)(item, index, identity);
    });
    var banners = (_d = (_c = (_b = (_a = coverData === null || coverData === void 0 ? void 0 : coverData.contents) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.filter(supportedContentType)) === null || _c === void 0 ? void 0 : _c.map(function (cv) { return ConvertEpisodeCoverToShowCover(cv, episodeCoversData); })) === null || _d === void 0 ? void 0 : _d.map(function (cvData) { return (0, mappers_1.mapCoverDataToBannerItem)(cvData); });
    if (additional) {
        ribbons.splice(1, 0, additional);
    }
    return {
        name: category.title,
        display_style: style,
        page_options: {
            contain_sub_item: false,
            content_navigation_option: 'default',
        },
        banners: banners,
        ribbons: ribbons,
    };
};
exports.ParsePageDetailFromOdxCategory = ParsePageDetailFromOdxCategory;
var CombineRibbonInfoAndPageProgramsToRibbonDetailPaging = function (ribbonId, ribbonName, contentData) {
    var _a;
    var categorySlug = (0, ribbon_utils_1.getFilterOptionsFromRibbonSlug)(ribbonId).categorySlug;
    // Hardcode is required
    var displayStyle;
    var ribbonType;
    if (categorySlug === 'korean-news') {
        displayStyle = constants_1.RibbonDisplayStyle.THUMBNAIL_WITH_PROGRAM_INFO;
    }
    else {
        displayStyle = constants_1.RibbonDisplayStyle.THUMBNAIL;
    }
    if (categorySlug === 'movies') {
        ribbonType = constants_1.RibbonType.POSTER;
    }
    else {
        ribbonType = constants_1.RibbonType.THUMBNAIL;
    }
    return {
        id: ribbonId,
        name: ribbonName,
        items: (_a = contentData === null || contentData === void 0 ? void 0 : contentData.results) === null || _a === void 0 ? void 0 : _a.map(mappers_1.mapOdxProgramsToRibbonItem),
        metadata: {
            page: (contentData.page_previous || 1) - 1,
            limit: contentData.page_size,
            total: contentData.count,
        },
        ribbon_options: {
            content_navigation_option: 'default',
        },
        seo: null,
        is_default_display: false,
        is_visible_in_sub_navigation_bar: false,
        is_visible_in_ribbon_main_section: true,
        is_visible_in_side_navigation_section: false,
        display_type: displayStyle,
        type: ribbonType,
        video_source: 0,
    };
};
exports.CombineRibbonInfoAndPageProgramsToRibbonDetailPaging = CombineRibbonInfoAndPageProgramsToRibbonDetailPaging;
var ParsePageLiveSchedulesFromOdxChannels = function (channels) {
    return {
        ribbons: [
            {
                id: constants_1.ODXKey.defaultUnknown,
                name: constants_1.ODXKey.defaultUnknown,
                slug: constants_1.ODXKey.defaultUnknown,
                icon_url: null,
                channels: channels === null || channels === void 0 ? void 0 : channels.map(mappers_1.mapOdxChannelsToChannelLiveScheduleRes),
            },
        ],
    };
};
exports.ParsePageLiveSchedulesFromOdxChannels = ParsePageLiveSchedulesFromOdxChannels;
var ParsePageDetailFromOdxTvGuide = function (tvGuide, tvGuidePrograms) {
    var _a;
    var data = tvGuidePrograms || [];
    (_a = tvGuide === null || tvGuide === void 0 ? void 0 : tvGuide.categories) === null || _a === void 0 ? void 0 : _a.forEach(function (cat, index) {
        var found = data.find(function (x) { return x.slug === cat.slug; });
        if (!found && index > 0) {
            var newCategory = {
                // @ts-ignore
                id: cat.id,
                slug: cat.slug,
                category_title: cat.title,
                items: [],
            };
            data.splice(index - 1, 0, newCategory);
        }
    });
    return {
        name: messages_1.Messages.get('tvGuideMenuTitle'),
        display_style: 'TV_GUIDE',
        page_options: {
            contain_sub_item: false,
            content_navigation_option: 'default',
            available_dates: tvGuide.date,
        },
        banners: [],
        ribbons: data.map(mappers_1.mapOdxTVGuideProgramItemToRibbonDetail),
    };
};
exports.ParsePageDetailFromOdxTvGuide = ParsePageDetailFromOdxTvGuide;
