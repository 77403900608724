/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApi = exports.UserApiFactory = exports.UserApiFp = exports.UserApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * UserApi - axios parameter creator
 * @export
 */
var UserApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Apply big N coupon code API
         * @summary Apply big N coupon code API
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ApplyBigNCouponForAccountRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyBigNCouponCodeApi: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('applyBigNCouponCodeApi', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('applyBigNCouponCodeApi', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('applyBigNCouponCodeApi', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/apply_big_n_coupon"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Change parental control code
         * @summary Change parental control code
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ChangeParentalControlCodeRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeParentalControlCode: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('changeParentalControlCode', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('changeParentalControlCode', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('changeParentalControlCode', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/parental_controls/change/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [Only STB] Check account permission
         * @summary [Only STB] Check account permission
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountPermission: function (tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('checkAccountPermission', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/permissions"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * If the username exists, the response status code will be 200. If the username doesn\'t exist, the response status code will be 400.
         * @summary Check existed user account API
         * @param {string} tenantSlug
         * @param {string} username
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [username2] Email or phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkExistedUserAccount: function (tenantSlug, username, authorization, acceptLanguage, username2, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('checkExistedUserAccount', 'tenantSlug', tenantSlug);
                            // verify required parameter 'username' is not null or undefined
                            (0, common_1.assertParamExists)('checkExistedUserAccount', 'username', username);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{username}/check_existed"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)))
                                .replace("{".concat("username", "}"), encodeURIComponent(String(username)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (username2 !== undefined) {
                                localVarQueryParameter['username'] = username2;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [MAILBOX] Confirm email url via email
         * @summary [MAILBOX] Confirm email url via email
         * @param {string} tenantSlug
         * @param {string} otp OTP
         * @param {string} [email] Email
         * @param {string} [phoneNumber] Phone number
         * @param {string} [validateType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail: function (tenantSlug, otp, email, phoneNumber, validateType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('confirmEmail', 'tenantSlug', tenantSlug);
                            // verify required parameter 'otp' is not null or undefined
                            (0, common_1.assertParamExists)('confirmEmail', 'otp', otp);
                            localVarPath = "/tenants/{tenant_slug}/accounts/emails/validate"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (otp !== undefined) {
                                localVarQueryParameter['otp'] = otp;
                            }
                            if (email !== undefined) {
                                localVarQueryParameter['email'] = email;
                            }
                            if (phoneNumber !== undefined) {
                                localVarQueryParameter['phone_number'] = phoneNumber;
                            }
                            if (validateType !== undefined) {
                                localVarQueryParameter['validate_type'] = validateType;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [MAILBOX] Confirm email url via email
         * @summary [MAILBOX] Confirm email url via email
         * @param {string} tenantSlug
         * @param {string} otp OTP
         * @param {string} [email] Email
         * @param {string} [phoneNumber] Phone number
         * @param {string} [validateType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail_1: function (tenantSlug, otp, email, phoneNumber, validateType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('confirmEmail_1', 'tenantSlug', tenantSlug);
                            // verify required parameter 'otp' is not null or undefined
                            (0, common_1.assertParamExists)('confirmEmail_1', 'otp', otp);
                            localVarPath = "/tenants/{tenant_slug}/accounts/emails/validate"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (otp !== undefined) {
                                localVarQueryParameter['otp'] = otp;
                            }
                            if (email !== undefined) {
                                localVarQueryParameter['email'] = email;
                            }
                            if (phoneNumber !== undefined) {
                                localVarQueryParameter['phone_number'] = phoneNumber;
                            }
                            if (validateType !== undefined) {
                                localVarQueryParameter['validate_type'] = validateType;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [MAILBOX] Confirm email url via email
         * @summary [MAILBOX] Confirm email url via email
         * @param {string} tenantSlug
         * @param {string} otp OTP
         * @param {string} [email] Email
         * @param {string} [phoneNumber] Phone number
         * @param {string} [validateType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail_2: function (tenantSlug, otp, email, phoneNumber, validateType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('confirmEmail_2', 'tenantSlug', tenantSlug);
                            // verify required parameter 'otp' is not null or undefined
                            (0, common_1.assertParamExists)('confirmEmail_2', 'otp', otp);
                            localVarPath = "/tenants/{tenant_slug}/accounts/registers/validate"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (otp !== undefined) {
                                localVarQueryParameter['otp'] = otp;
                            }
                            if (email !== undefined) {
                                localVarQueryParameter['email'] = email;
                            }
                            if (phoneNumber !== undefined) {
                                localVarQueryParameter['phone_number'] = phoneNumber;
                            }
                            if (validateType !== undefined) {
                                localVarQueryParameter['validate_type'] = validateType;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [MAILBOX] Confirm email url via email
         * @summary [MAILBOX] Confirm email url via email
         * @param {string} tenantSlug
         * @param {string} otp OTP
         * @param {string} [email] Email
         * @param {string} [phoneNumber] Phone number
         * @param {string} [validateType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail_3: function (tenantSlug, otp, email, phoneNumber, validateType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('confirmEmail_3', 'tenantSlug', tenantSlug);
                            // verify required parameter 'otp' is not null or undefined
                            (0, common_1.assertParamExists)('confirmEmail_3', 'otp', otp);
                            localVarPath = "/tenants/{tenant_slug}/accounts/registers/validate"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (otp !== undefined) {
                                localVarQueryParameter['otp'] = otp;
                            }
                            if (email !== undefined) {
                                localVarQueryParameter['email'] = email;
                            }
                            if (phoneNumber !== undefined) {
                                localVarQueryParameter['phone_number'] = phoneNumber;
                            }
                            if (validateType !== undefined) {
                                localVarQueryParameter['validate_type'] = validateType;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Contacts support account
         * @summary Contacts support account
         * @param {string} tenantSlug
         * @param {ContactsSupportAccountRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSupport: function (tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('contactSupport', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('contactSupport', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/contacts_support"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Generate Chat User Info API
         * @summary Generate Chat User Info API
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateChatUserInfoApi: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('generateChatUserInfoApi', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('generateChatUserInfoApi', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/chat_user_info"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get account info
         * @summary Get account info api
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInfo: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('getAccountInfo', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getAccountInfo', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/info"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get profile
         * @summary Get profile
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountProfile: function (tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getAccountProfile', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/profile"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get or create channel API
         * @summary Get or create channel API
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateChannelInfo: function (contentId, tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getOrCreateChannelInfo', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getOrCreateChannelInfo', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/channel_info"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get or create channel API
         * @summary Get or create channel API
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateChannelInfo_4: function (contentId, tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getOrCreateChannelInfo_4', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getOrCreateChannelInfo_4', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/channel_info"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List peoples of content
         * @summary List peoples of content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeoplesOfContent: function (contentId, tenantSlug, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getPeoplesOfContent', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPeoplesOfContent', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/peoples"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (imageFormat !== undefined) {
                                localVarQueryParameter['image_format'] = imageFormat;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all reviews of user
         * @summary List all reviews of user
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewsByUser: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, select, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('getReviewsByUser', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getReviewsByUser', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/account/{account_id}/reviews/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get profile
         * @summary Get profile
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserProfile: function (authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/cas/profile/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Limit CCU API
         * @param {string} accountId
         * @param {string} action
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        limitCcuApi: function (accountId, action, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('limitCcuApi', 'accountId', accountId);
                            // verify required parameter 'action' is not null or undefined
                            (0, common_1.assertParamExists)('limitCcuApi', 'action', action);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('limitCcuApi', 'tenantSlug', tenantSlug);
                            localVarPath = "/v2/tenants/{tenant_slug}/accounts/{account_id}/drm/{action}"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("action", "}"), encodeURIComponent(String(action)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Limit CCU API
         * @param {string} accountId
         * @param {string} action
         * @param {string} tenantSlug
         * @param {LimitCCURequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        limitCcuApi_5: function (accountId, action, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('limitCcuApi_5', 'accountId', accountId);
                            // verify required parameter 'action' is not null or undefined
                            (0, common_1.assertParamExists)('limitCcuApi_5', 'action', action);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('limitCcuApi_5', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('limitCcuApi_5', 'data', data);
                            localVarPath = "/v2/tenants/{tenant_slug}/accounts/{account_id}/drm/{action}"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("action", "}"), encodeURIComponent(String(action)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Link account with social network account
         * @summary Link account with social network account
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {LinkAccountWithSNAccountRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkAccount: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('linkAccount', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('linkAccount', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('linkAccount', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/link_account"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Remove account api
         * @summary Remove account api
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAccount: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('removeAccount', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('removeAccount', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/remove"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Change email
         * @summary Change email
         * @param {string} tenantSlug
         * @param {ChangeEmailRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestChangeEmail: function (tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('requestChangeEmail', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('requestChangeEmail', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/email"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Change phone number for account
         * @summary Change phone number
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ChangeMobilePhoneRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestChangePhoneNumber: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('requestChangePhoneNumber', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('requestChangePhoneNumber', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('requestChangePhoneNumber', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/change_phone_number"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Reset parental control code
         * @summary Reset parental control code
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetParentalControlCode: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('resetParentalControlCode', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('resetParentalControlCode', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/parental_controls/reset/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Send user service survey
         * @summary Send user service survey
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {UserServiceSurveyRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendUserSurvey: function (accountId, tenantSlug, data, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('sendUserSurvey', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('sendUserSurvey', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('sendUserSurvey', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/surveys/service/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Setup account parental control
         * @summary Setup account parental control
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {SetupParentalControlRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupParentalControlCode: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('setupParentalControlCode', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('setupParentalControlCode', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('setupParentalControlCode', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/parental_controls/setup/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update profile
         * @summary Update profile
         * @param {string} tenantSlug
         * @param {UpdateProfileRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountProfile: function (tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateAccountProfile', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateAccountProfile', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/profile"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update profile
         * @summary Update profile
         * @param {string} tenantSlug
         * @param {UpdateProfileRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountProfile_6: function (tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateAccountProfile_6', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateAccountProfile_6', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/profile"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update nickname user chat api
         * @summary Update nickname user chat api
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {UpdateUserChatRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNicknameUserChatApi: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('updateNicknameUserChatApi', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateNicknameUserChatApi', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateNicknameUserChatApi', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/update_nickname_chat"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update profile
         * @summary Update profile
         * @param {UpdateProfileRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateUserProfile: function (data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateUserProfile', 'data', data);
                            localVarPath = "/backend/cas/profile/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Verify parental control code
         * @summary Verify parental control code
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [code] Account parental control code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyParentalControlCode: function (accountId, tenantSlug, authorization, acceptLanguage, code, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('verifyParentalControlCode', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('verifyParentalControlCode', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/parental_controls/verify/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (code !== undefined) {
                                localVarQueryParameter['code'] = code;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.UserApiAxiosParamCreator = UserApiAxiosParamCreator;
/**
 * UserApi - functional programming interface
 * @export
 */
var UserApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.UserApiAxiosParamCreator)(configuration);
    return {
        /**
         * Apply big N coupon code API
         * @summary Apply big N coupon code API
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ApplyBigNCouponForAccountRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyBigNCouponCodeApi: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.applyBigNCouponCodeApi(accountId, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Change parental control code
         * @summary Change parental control code
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ChangeParentalControlCodeRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeParentalControlCode: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.changeParentalControlCode(accountId, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [Only STB] Check account permission
         * @summary [Only STB] Check account permission
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountPermission: function (tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.checkAccountPermission(tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * If the username exists, the response status code will be 200. If the username doesn\'t exist, the response status code will be 400.
         * @summary Check existed user account API
         * @param {string} tenantSlug
         * @param {string} username
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [username2] Email or phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkExistedUserAccount: function (tenantSlug, username, authorization, acceptLanguage, username2, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.checkExistedUserAccount(tenantSlug, username, authorization, acceptLanguage, username2, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [MAILBOX] Confirm email url via email
         * @summary [MAILBOX] Confirm email url via email
         * @param {string} tenantSlug
         * @param {string} otp OTP
         * @param {string} [email] Email
         * @param {string} [phoneNumber] Phone number
         * @param {string} [validateType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail: function (tenantSlug, otp, email, phoneNumber, validateType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.confirmEmail(tenantSlug, otp, email, phoneNumber, validateType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [MAILBOX] Confirm email url via email
         * @summary [MAILBOX] Confirm email url via email
         * @param {string} tenantSlug
         * @param {string} otp OTP
         * @param {string} [email] Email
         * @param {string} [phoneNumber] Phone number
         * @param {string} [validateType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail_1: function (tenantSlug, otp, email, phoneNumber, validateType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.confirmEmail_1(tenantSlug, otp, email, phoneNumber, validateType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [MAILBOX] Confirm email url via email
         * @summary [MAILBOX] Confirm email url via email
         * @param {string} tenantSlug
         * @param {string} otp OTP
         * @param {string} [email] Email
         * @param {string} [phoneNumber] Phone number
         * @param {string} [validateType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail_2: function (tenantSlug, otp, email, phoneNumber, validateType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.confirmEmail_2(tenantSlug, otp, email, phoneNumber, validateType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [MAILBOX] Confirm email url via email
         * @summary [MAILBOX] Confirm email url via email
         * @param {string} tenantSlug
         * @param {string} otp OTP
         * @param {string} [email] Email
         * @param {string} [phoneNumber] Phone number
         * @param {string} [validateType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail_3: function (tenantSlug, otp, email, phoneNumber, validateType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.confirmEmail_3(tenantSlug, otp, email, phoneNumber, validateType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Contacts support account
         * @summary Contacts support account
         * @param {string} tenantSlug
         * @param {ContactsSupportAccountRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSupport: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.contactSupport(tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Generate Chat User Info API
         * @summary Generate Chat User Info API
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateChatUserInfoApi: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.generateChatUserInfoApi(accountId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get account info
         * @summary Get account info api
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInfo: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAccountInfo(accountId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get profile
         * @summary Get profile
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountProfile: function (tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAccountProfile(tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get or create channel API
         * @summary Get or create channel API
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateChannelInfo: function (contentId, tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getOrCreateChannelInfo(contentId, tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get or create channel API
         * @summary Get or create channel API
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateChannelInfo_4: function (contentId, tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getOrCreateChannelInfo_4(contentId, tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List peoples of content
         * @summary List peoples of content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeoplesOfContent: function (contentId, tenantSlug, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPeoplesOfContent(contentId, tenantSlug, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List all reviews of user
         * @summary List all reviews of user
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewsByUser: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, select, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getReviewsByUser(accountId, tenantSlug, authorization, acceptLanguage, page, limit, select, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get profile
         * @summary Get profile
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserProfile: function (authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserProfile(authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Limit CCU API
         * @param {string} accountId
         * @param {string} action
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        limitCcuApi: function (accountId, action, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.limitCcuApi(accountId, action, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Limit CCU API
         * @param {string} accountId
         * @param {string} action
         * @param {string} tenantSlug
         * @param {LimitCCURequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        limitCcuApi_5: function (accountId, action, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.limitCcuApi_5(accountId, action, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Link account with social network account
         * @summary Link account with social network account
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {LinkAccountWithSNAccountRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkAccount: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.linkAccount(accountId, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Remove account api
         * @summary Remove account api
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAccount: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.removeAccount(accountId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Change email
         * @summary Change email
         * @param {string} tenantSlug
         * @param {ChangeEmailRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestChangeEmail: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.requestChangeEmail(tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Change phone number for account
         * @summary Change phone number
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ChangeMobilePhoneRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestChangePhoneNumber: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.requestChangePhoneNumber(accountId, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Reset parental control code
         * @summary Reset parental control code
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetParentalControlCode: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.resetParentalControlCode(accountId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Send user service survey
         * @summary Send user service survey
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {UserServiceSurveyRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendUserSurvey: function (accountId, tenantSlug, data, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sendUserSurvey(accountId, tenantSlug, data, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Setup account parental control
         * @summary Setup account parental control
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {SetupParentalControlRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupParentalControlCode: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setupParentalControlCode(accountId, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update profile
         * @summary Update profile
         * @param {string} tenantSlug
         * @param {UpdateProfileRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountProfile: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateAccountProfile(tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update profile
         * @summary Update profile
         * @param {string} tenantSlug
         * @param {UpdateProfileRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountProfile_6: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateAccountProfile_6(tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update nickname user chat api
         * @summary Update nickname user chat api
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {UpdateUserChatRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNicknameUserChatApi: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateNicknameUserChatApi(accountId, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update profile
         * @summary Update profile
         * @param {UpdateProfileRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateUserProfile: function (data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateUserProfile(data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Verify parental control code
         * @summary Verify parental control code
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [code] Account parental control code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyParentalControlCode: function (accountId, tenantSlug, authorization, acceptLanguage, code, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.verifyParentalControlCode(accountId, tenantSlug, authorization, acceptLanguage, code, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.UserApiFp = UserApiFp;
/**
 * UserApi - factory interface
 * @export
 */
var UserApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.UserApiFp)(configuration);
    return {
        /**
         * Apply big N coupon code API
         * @summary Apply big N coupon code API
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ApplyBigNCouponForAccountRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyBigNCouponCodeApi: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.applyBigNCouponCodeApi(accountId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Change parental control code
         * @summary Change parental control code
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ChangeParentalControlCodeRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeParentalControlCode: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.changeParentalControlCode(accountId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [Only STB] Check account permission
         * @summary [Only STB] Check account permission
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountPermission: function (tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.checkAccountPermission(tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * If the username exists, the response status code will be 200. If the username doesn\'t exist, the response status code will be 400.
         * @summary Check existed user account API
         * @param {string} tenantSlug
         * @param {string} username
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [username2] Email or phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkExistedUserAccount: function (tenantSlug, username, authorization, acceptLanguage, username2, options) {
            return localVarFp.checkExistedUserAccount(tenantSlug, username, authorization, acceptLanguage, username2, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [MAILBOX] Confirm email url via email
         * @summary [MAILBOX] Confirm email url via email
         * @param {string} tenantSlug
         * @param {string} otp OTP
         * @param {string} [email] Email
         * @param {string} [phoneNumber] Phone number
         * @param {string} [validateType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail: function (tenantSlug, otp, email, phoneNumber, validateType, options) {
            return localVarFp.confirmEmail(tenantSlug, otp, email, phoneNumber, validateType, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [MAILBOX] Confirm email url via email
         * @summary [MAILBOX] Confirm email url via email
         * @param {string} tenantSlug
         * @param {string} otp OTP
         * @param {string} [email] Email
         * @param {string} [phoneNumber] Phone number
         * @param {string} [validateType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail_1: function (tenantSlug, otp, email, phoneNumber, validateType, options) {
            return localVarFp.confirmEmail_1(tenantSlug, otp, email, phoneNumber, validateType, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [MAILBOX] Confirm email url via email
         * @summary [MAILBOX] Confirm email url via email
         * @param {string} tenantSlug
         * @param {string} otp OTP
         * @param {string} [email] Email
         * @param {string} [phoneNumber] Phone number
         * @param {string} [validateType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail_2: function (tenantSlug, otp, email, phoneNumber, validateType, options) {
            return localVarFp.confirmEmail_2(tenantSlug, otp, email, phoneNumber, validateType, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [MAILBOX] Confirm email url via email
         * @summary [MAILBOX] Confirm email url via email
         * @param {string} tenantSlug
         * @param {string} otp OTP
         * @param {string} [email] Email
         * @param {string} [phoneNumber] Phone number
         * @param {string} [validateType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail_3: function (tenantSlug, otp, email, phoneNumber, validateType, options) {
            return localVarFp.confirmEmail_3(tenantSlug, otp, email, phoneNumber, validateType, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Contacts support account
         * @summary Contacts support account
         * @param {string} tenantSlug
         * @param {ContactsSupportAccountRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSupport: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.contactSupport(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Generate Chat User Info API
         * @summary Generate Chat User Info API
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateChatUserInfoApi: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.generateChatUserInfoApi(accountId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get account info
         * @summary Get account info api
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInfo: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getAccountInfo(accountId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get profile
         * @summary Get profile
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountProfile: function (tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getAccountProfile(tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get or create channel API
         * @summary Get or create channel API
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateChannelInfo: function (contentId, tenantSlug, options) {
            return localVarFp.getOrCreateChannelInfo(contentId, tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get or create channel API
         * @summary Get or create channel API
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateChannelInfo_4: function (contentId, tenantSlug, options) {
            return localVarFp.getOrCreateChannelInfo_4(contentId, tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List peoples of content
         * @summary List peoples of content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [imageFormat] Image type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeoplesOfContent: function (contentId, tenantSlug, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options) {
            return localVarFp.getPeoplesOfContent(contentId, tenantSlug, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all reviews of user
         * @summary List all reviews of user
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewsByUser: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, select, options) {
            return localVarFp.getReviewsByUser(accountId, tenantSlug, authorization, acceptLanguage, page, limit, select, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get profile
         * @summary Get profile
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserProfile: function (authorization, acceptLanguage, options) {
            return localVarFp.getUserProfile(authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Limit CCU API
         * @param {string} accountId
         * @param {string} action
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        limitCcuApi: function (accountId, action, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.limitCcuApi(accountId, action, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Limit CCU API
         * @param {string} accountId
         * @param {string} action
         * @param {string} tenantSlug
         * @param {LimitCCURequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        limitCcuApi_5: function (accountId, action, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.limitCcuApi_5(accountId, action, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Link account with social network account
         * @summary Link account with social network account
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {LinkAccountWithSNAccountRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkAccount: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.linkAccount(accountId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Remove account api
         * @summary Remove account api
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAccount: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.removeAccount(accountId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Change email
         * @summary Change email
         * @param {string} tenantSlug
         * @param {ChangeEmailRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestChangeEmail: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.requestChangeEmail(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Change phone number for account
         * @summary Change phone number
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ChangeMobilePhoneRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestChangePhoneNumber: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.requestChangePhoneNumber(accountId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Reset parental control code
         * @summary Reset parental control code
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetParentalControlCode: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.resetParentalControlCode(accountId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Send user service survey
         * @summary Send user service survey
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {UserServiceSurveyRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendUserSurvey: function (accountId, tenantSlug, data, authorization, options) {
            return localVarFp.sendUserSurvey(accountId, tenantSlug, data, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Setup account parental control
         * @summary Setup account parental control
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {SetupParentalControlRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupParentalControlCode: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.setupParentalControlCode(accountId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update profile
         * @summary Update profile
         * @param {string} tenantSlug
         * @param {UpdateProfileRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountProfile: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateAccountProfile(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update profile
         * @summary Update profile
         * @param {string} tenantSlug
         * @param {UpdateProfileRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountProfile_6: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateAccountProfile_6(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update nickname user chat api
         * @summary Update nickname user chat api
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {UpdateUserChatRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNicknameUserChatApi: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateNicknameUserChatApi(accountId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update profile
         * @summary Update profile
         * @param {UpdateProfileRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateUserProfile: function (data, authorization, acceptLanguage, options) {
            return localVarFp.updateUserProfile(data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Verify parental control code
         * @summary Verify parental control code
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [code] Account parental control code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyParentalControlCode: function (accountId, tenantSlug, authorization, acceptLanguage, code, options) {
            return localVarFp.verifyParentalControlCode(accountId, tenantSlug, authorization, acceptLanguage, code, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.UserApiFactory = UserApiFactory;
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
var UserApi = /** @class */ (function (_super) {
    __extends(UserApi, _super);
    function UserApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Apply big N coupon code API
     * @summary Apply big N coupon code API
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {ApplyBigNCouponForAccountRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.applyBigNCouponCodeApi = function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).applyBigNCouponCodeApi(accountId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Change parental control code
     * @summary Change parental control code
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {ChangeParentalControlCodeRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.changeParentalControlCode = function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).changeParentalControlCode(accountId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [Only STB] Check account permission
     * @summary [Only STB] Check account permission
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.checkAccountPermission = function (tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).checkAccountPermission(tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * If the username exists, the response status code will be 200. If the username doesn\'t exist, the response status code will be 400.
     * @summary Check existed user account API
     * @param {string} tenantSlug
     * @param {string} username
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [username2] Email or phone number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.checkExistedUserAccount = function (tenantSlug, username, authorization, acceptLanguage, username2, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).checkExistedUserAccount(tenantSlug, username, authorization, acceptLanguage, username2, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [MAILBOX] Confirm email url via email
     * @summary [MAILBOX] Confirm email url via email
     * @param {string} tenantSlug
     * @param {string} otp OTP
     * @param {string} [email] Email
     * @param {string} [phoneNumber] Phone number
     * @param {string} [validateType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.confirmEmail = function (tenantSlug, otp, email, phoneNumber, validateType, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).confirmEmail(tenantSlug, otp, email, phoneNumber, validateType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [MAILBOX] Confirm email url via email
     * @summary [MAILBOX] Confirm email url via email
     * @param {string} tenantSlug
     * @param {string} otp OTP
     * @param {string} [email] Email
     * @param {string} [phoneNumber] Phone number
     * @param {string} [validateType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.confirmEmail_1 = function (tenantSlug, otp, email, phoneNumber, validateType, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).confirmEmail_1(tenantSlug, otp, email, phoneNumber, validateType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [MAILBOX] Confirm email url via email
     * @summary [MAILBOX] Confirm email url via email
     * @param {string} tenantSlug
     * @param {string} otp OTP
     * @param {string} [email] Email
     * @param {string} [phoneNumber] Phone number
     * @param {string} [validateType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.confirmEmail_2 = function (tenantSlug, otp, email, phoneNumber, validateType, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).confirmEmail_2(tenantSlug, otp, email, phoneNumber, validateType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [MAILBOX] Confirm email url via email
     * @summary [MAILBOX] Confirm email url via email
     * @param {string} tenantSlug
     * @param {string} otp OTP
     * @param {string} [email] Email
     * @param {string} [phoneNumber] Phone number
     * @param {string} [validateType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.confirmEmail_3 = function (tenantSlug, otp, email, phoneNumber, validateType, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).confirmEmail_3(tenantSlug, otp, email, phoneNumber, validateType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Contacts support account
     * @summary Contacts support account
     * @param {string} tenantSlug
     * @param {ContactsSupportAccountRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.contactSupport = function (tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).contactSupport(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Generate Chat User Info API
     * @summary Generate Chat User Info API
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.generateChatUserInfoApi = function (accountId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).generateChatUserInfoApi(accountId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get account info
     * @summary Get account info api
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.getAccountInfo = function (accountId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).getAccountInfo(accountId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get profile
     * @summary Get profile
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.getAccountProfile = function (tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).getAccountProfile(tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get or create channel API
     * @summary Get or create channel API
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.getOrCreateChannelInfo = function (contentId, tenantSlug, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).getOrCreateChannelInfo(contentId, tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get or create channel API
     * @summary Get or create channel API
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.getOrCreateChannelInfo_4 = function (contentId, tenantSlug, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).getOrCreateChannelInfo_4(contentId, tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List peoples of content
     * @summary List peoples of content
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [imageFormat] Image type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.getPeoplesOfContent = function (contentId, tenantSlug, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).getPeoplesOfContent(contentId, tenantSlug, authorization, acceptLanguage, page, limit, select, imageResolutionScale, imageFormat, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all reviews of user
     * @summary List all reviews of user
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.getReviewsByUser = function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, select, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).getReviewsByUser(accountId, tenantSlug, authorization, acceptLanguage, page, limit, select, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get profile
     * @summary Get profile
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.getUserProfile = function (authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).getUserProfile(authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Limit CCU API
     * @param {string} accountId
     * @param {string} action
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.limitCcuApi = function (accountId, action, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).limitCcuApi(accountId, action, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Limit CCU API
     * @param {string} accountId
     * @param {string} action
     * @param {string} tenantSlug
     * @param {LimitCCURequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.limitCcuApi_5 = function (accountId, action, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).limitCcuApi_5(accountId, action, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Link account with social network account
     * @summary Link account with social network account
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {LinkAccountWithSNAccountRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.linkAccount = function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).linkAccount(accountId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Remove account api
     * @summary Remove account api
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.removeAccount = function (accountId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).removeAccount(accountId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Change email
     * @summary Change email
     * @param {string} tenantSlug
     * @param {ChangeEmailRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.requestChangeEmail = function (tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).requestChangeEmail(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Change phone number for account
     * @summary Change phone number
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {ChangeMobilePhoneRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.requestChangePhoneNumber = function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).requestChangePhoneNumber(accountId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Reset parental control code
     * @summary Reset parental control code
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.resetParentalControlCode = function (accountId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).resetParentalControlCode(accountId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Send user service survey
     * @summary Send user service survey
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {UserServiceSurveyRequest} data
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.sendUserSurvey = function (accountId, tenantSlug, data, authorization, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).sendUserSurvey(accountId, tenantSlug, data, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Setup account parental control
     * @summary Setup account parental control
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {SetupParentalControlRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.setupParentalControlCode = function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).setupParentalControlCode(accountId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update profile
     * @summary Update profile
     * @param {string} tenantSlug
     * @param {UpdateProfileRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.updateAccountProfile = function (tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).updateAccountProfile(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update profile
     * @summary Update profile
     * @param {string} tenantSlug
     * @param {UpdateProfileRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.updateAccountProfile_6 = function (tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).updateAccountProfile_6(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update nickname user chat api
     * @summary Update nickname user chat api
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {UpdateUserChatRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.updateNicknameUserChatApi = function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).updateNicknameUserChatApi(accountId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update profile
     * @summary Update profile
     * @param {UpdateProfileRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.updateUserProfile = function (data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).updateUserProfile(data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Verify parental control code
     * @summary Verify parental control code
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [code] Account parental control code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.verifyParentalControlCode = function (accountId, tenantSlug, authorization, acceptLanguage, code, options) {
        var _this = this;
        return (0, exports.UserApiFp)(this.configuration).verifyParentalControlCode(accountId, tenantSlug, authorization, acceptLanguage, code, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UserApi;
}(base_1.BaseAPI));
exports.UserApi = UserApi;
