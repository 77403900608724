Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiAdapterFactory = void 0;
var odx_api_adapter_1 = require("./impls/odx-api-adapter");
// import { VimaiApiAdapter } from './impls/vimai-api-adapter'
var adapter_type_1 = require("./adapter-type");
var vimai_api_adapter_1 = require("./impls/vimai-api-adapter");
var ApiAdapterFactory = /** @class */ (function () {
    function ApiAdapterFactory() {
    }
    ApiAdapterFactory.create = function (adapterType, configs) {
        switch (adapterType) {
            case adapter_type_1.AdapterType.ODX:
                return new odx_api_adapter_1.OdxApiAdapter(configs);
            case adapter_type_1.AdapterType.VIMAI:
                return new vimai_api_adapter_1.VimaiApiAdapter(configs);
        }
    };
    return ApiAdapterFactory;
}());
exports.ApiAdapterFactory = ApiAdapterFactory;
