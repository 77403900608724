/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserContentApi = exports.UserContentApiFactory = exports.UserContentApiFp = exports.UserContentApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * UserContentApi - axios parameter creator
 * @export
 */
var UserContentApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Save recent content viewing
         * @summary Save recent content viewing
         * @param {SaveRecentContentRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        addUserRecentContent: function (data, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('addUserRecentContent', 'data', data);
                            localVarPath = "/backend_usc/recent/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create a review of user for content
         * @summary Create a review of user for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {UserContentReviewsRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReviewOfContent: function (contentId, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('createReviewOfContent', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createReviewOfContent', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createReviewOfContent', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/review/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get all interacted content by account id
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [parentId] Filter by base content
         * @param {string} [contentIds] Filter by content ids, split by ,
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInteractedContents: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, select, parentId, contentIds, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('getAccountInteractedContents', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getAccountInteractedContents', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/interacted_contents/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (parentId !== undefined) {
                                localVarQueryParameter['parent_id'] = parentId;
                            }
                            if (contentIds !== undefined) {
                                localVarQueryParameter['content_ids'] = contentIds;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * get progress content
         * @summary get progress content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentProgress: function (authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/cm/progress/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get progress of a content
         * @summary Get progress of a content
         * @param {string} contentId Id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentContentProgress: function (contentId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getCurrentContentProgress', 'contentId', contentId);
                            localVarPath = "/backend/cm/content/{content_id}/progress"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get user favorite content
         * @summary Get user favorite content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {number} [cursor] cusor paging
         * @param {string} [xhr] (0/1) is web platform
         * @param {string} [imageFormat] Image type
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteContents: function (authorization, acceptLanguage, select, imageResolutionScale, resolution, size, cursor, xhr, imageFormat, page, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/cm/favorite/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (imageResolutionScale !== undefined) {
                                localVarQueryParameter['image_resolution_scale'] = imageResolutionScale;
                            }
                            if (resolution !== undefined) {
                                localVarQueryParameter['resolution'] = resolution;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (cursor !== undefined) {
                                localVarQueryParameter['cursor'] = cursor;
                            }
                            if (xhr !== undefined) {
                                localVarQueryParameter['xhr'] = xhr;
                            }
                            if (imageFormat !== undefined) {
                                localVarQueryParameter['image_format'] = imageFormat;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get user watch later content
         * @summary Get user watch later content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {string} [cursor] cursor paging
         * @param {string} [xhr] (1/0) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getListWatchlater: function (authorization, acceptLanguage, resolution, size, cursor, xhr, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/cm/watchlater/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (resolution !== undefined) {
                                localVarQueryParameter['resolution'] = resolution;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            if (cursor !== undefined) {
                                localVarQueryParameter['cursor'] = cursor;
                            }
                            if (xhr !== undefined) {
                                localVarQueryParameter['xhr'] = xhr;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get reacted contents by account
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReactedContents: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('getReactedContents', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getReactedContents', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/reacted_contents"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get recent content of user
         * @summary Get recent content of user
         * @param {string} [authorization] Access token
         * @param {number} [page] page start at 0
         * @param {number} [limit] limit ( default is 10 )
         * @param {number} [xhr] (1/0) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRecentContentOfUser: function (authorization, page, limit, xhr, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend_usc/recent/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (xhr !== undefined) {
                                localVarQueryParameter['xhr'] = xhr;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a review of user for content
         * @summary Get a review of user for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewOfContent: function (contentId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getReviewOfContent', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getReviewOfContent', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/review/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get user content subtitle api
         * @summary Get user content subtitle api
         * @param {string} contentId id of content
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectedSubtitle: function (contentId, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getSelectedSubtitle', 'contentId', contentId);
                            localVarPath = "/backend_usc/subtitle/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (contentId !== undefined) {
                                localVarQueryParameter['content_id'] = contentId;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [Backend only] get user content
         * @summary [Backend only] get user content
         * @param {string} userId User id
         * @param {string} contentId Content id
         * @param {string} [authorization] Secret access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserContent: function (userId, contentId, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            (0, common_1.assertParamExists)('getUserContent', 'userId', userId);
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getUserContent', 'contentId', contentId);
                            localVarPath = "/backend_usc/user_content/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (userId !== undefined) {
                                localVarQueryParameter['user_id'] = userId;
                            }
                            if (contentId !== undefined) {
                                localVarQueryParameter['content_id'] = contentId;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get user content api
         * @summary Get user content api
         * @param {string} contentId id of content
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserContentProgress: function (contentId, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getUserContentProgress', 'contentId', contentId);
                            localVarPath = "/backend_usc/progress/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (contentId !== undefined) {
                                localVarQueryParameter['content_id'] = contentId;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get content watchlater of user
         * @summary Get content watchlater of user
         * @param {string} userId User id
         * @param {string} cursor Cursor
         * @param {string} [authorization] Secret access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserWatchlater: function (userId, cursor, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            (0, common_1.assertParamExists)('getUserWatchlater', 'userId', userId);
                            // verify required parameter 'cursor' is not null or undefined
                            (0, common_1.assertParamExists)('getUserWatchlater', 'cursor', cursor);
                            localVarPath = "/backend_usc/watchlater/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (userId !== undefined) {
                                localVarQueryParameter['user_id'] = userId;
                            }
                            if (cursor !== undefined) {
                                localVarQueryParameter['cursor'] = cursor;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Favorite/Unfavorite Content
         * @summary Favorite/Unfavorite Content
         * @param {UpdateFavoriteContentRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invokeFavoriteContent: function (data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('invokeFavoriteContent', 'data', data);
                            localVarPath = "/backend/cm/favorite/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * WatchLater/UnWatchLater Content
         * @summary WatchLater/UnWatchLater Content
         * @param {UpdateWatchLaterRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        invokeWatchlaterContent: function (data, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('invokeWatchlaterContent', 'data', data);
                            localVarPath = "/backend/cm/watchlater/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * React User Content
         * @summary React User Content
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ReactUserContent} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactContent: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('reactContent', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('reactContent', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('reactContent', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/react_content"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Remove content
         * @summary Remove content
         * @param {string} contentId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeContentFromHistory: function (contentId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('removeContentFromHistory', 'contentId', contentId);
                            localVarPath = "/backend/cm/content/{content_id}/progress"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove content from watching list
         * @param {string} accountId
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeContentFromWatchingList: function (accountId, contentId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('removeContentFromWatchingList', 'accountId', accountId);
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('removeContentFromWatchingList', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('removeContentFromWatchingList', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/interacted_contents/{content_id}/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * User select subtitle for content
         * @summary User select subtitle for content
         * @param {UserContentSelectSubtitleRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectSubtitle: function (data, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('selectSubtitle', 'data', data);
                            localVarPath = "/backend_usc/subtitle/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get content rating of user
         * @summary Get content rating of user
         * @param {UserRateContentRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        setUserContentRating: function (data, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('setUserContentRating', 'data', data);
                            localVarPath = "/backend_usc/rating/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update progress of a content
         * @summary Update progress of a content
         * @param {string} contentId
         * @param {UpdateProgressForContentRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentProgress: function (contentId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('updateContentProgress', 'contentId', contentId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateContentProgress', 'data', data);
                            localVarPath = "/backend/cm/content/{content_id}/progress"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update progress viewing
         * @summary Update progress viewing
         * @param {UpdateProgressRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentProgress_1: function (data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateContentProgress_1', 'data', data);
                            localVarPath = "/backend/cm/progress/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update progress content
         * @summary Update progress content
         * @param {UserUpdateProgressContentRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserContentProgress: function (data, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateUserContentProgress', 'data', data);
                            localVarPath = "/backend_usc/progress/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.UserContentApiAxiosParamCreator = UserContentApiAxiosParamCreator;
/**
 * UserContentApi - functional programming interface
 * @export
 */
var UserContentApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.UserContentApiAxiosParamCreator)(configuration);
    return {
        /**
         * Save recent content viewing
         * @summary Save recent content viewing
         * @param {SaveRecentContentRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        addUserRecentContent: function (data, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addUserRecentContent(data, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create a review of user for content
         * @summary Create a review of user for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {UserContentReviewsRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReviewOfContent: function (contentId, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createReviewOfContent(contentId, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get all interacted content by account id
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [parentId] Filter by base content
         * @param {string} [contentIds] Filter by content ids, split by ,
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInteractedContents: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, select, parentId, contentIds, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAccountInteractedContents(accountId, tenantSlug, authorization, acceptLanguage, page, limit, select, parentId, contentIds, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * get progress content
         * @summary get progress content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentProgress: function (authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentProgress(authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get progress of a content
         * @summary Get progress of a content
         * @param {string} contentId Id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentContentProgress: function (contentId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCurrentContentProgress(contentId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get user favorite content
         * @summary Get user favorite content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {number} [cursor] cusor paging
         * @param {string} [xhr] (0/1) is web platform
         * @param {string} [imageFormat] Image type
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteContents: function (authorization, acceptLanguage, select, imageResolutionScale, resolution, size, cursor, xhr, imageFormat, page, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFavoriteContents(authorization, acceptLanguage, select, imageResolutionScale, resolution, size, cursor, xhr, imageFormat, page, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get user watch later content
         * @summary Get user watch later content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {string} [cursor] cursor paging
         * @param {string} [xhr] (1/0) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getListWatchlater: function (authorization, acceptLanguage, resolution, size, cursor, xhr, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getListWatchlater(authorization, acceptLanguage, resolution, size, cursor, xhr, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get reacted contents by account
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReactedContents: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getReactedContents(accountId, tenantSlug, authorization, acceptLanguage, page, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get recent content of user
         * @summary Get recent content of user
         * @param {string} [authorization] Access token
         * @param {number} [page] page start at 0
         * @param {number} [limit] limit ( default is 10 )
         * @param {number} [xhr] (1/0) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRecentContentOfUser: function (authorization, page, limit, xhr, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getRecentContentOfUser(authorization, page, limit, xhr, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get a review of user for content
         * @summary Get a review of user for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewOfContent: function (contentId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getReviewOfContent(contentId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get user content subtitle api
         * @summary Get user content subtitle api
         * @param {string} contentId id of content
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectedSubtitle: function (contentId, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSelectedSubtitle(contentId, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [Backend only] get user content
         * @summary [Backend only] get user content
         * @param {string} userId User id
         * @param {string} contentId Content id
         * @param {string} [authorization] Secret access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserContent: function (userId, contentId, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserContent(userId, contentId, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get user content api
         * @summary Get user content api
         * @param {string} contentId id of content
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserContentProgress: function (contentId, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserContentProgress(contentId, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get content watchlater of user
         * @summary Get content watchlater of user
         * @param {string} userId User id
         * @param {string} cursor Cursor
         * @param {string} [authorization] Secret access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserWatchlater: function (userId, cursor, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserWatchlater(userId, cursor, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Favorite/Unfavorite Content
         * @summary Favorite/Unfavorite Content
         * @param {UpdateFavoriteContentRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invokeFavoriteContent: function (data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.invokeFavoriteContent(data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * WatchLater/UnWatchLater Content
         * @summary WatchLater/UnWatchLater Content
         * @param {UpdateWatchLaterRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        invokeWatchlaterContent: function (data, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.invokeWatchlaterContent(data, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * React User Content
         * @summary React User Content
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ReactUserContent} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactContent: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.reactContent(accountId, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Remove content
         * @summary Remove content
         * @param {string} contentId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeContentFromHistory: function (contentId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.removeContentFromHistory(contentId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove content from watching list
         * @param {string} accountId
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeContentFromWatchingList: function (accountId, contentId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.removeContentFromWatchingList(accountId, contentId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * User select subtitle for content
         * @summary User select subtitle for content
         * @param {UserContentSelectSubtitleRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectSubtitle: function (data, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.selectSubtitle(data, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get content rating of user
         * @summary Get content rating of user
         * @param {UserRateContentRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        setUserContentRating: function (data, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setUserContentRating(data, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update progress of a content
         * @summary Update progress of a content
         * @param {string} contentId
         * @param {UpdateProgressForContentRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentProgress: function (contentId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateContentProgress(contentId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update progress viewing
         * @summary Update progress viewing
         * @param {UpdateProgressRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentProgress_1: function (data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateContentProgress_1(data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update progress content
         * @summary Update progress content
         * @param {UserUpdateProgressContentRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserContentProgress: function (data, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateUserContentProgress(data, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.UserContentApiFp = UserContentApiFp;
/**
 * UserContentApi - factory interface
 * @export
 */
var UserContentApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.UserContentApiFp)(configuration);
    return {
        /**
         * Save recent content viewing
         * @summary Save recent content viewing
         * @param {SaveRecentContentRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        addUserRecentContent: function (data, authorization, options) {
            return localVarFp.addUserRecentContent(data, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create a review of user for content
         * @summary Create a review of user for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {UserContentReviewsRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReviewOfContent: function (contentId, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.createReviewOfContent(contentId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get all interacted content by account id
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [parentId] Filter by base content
         * @param {string} [contentIds] Filter by content ids, split by ,
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInteractedContents: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, select, parentId, contentIds, options) {
            return localVarFp.getAccountInteractedContents(accountId, tenantSlug, authorization, acceptLanguage, page, limit, select, parentId, contentIds, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * get progress content
         * @summary get progress content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentProgress: function (authorization, acceptLanguage, options) {
            return localVarFp.getContentProgress(authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get progress of a content
         * @summary Get progress of a content
         * @param {string} contentId Id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentContentProgress: function (contentId, authorization, acceptLanguage, options) {
            return localVarFp.getCurrentContentProgress(contentId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get user favorite content
         * @summary Get user favorite content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {number} [cursor] cusor paging
         * @param {string} [xhr] (0/1) is web platform
         * @param {string} [imageFormat] Image type
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteContents: function (authorization, acceptLanguage, select, imageResolutionScale, resolution, size, cursor, xhr, imageFormat, page, limit, options) {
            return localVarFp.getFavoriteContents(authorization, acceptLanguage, select, imageResolutionScale, resolution, size, cursor, xhr, imageFormat, page, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get user watch later content
         * @summary Get user watch later content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [resolution] 1x, 2x, 3x
         * @param {string} [size] small, medium, large
         * @param {string} [cursor] cursor paging
         * @param {string} [xhr] (1/0) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getListWatchlater: function (authorization, acceptLanguage, resolution, size, cursor, xhr, options) {
            return localVarFp.getListWatchlater(authorization, acceptLanguage, resolution, size, cursor, xhr, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get reacted contents by account
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReactedContents: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, options) {
            return localVarFp.getReactedContents(accountId, tenantSlug, authorization, acceptLanguage, page, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get recent content of user
         * @summary Get recent content of user
         * @param {string} [authorization] Access token
         * @param {number} [page] page start at 0
         * @param {number} [limit] limit ( default is 10 )
         * @param {number} [xhr] (1/0) is web platform
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRecentContentOfUser: function (authorization, page, limit, xhr, options) {
            return localVarFp.getRecentContentOfUser(authorization, page, limit, xhr, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a review of user for content
         * @summary Get a review of user for content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewOfContent: function (contentId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getReviewOfContent(contentId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get user content subtitle api
         * @summary Get user content subtitle api
         * @param {string} contentId id of content
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectedSubtitle: function (contentId, authorization, options) {
            return localVarFp.getSelectedSubtitle(contentId, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [Backend only] get user content
         * @summary [Backend only] get user content
         * @param {string} userId User id
         * @param {string} contentId Content id
         * @param {string} [authorization] Secret access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserContent: function (userId, contentId, authorization, options) {
            return localVarFp.getUserContent(userId, contentId, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get user content api
         * @summary Get user content api
         * @param {string} contentId id of content
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserContentProgress: function (contentId, authorization, options) {
            return localVarFp.getUserContentProgress(contentId, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get content watchlater of user
         * @summary Get content watchlater of user
         * @param {string} userId User id
         * @param {string} cursor Cursor
         * @param {string} [authorization] Secret access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserWatchlater: function (userId, cursor, authorization, options) {
            return localVarFp.getUserWatchlater(userId, cursor, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Favorite/Unfavorite Content
         * @summary Favorite/Unfavorite Content
         * @param {UpdateFavoriteContentRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invokeFavoriteContent: function (data, authorization, acceptLanguage, options) {
            return localVarFp.invokeFavoriteContent(data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * WatchLater/UnWatchLater Content
         * @summary WatchLater/UnWatchLater Content
         * @param {UpdateWatchLaterRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        invokeWatchlaterContent: function (data, authorization, options) {
            return localVarFp.invokeWatchlaterContent(data, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * React User Content
         * @summary React User Content
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ReactUserContent} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactContent: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.reactContent(accountId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Remove content
         * @summary Remove content
         * @param {string} contentId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeContentFromHistory: function (contentId, authorization, acceptLanguage, options) {
            return localVarFp.removeContentFromHistory(contentId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Remove content from watching list
         * @param {string} accountId
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeContentFromWatchingList: function (accountId, contentId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.removeContentFromWatchingList(accountId, contentId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * User select subtitle for content
         * @summary User select subtitle for content
         * @param {UserContentSelectSubtitleRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectSubtitle: function (data, authorization, options) {
            return localVarFp.selectSubtitle(data, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get content rating of user
         * @summary Get content rating of user
         * @param {UserRateContentRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        setUserContentRating: function (data, authorization, options) {
            return localVarFp.setUserContentRating(data, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update progress of a content
         * @summary Update progress of a content
         * @param {string} contentId
         * @param {UpdateProgressForContentRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentProgress: function (contentId, data, authorization, acceptLanguage, options) {
            return localVarFp.updateContentProgress(contentId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update progress viewing
         * @summary Update progress viewing
         * @param {UpdateProgressRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentProgress_1: function (data, authorization, acceptLanguage, options) {
            return localVarFp.updateContentProgress_1(data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update progress content
         * @summary Update progress content
         * @param {UserUpdateProgressContentRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserContentProgress: function (data, authorization, options) {
            return localVarFp.updateUserContentProgress(data, authorization, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.UserContentApiFactory = UserContentApiFactory;
/**
 * UserContentApi - object-oriented interface
 * @export
 * @class UserContentApi
 * @extends {BaseAPI}
 */
var UserContentApi = /** @class */ (function (_super) {
    __extends(UserContentApi, _super);
    function UserContentApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Save recent content viewing
     * @summary Save recent content viewing
     * @param {SaveRecentContentRequest} data
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.addUserRecentContent = function (data, authorization, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).addUserRecentContent(data, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create a review of user for content
     * @summary Create a review of user for content
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {UserContentReviewsRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.createReviewOfContent = function (contentId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).createReviewOfContent(contentId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get all interacted content by account id
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [parentId] Filter by base content
     * @param {string} [contentIds] Filter by content ids, split by ,
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.getAccountInteractedContents = function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, select, parentId, contentIds, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).getAccountInteractedContents(accountId, tenantSlug, authorization, acceptLanguage, page, limit, select, parentId, contentIds, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * get progress content
     * @summary get progress content
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.getContentProgress = function (authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).getContentProgress(authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get progress of a content
     * @summary Get progress of a content
     * @param {string} contentId Id of content
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.getCurrentContentProgress = function (contentId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).getCurrentContentProgress(contentId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get user favorite content
     * @summary Get user favorite content
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {string} [imageResolutionScale] Resolution scale of image fields. Example: f@hd, q@hd, ...
     * @param {string} [resolution] 1x, 2x, 3x
     * @param {string} [size] small, medium, large
     * @param {number} [cursor] cusor paging
     * @param {string} [xhr] (0/1) is web platform
     * @param {string} [imageFormat] Image type
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.getFavoriteContents = function (authorization, acceptLanguage, select, imageResolutionScale, resolution, size, cursor, xhr, imageFormat, page, limit, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).getFavoriteContents(authorization, acceptLanguage, select, imageResolutionScale, resolution, size, cursor, xhr, imageFormat, page, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get user watch later content
     * @summary Get user watch later content
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [resolution] 1x, 2x, 3x
     * @param {string} [size] small, medium, large
     * @param {string} [cursor] cursor paging
     * @param {string} [xhr] (1/0) is web platform
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.getListWatchlater = function (authorization, acceptLanguage, resolution, size, cursor, xhr, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).getListWatchlater(authorization, acceptLanguage, resolution, size, cursor, xhr, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get reacted contents by account
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.getReactedContents = function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).getReactedContents(accountId, tenantSlug, authorization, acceptLanguage, page, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get recent content of user
     * @summary Get recent content of user
     * @param {string} [authorization] Access token
     * @param {number} [page] page start at 0
     * @param {number} [limit] limit ( default is 10 )
     * @param {number} [xhr] (1/0) is web platform
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.getRecentContentOfUser = function (authorization, page, limit, xhr, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).getRecentContentOfUser(authorization, page, limit, xhr, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a review of user for content
     * @summary Get a review of user for content
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.getReviewOfContent = function (contentId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).getReviewOfContent(contentId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get user content subtitle api
     * @summary Get user content subtitle api
     * @param {string} contentId id of content
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.getSelectedSubtitle = function (contentId, authorization, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).getSelectedSubtitle(contentId, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [Backend only] get user content
     * @summary [Backend only] get user content
     * @param {string} userId User id
     * @param {string} contentId Content id
     * @param {string} [authorization] Secret access token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.getUserContent = function (userId, contentId, authorization, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).getUserContent(userId, contentId, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get user content api
     * @summary Get user content api
     * @param {string} contentId id of content
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.getUserContentProgress = function (contentId, authorization, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).getUserContentProgress(contentId, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get content watchlater of user
     * @summary Get content watchlater of user
     * @param {string} userId User id
     * @param {string} cursor Cursor
     * @param {string} [authorization] Secret access token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.getUserWatchlater = function (userId, cursor, authorization, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).getUserWatchlater(userId, cursor, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Favorite/Unfavorite Content
     * @summary Favorite/Unfavorite Content
     * @param {UpdateFavoriteContentRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.invokeFavoriteContent = function (data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).invokeFavoriteContent(data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * WatchLater/UnWatchLater Content
     * @summary WatchLater/UnWatchLater Content
     * @param {UpdateWatchLaterRequest} data
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.invokeWatchlaterContent = function (data, authorization, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).invokeWatchlaterContent(data, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * React User Content
     * @summary React User Content
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {ReactUserContent} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.reactContent = function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).reactContent(accountId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Remove content
     * @summary Remove content
     * @param {string} contentId
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.removeContentFromHistory = function (contentId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).removeContentFromHistory(contentId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Remove content from watching list
     * @param {string} accountId
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.removeContentFromWatchingList = function (accountId, contentId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).removeContentFromWatchingList(accountId, contentId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * User select subtitle for content
     * @summary User select subtitle for content
     * @param {UserContentSelectSubtitleRequest} data
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.selectSubtitle = function (data, authorization, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).selectSubtitle(data, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get content rating of user
     * @summary Get content rating of user
     * @param {UserRateContentRequest} data
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.setUserContentRating = function (data, authorization, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).setUserContentRating(data, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update progress of a content
     * @summary Update progress of a content
     * @param {string} contentId
     * @param {UpdateProgressForContentRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.updateContentProgress = function (contentId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).updateContentProgress(contentId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update progress viewing
     * @summary Update progress viewing
     * @param {UpdateProgressRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.updateContentProgress_1 = function (data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).updateContentProgress_1(data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update progress content
     * @summary Update progress content
     * @param {UserUpdateProgressContentRequest} data
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserContentApi
     */
    UserContentApi.prototype.updateUserContentProgress = function (data, authorization, options) {
        var _this = this;
        return (0, exports.UserContentApiFp)(this.configuration).updateUserContentProgress(data, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UserContentApi;
}(base_1.BaseAPI));
exports.UserContentApi = UserContentApi;
