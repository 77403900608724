import { AdminContentApiInterface } from '~vimai-api-adapter/api-adapters/api-adapter-vimai/apis/admin-content-api';
import { LoginFacebookRequest } from '~vimai-api-adapter/api-adapters/api-adapter-vimai/models/login-facebook-request';
import { store } from '~store';

import { getConfigByKey } from '~features/tenantConfig/services';
import camelcaseKeys from 'camelcase-keys';

import { AxiosPromise, AxiosResponse } from 'axios';
import {
  ChangeEmailRequest,
  ChangePasswordRequest,
  CheckPasswordRequest,
  ContactsSupportAccountRequest,
  CreateStripeSubscription,
  ForgetPasswordRequest,
  RegisterEmailRequest,
  ResendEmailRequestSerialzer,
  ResetPasswordRequest,
  UpdateFavoriteContentRequest,
  UpdatePaymentMethodRequest,
  UpdateProfileRequest,
  UpdateProgressForContentRequest,
  UserContentReviewsRequest,
} from '~vimai-api-adapter/api-adapters/api-adapter-vimai/models';
import {
  MultitenancyApiInterface,
  ContentApiInterface,
  ContentListingApiInterface,
  AuthenticationApiInterface,
  UserApiInterface,
  PaymentApiInterface,
  UserContentApiInterface,
  AdsManagementApiInterface,
} from '~vimai-api-adapter';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';
import * as APIString from '~constants/apiString';
import { IAxiosResponseData, Pagination } from '~core/models';

const {
  authenticationService,
  multitenancyService,
  contentService,
  contentListingService,
  userService,
  paymentService,
  userContentService,
  adsManagementService,
  geoBlockingService,
  adminContentService,
} = apiAdapter;

export {
  get_menu,
  get_page,
  get_ribbon,
  get_page_menu,
  get_detail,
  get_season,
  get_relate,
  get_epg,
  get_clip_hay,
  get_all_channel,
  get_search,
  login_email,
  loginPhoneNumber,
  requestCode,
  resetPassword,
  register_email,
  changePassword,
  getProfile,
  fbLogin,
  updateProfile,
  get_payment_plan,
  refresh_stb_auth_code,
  paymentPlan,
  cancel_subscription_plan,
  get_actived_subscription_plan,
  register_or_login,
  subscription_history,
  post_progress,
  get_progress,
  change_email,
  resend_email,
  supports,
  confirmEmail,
  updateAccountPaymentMethod,
  getAdsVideo,
  getAccountPaymentMethod,
  postFavorite,
  getFavorite,
  registerEmail,
  allowedCountries,
  reportTemplates,
  uploadImage,
  getAccountInfo,
  subscriptionHistoryV2,
  _checkCurrentPassword,
  updateProfileRequest,
  updateAvatarRequest,
  removeAccount,
  getListReview,
  removeMyReview,
  updateReview,
  postRequestRefund,
  getRequestRefund,
  cancelRequestRefund,
};

const SEARCH_PAGE_SELECT_FIELDS = ['items', 'keyword', 'suggested_items'];

const SEARCH_PAGE_CONTENT_ITEMS_SELECT_FIELDS = [
  'can_preview',
  'content_categories',
  'has_free_content',
  'id',
  'images',
  'is_new_release',
  'is_premium',
  'is_watchable',
  'num_first_episode_preview',
  'slug',
  'title',
  'type',
  'video_source',
  'release_date',
  'regions',
  'total_episodes',
  'metadata',
  'runtime',
  'released_episode_count',
  'is_favorite',
  'payment_type',
  'short_description',
  'long_description',
  'duration',
];

export const CONTENT_METADATA = ['regions'];

const CONTENT_FAVORITE_PAGE_SELECT_FIELDS = [
  'id',
  'slug',
  'title',
  'images',
  'is_new_release',
  'is_premium',
  'is_watchable',
  'youtube_video_id',
  'video_source',
  'category_slug',
  'total_episodes',
  'release_date',
  'has_free_content',
  'metadata',
  'type',
  'runtime',
  'released_episode_count',
  'is_favorite',
  'payment_type',
  'duration',
  'content_categories',
  'short_description',
  'long_description',
];

function get_menu(): Promise<IAxiosResponseData<MultitenancyApiInterface['getPlatformDetail']>> {
  const DISALLOWED_PAGE_TYPES = ['SEARCH', 'MY_LIST', 'COUNTRY'];
  const { authorization = '', platformSlug = '' } = config;

  return multitenancyService
    .getPlatformDetail(platformSlug, authorization)
    .then(axiosTakeDataFromResponse)
    .then((pages: IAxiosResponseData<MultitenancyApiInterface['getPlatformDetail']>) =>
      pages.filter(page => !DISALLOWED_PAGE_TYPES.includes(page.display_style || '')),
    );
}

function get_page(): Promise<IAxiosResponseData<ContentApiInterface['getHomePage']>> {
  const { platformSlug = '', tenantSlug = '', authorization } = config;

  return contentService
    .getHomePage(platformSlug, tenantSlug, authorization)
    .then(axiosTakeDataFromResponse);
}

function get_page_menu(
  id: string,
  randomString: string,
): Promise<IAxiosResponseData<ContentListingApiInterface['getTenantPageDetail']>> {
  const { platformSlug, tenantSlug, authorization } = config;

  return contentListingService
    .getTenantPageDetail(id, authorization, undefined, platformSlug)
    .then(axiosTakeDataFromResponse);
}

function get_ribbon(
  id: string,
  page = 0,
): Promise<IAxiosResponseData<ContentListingApiInterface['getRibbonItems']>> {
  const { platformSlug, authorization } = config;

  return contentListingService
    .getRibbonItems(id, authorization, undefined, page, undefined, platformSlug)
    .then(axiosTakeDataFromResponse);
}

function get_detail(
  slug: string,
): Promise<IAxiosResponseData<ContentApiInterface['getDetailByContentId']>> {
  const { platformSlug, imageResolutionScale, authorization } = config;

  return contentService
    .getDetailByContentId(
      slug,
      authorization,
      undefined,
      imageResolutionScale,
      undefined,
      platformSlug,
    )
    .then(axiosTakeDataFromResponse);
}

function get_season(
  season_id: string,
): Promise<IAxiosResponseData<ContentListingApiInterface['getSeasonDetailById']>> {
  const { authorization, userId } = config;

  return contentListingService
    .getSeasonDetailById(season_id, authorization, undefined, userId)
    .then(axiosTakeDataFromResponse);
}

function get_relate(
  id: string,
): Promise<IAxiosResponseData<ContentListingApiInterface['getRelatedContentById']>> {
  const { authorization } = config;

  return contentListingService
    .getRelatedContentById(id, authorization)
    .then(axiosTakeDataFromResponse);
}

function get_epg(params: {
  channel_id: string;
  schedule_date: string;
}): Promise<IAxiosResponseData<ContentListingApiInterface['getChannelEpgs']>> {
  const { channel_id, schedule_date } = params;

  return contentListingService
    .getChannelEpgs(
      undefined,
      undefined,
      undefined,
      undefined,
      schedule_date,
      undefined,
      undefined,
      channel_id,
    )
    .then(axiosTakeDataFromResponse);
}

function get_clip_hay(
  id: string,
): Promise<IAxiosResponseData<ContentListingApiInterface['getListClipHay']>> {
  const { tenantSlug, authorization } = config;

  return contentListingService.getListClipHay(id, authorization).then(axiosTakeDataFromResponse);
}

function get_all_channel(): Promise<
  IAxiosResponseData<ContentListingApiInterface['getListLiveChannel']>
> {
  const { authorization } = config;

  return contentListingService.getListLiveChannel(authorization).then(axiosTakeDataFromResponse);
}

function get_search(params: {
  keyword: string;
  page: number;
  limit: number;
}): Promise<IAxiosResponseData<ContentApiInterface['getSearchContents']>> {
  const select = JSON.stringify({
    Search: SEARCH_PAGE_SELECT_FIELDS,
    Movie: SEARCH_PAGE_CONTENT_ITEMS_SELECT_FIELDS,
    ContentMetadata: CONTENT_METADATA,
  });
  const search = encodeURIComponent(params.keyword);
  const data = { ...params };
  const { page, limit } = camelcaseKeys(data);
  const { imageResolutionScale, tenantSlug = '', authorization, imageFormat } = config;

  return contentService
    .getSearchContents(
      tenantSlug,
      search,
      authorization,
      undefined,
      page,
      limit,
      select,
      imageResolutionScale,
      imageFormat,
    )
    .then(axiosTakeDataFromResponse);
}

function login_email(
  email: string,
  password: string,
): Promise<IAxiosResponseData<AuthenticationApiInterface['loginWithEmail']>> {
  const { tenantSlug = '' } = config;

  return authenticationService
    .loginWithEmail(tenantSlug, {
      email,
      password,
    })
    .then(axiosTakeDataFromResponse);
}

function loginPhoneNumber(
  username: string,
  password: string,
): Promise<IAxiosResponseData<AuthenticationApiInterface['loginWithPhoneOrEmail']>> {
  const data = {
    username,
    password,
  };
  const { tenantSlug = '' } = config;

  return authenticationService
    .loginWithPhoneOrEmail(tenantSlug, data)
    .then(axiosTakeDataFromResponse);
}

function requestCode(
  email: ForgetPasswordRequest['email'],
  url: ForgetPasswordRequest['reset_password_page_url'],
  phoneNumber: ForgetPasswordRequest['phone_number'] = null,
): Promise<IAxiosResponseData<AuthenticationApiInterface['forgetUserPassword']>> {
  let data: ForgetPasswordRequest = {
    email,
    reset_password_page_url: url,
  };
  if (phoneNumber) {
    data = {
      reset_password_page_url: url,
      phone_number: phoneNumber,
    };
  }
  const { tenantSlug = '', authorization } = config;

  return authenticationService
    .forgetUserPassword(tenantSlug, data, authorization)
    .then(axiosTakeDataFromResponse);
}

function resetPassword(
  email: ResetPasswordRequest['email'],
  otp: ResetPasswordRequest['otp'],
  new_password: ResetPasswordRequest['new_password'],
): Promise<IAxiosResponseData<AuthenticationApiInterface['resetUserPassword']>> {
  const { tenantSlug = '' } = config;
  const payload: ResetPasswordRequest = {
    email,
    otp,
    new_password,
  };

  return authenticationService
    .resetUserPassword(tenantSlug, payload)
    .then(axiosTakeDataFromResponse);
}

function register_email(
  params: {
    firstName: RegisterEmailRequest['first_name'];
    lastName: RegisterEmailRequest['last_name'];
    phoneNumber: RegisterEmailRequest['phone_number'];
  } & Omit<RegisterEmailRequest, 'first_name' | 'last_name' | 'phone_number'>,
): Promise<IAxiosResponseData<AuthenticationApiInterface['registerEmailAccount']>> {
  const {
    email,
    firstName: first_name,
    lastName: last_name,
    phoneNumber: phone_number,
    password,
    gender,
    dob,
  } = params;

  const payload: RegisterEmailRequest = {
    email,
    first_name,
    last_name,
    phone_number,
    password,
    gender,
    dob,
  };
  const { tenantSlug = '' } = config;

  return authenticationService
    .registerEmailAccount(tenantSlug, payload)
    .then(axiosTakeDataFromResponse);
}

function changePassword(
  old_password: ChangePasswordRequest['old_password'],
  password: ChangePasswordRequest['password'],
): Promise<IAxiosResponseData<AuthenticationApiInterface['changeUserPassword']>> {
  const { tenantSlug = '', authorization = '' } = config;
  const payload: ChangePasswordRequest = {
    old_password,
    password,
  };
  return authenticationService
    .changeUserPassword(tenantSlug, payload, authorization)
    .then(axiosTakeDataFromResponse);
}

function getProfile(): Promise<IAxiosResponseData<UserApiInterface['getAccountProfile']>> {
  const { tenantSlug = '', authorization } = config;

  return userService.getAccountProfile(tenantSlug, authorization).then(axiosTakeDataFromResponse);
}

function fbLogin(
  token: LoginFacebookRequest['token'],
  platform: LoginFacebookRequest['platform'],
  model: LoginFacebookRequest['model'],
  device_id: LoginFacebookRequest['device_id'],
  mac_address: LoginFacebookRequest['mac_address'],
  push_token: LoginFacebookRequest['push_token'] = '',
): Promise<IAxiosResponseData<AuthenticationApiInterface['loginWithFacebook']>> {
  const { tenantSlug = '' } = config;
  const payload: LoginFacebookRequest = {
    token,
    platform,
    model,
    device_id,
    mac_address,
    push_token,
  };
  return authenticationService
    .loginWithFacebook(tenantSlug, payload)
    .then(axiosTakeDataFromResponse);
}

function updateProfile(
  params: {
    firstName: UpdateProfileRequest['first_name'];
    lastName: UpdateProfileRequest['last_name'];
    phoneNumber: UpdateProfileRequest['phone_number'];
  } & Omit<UpdateProfileRequest, 'first_name' | 'last_name' | 'phone_number'>,
): Promise<IAxiosResponseData<UserApiInterface['updateAccountProfile']>> {
  const {
    firstName: first_name,
    lastName: last_name,
    phoneNumber: phone_number,
    gender,
    dob,
    email,
    password,
    avatar,
  } = params;

  const { tenantSlug = '', authorization } = config;
  const payload: UpdateProfileRequest = {
    first_name,
    last_name,
    phone_number,
    gender,
    dob,
    email,
    password,
    avatar,
  };

  return userService
    .updateAccountProfile(tenantSlug, payload, authorization)
    .then(axiosTakeDataFromResponse);
}

function get_payment_plan(): Promise<
  IAxiosResponseData<PaymentApiInterface['getListSubscriptionPlan']>
> {
  const { platformSlug, tenantSlug = '', authorization, environment } = config;

  return paymentService
    .getListSubscriptionPlan(tenantSlug, authorization, undefined, environment, platformSlug)
    .then(axiosTakeDataFromResponse);
}

function refresh_stb_auth_code(): Promise<
  IAxiosResponseData<AuthenticationApiInterface['refreshAuthorizationCode']>
> {
  const { authorization } = config;

  return authenticationService
    .refreshAuthorizationCode(authorization)
    .then(axiosTakeDataFromResponse);
}

function paymentPlan(
  stripe_plan_id: CreateStripeSubscription['stripe_plan_id'],
  stripe_payment_method_id: CreateStripeSubscription['stripe_payment_method_id'],
  product: CreateStripeSubscription['product'],
): Promise<IAxiosResponseData<PaymentApiInterface['createStripeSubscription']>> {
  const data: CreateStripeSubscription = {
    stripe_plan_id,
    stripe_payment_method_id,
    product,
  };
  const { tenantSlug = '', authorization } = config;

  return paymentService
    .createStripeSubscription(tenantSlug, data, authorization)
    .then(axiosTakeDataFromResponse);
}

function get_actived_subscription_plan(): Promise<
  IAxiosResponseData<PaymentApiInterface['getCurrentSubscriptionPlan']>
> {
  const { tenantSlug = '', authorization } = config;

  return paymentService
    .getCurrentSubscriptionPlan(tenantSlug, authorization)
    .then(axiosTakeDataFromResponse);
}

function cancel_subscription_plan(): Promise<
  IAxiosResponseData<PaymentApiInterface['cancelUserSubscription']>
> {
  const { tenantSlug = '', authorization } = config;

  return paymentService
    .cancelUserSubscription(tenantSlug, authorization)
    .then(axiosTakeDataFromResponse);
}

function register_or_login(
  email: RegisterEmailRequest['email'],
  password: RegisterEmailRequest['password'],
): Promise<IAxiosResponseData<AuthenticationApiInterface['registerOrLogin']>> {
  const { tenantSlug = '' } = config;
  const payload: RegisterEmailRequest = { email, password };
  return authenticationService.registerOrLogin(tenantSlug, payload).then(axiosTakeDataFromResponse);
}
function subscription_history(): Promise<
  IAxiosResponseData<PaymentApiInterface['getUserInvoiceHistory']>
> {
  const { tenantSlug = '', authorization } = config;

  return paymentService
    .getUserInvoiceHistory(tenantSlug, authorization)
    .then(axiosTakeDataFromResponse);
}

function post_progress(
  content_id: string,
  progress: UpdateProgressForContentRequest['progress'],
): Promise<IAxiosResponseData<UserContentApiInterface['updateContentProgress']> | void> {
  const payload: UpdateProgressForContentRequest = { progress };
  const { authorization } = config;
  if (!authorization) return Promise.resolve();
  return userContentService
    .updateContentProgress(content_id, payload, authorization)
    .then(axiosTakeDataFromResponse);
}

function get_progress(
  content_id: string,
): Promise<IAxiosResponseData<UserContentApiInterface['getCurrentContentProgress']>> {
  const { authorization } = config;

  return userContentService
    .getCurrentContentProgress(content_id, authorization)
    .then(axiosTakeDataFromResponse);
}

function change_email(
  old_email: ChangeEmailRequest['old_email'],
  new_email: ChangeEmailRequest['new_email'],
): Promise<IAxiosResponseData<UserApiInterface['requestChangeEmail']>> {
  const { tenantSlug = '', authorization } = config;

  const payload: ChangeEmailRequest = { old_email, new_email };
  return userService
    .requestChangeEmail(tenantSlug, payload, authorization)
    .then(axiosTakeDataFromResponse);
}

function resend_email(
  type: ResendEmailRequestSerialzer['type'],
): Promise<IAxiosResponseData<AuthenticationApiInterface['resendConfirmationEmail']>> {
  const { tenantSlug = '', authorization } = config;
  const payload: ResendEmailRequestSerialzer = { type };
  return authenticationService
    .resendConfirmationEmail(tenantSlug, payload, authorization)
    .then(axiosTakeDataFromResponse);
}

function supports(
  payload: ContactsSupportAccountRequest,
): Promise<IAxiosResponseData<UserApiInterface['contactSupport']>> {
  const { tenantSlug = '', authorization } = config;
  return userService
    .contactSupport(tenantSlug, payload, authorization)
    .then(axiosTakeDataFromResponse);
}

function confirmEmail(data: {
  email: string;
  otp: string;
}): Promise<IAxiosResponseData<UserApiInterface['confirmEmail']>> {
  const { email, otp } = data;
  const { tenantSlug = '' } = config;

  return userService.confirmEmail(tenantSlug, otp, email).then(axiosTakeDataFromResponse);
}

function updateAccountPaymentMethod(
  data: UpdatePaymentMethodRequest,
): Promise<IAxiosResponseData<PaymentApiInterface['updateUserPaymentMethod']>> {
  const { tenantSlug = '', authorization } = config;

  return paymentService
    .updateUserPaymentMethod(tenantSlug, data, authorization)
    .then(axiosTakeDataFromResponse);
}

function getAdsVideo(params: {
  content_id: string;
}): Promise<IAxiosResponseData<AdsManagementApiInterface['getPlatformVideoAdsInfo']>> {
  const { content_id } = params;
  const { platformSlug = '', tenantSlug = '', authorization } = config;

  return adsManagementService
    .getPlatformVideoAdsInfo(platformSlug, tenantSlug, authorization, undefined, content_id)
    .then(axiosTakeDataFromResponse);
}

function getAccountPaymentMethod(): Promise<
  IAxiosResponseData<PaymentApiInterface['getUserPaymentMethod']>
> {
  const { tenantSlug = '', authorization } = config;

  return paymentService
    .getUserPaymentMethod(tenantSlug, authorization)
    .then(axiosTakeDataFromResponse);
}

function postFavorite(
  idContent: UpdateFavoriteContentRequest['content_id'],
): Promise<IAxiosResponseData<UserContentApiInterface['invokeFavoriteContent']>> {
  const { authorization } = config;

  return userContentService
    .invokeFavoriteContent({ content_id: idContent }, authorization)
    .then(axiosTakeDataFromResponse);
}

function getFavorite(): Promise<
  IAxiosResponseData<UserContentApiInterface['getFavoriteContents']>
> {
  const select = JSON.stringify({
    MovieDetail: CONTENT_FAVORITE_PAGE_SELECT_FIELDS,
    ContentMetadata: CONTENT_METADATA,
  });
  const { authorization, imageResolutionScale, imageFormat } = config;

  return userContentService
    .getFavoriteContents(
      authorization,
      undefined,
      select,
      imageResolutionScale,
      undefined,
      undefined,
      undefined,
      undefined,
      imageFormat,
    )
    .then(axiosTakeDataFromResponse);
}
function registerEmail(
  email: RegisterEmailRequest['email'],
  password: RegisterEmailRequest['password'],
): Promise<IAxiosResponseData<AuthenticationApiInterface['registerEmailAccount']>> {
  const { tenantSlug = '', authorization } = config;
  const payload: RegisterEmailRequest = {
    email,
    password,
  };
  return authenticationService
    .registerEmailAccount(tenantSlug, payload, authorization)
    .then(axiosTakeDataFromResponse);
}

function allowedCountries(): Promise<{ isBlock: boolean; result: any }> {
  if (getConfigByKey('should_display_geoblocked_contents_in_listing')) {
    return Promise.resolve({ isBlock: false, result: undefined });
  }
  const { tenantSlug = '', authorization } = config;

  return new Promise((resolve, reject) => {
    geoBlockingService
      .checkAllowedCountry(tenantSlug, authorization, undefined, true)
      .then(axiosTakeDataFromResponse)
      .then(response => {
        resolve({ isBlock: false, result: response });
      })
      .catch(error => {
        if (error.error_code === 'country_blocking_exception') {
          return resolve({ isBlock: true, result: error });
        }
        reject(error);
      });
  });
}

export function allowedCountriesContent(): Promise<{
  isGeoBlocked?: boolean;
  result?: any;
}> {
  if (!getConfigByKey('should_display_geoblocked_contents_in_listing')) {
    return Promise.resolve({});
  }
  const { tenantSlug = '', authorization } = config;

  return new Promise((resolve, reject) => {
    geoBlockingService
      .checkAllowedCountry(tenantSlug, authorization, undefined, true)
      .then(axiosTakeDataFromResponse)
      .then(response => {
        resolve({});
      })
      .catch(error => {
        if (error.error_code === 'country_blocking_exception') {
          return resolve({ isGeoBlocked: true, result: error });
        }
        reject(error);
      });
  });
}

function reportTemplates(): Promise<
  IAxiosResponseData<ContentApiInterface['getContentReportTemplates']>
> {
  const { tenantSlug = '', authorization } = config;

  return contentService
    .getContentReportTemplates(tenantSlug, undefined, undefined, undefined, authorization)
    .then(axiosTakeDataFromResponse);
}

function uploadImage(
  data: File,
): Promise<IAxiosResponseData<AdminContentApiInterface['uploadImage']>> {
  const { tenantSlug = '', authorization } = config;

  const filename = data.name.split('.')[0];
  const file = data;
  const formData: any = new FormData();

  formData.append('filename', filename);
  formData.append('file', file);

  return adminContentService
    .uploadImage(tenantSlug, formData, authorization, undefined, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(axiosTakeDataFromResponse);
}

function getAccountInfo(): Promise<IAxiosResponseData<UserApiInterface['getAccountInfo']>> {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return Promise.reject();
  }
  const { accountId = '', tenantSlug = '', authorization } = config;

  return userService
    .getAccountInfo(accountId, tenantSlug, authorization)
    .then(axiosTakeDataFromResponse);
}

function subscriptionHistoryV2(): Promise<
  IAxiosResponseData<PaymentApiInterface['getUserSubscriptionHistory']> | any[]
> {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return Promise.resolve([]);
  }
  const { accountId = '', tenantSlug = '', authorization } = config;

  return paymentService
    .getUserSubscriptionHistory(accountId, tenantSlug, authorization)
    .then(axiosTakeDataFromResponse);
}

function _checkCurrentPassword(
  password: string,
): Promise<IAxiosResponseData<AuthenticationApiInterface['checkAccountPassword']>> | void {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return;
  }
  const { accountId = '', tenantSlug = '', authorization } = config;
  const payload: CheckPasswordRequest = {
    password,
  };

  return authenticationService
    .checkAccountPassword(accountId, tenantSlug, payload, authorization)
    .then(axiosTakeDataFromResponse);
}

function updateAvatarRequest(params: any) {
  const { avatar } = params;
  const { tenantSlug = '', authorization, acceptLanguage } = config;
  const formdata: any = new FormData();
  formdata.append('avatar', avatar);

  return userService
    .updateAccountProfile(tenantSlug, formdata, authorization, acceptLanguage, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(axiosTakeDataFromResponse);
}

function updateProfileRequest(
  params: {
    firstName: UpdateProfileRequest['first_name'];
    lastName: UpdateProfileRequest['last_name'];
  } & Pick<UpdateProfileRequest, 'email' | 'password'>,
): Promise<IAxiosResponseData<UserApiInterface['updateAccountProfile']>> {
  const { firstName: first_name, lastName: last_name, email, password } = params;
  const data: UpdateProfileRequest = {
    first_name,
    last_name,
    email,
    password,
  };
  const { tenantSlug = '', authorization } = config;

  return userService
    .updateAccountProfile(tenantSlug, data, authorization)
    .then(axiosTakeDataFromResponse);
}

function removeAccount(): Promise<IAxiosResponseData<UserApiInterface['removeAccount']>> | void {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return;
  }
  const { accountId = '', tenantSlug = '', authorization } = config;

  return userService
    .removeAccount(accountId, tenantSlug, authorization)
    .then(axiosTakeDataFromResponse);
}

function getListReview(params: {
  page: number;
  limit: number;
}): Promise<IAxiosResponseData<UserApiInterface['getReviewsByUser']> | void> {
  const { page, limit } = params;
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return Promise.resolve();
  }
  const { accountId = '', tenantSlug = '', authorization } = config;

  return userService
    .getReviewsByUser(accountId, tenantSlug, authorization, undefined, page, limit)
    .then(axiosTakeDataFromResponse);
}

function removeMyReview(
  review_id: string,
): Promise<IAxiosResponseData<ContentApiInterface['deleteContentReviewDetail']>> {
  const { tenantSlug = '', authorization } = config;

  return contentService
    .deleteContentReviewDetail(review_id, tenantSlug, authorization)
    .then(axiosTakeDataFromResponse);
}

function updateReview(
  params: { review_id: string } & UserContentReviewsRequest,
): Promise<IAxiosResponseData<ContentApiInterface['updateContentRealView']>> {
  const { review_id, rating, comment } = params;
  const { tenantSlug = '', authorization } = config;
  const payload: UserContentReviewsRequest = {
    rating,
    comment,
  };
  return contentService
    .updateContentReviewDetail(review_id, tenantSlug, payload, authorization)
    .then(axiosTakeDataFromResponse);
}

function postRequestRefund(params: {
  content_id: string;
}): Promise<IAxiosResponseData<PaymentApiInterface['requestContentRefund']>> {
  const { content_id } = params;

  const { tenantSlug = '', authorization, acceptLanguage } = config;

  return paymentService.requestContentRefund(content_id, tenantSlug, authorization, acceptLanguage);
}

function cancelRequestRefund(params: {
  id: string;
}): Promise<IAxiosResponseData<PaymentApiInterface['cancelRequestRefund']>> {
  const { id } = params;
  const { tenantSlug = '', authorization } = config;

  return paymentService
    .cancelRequestRefund(id, tenantSlug, authorization)
    .then(axiosTakeDataFromResponse);
}

function getRequestRefund(
  params: {
    paid_content_id: string;
    status: number;
  } & Pagination,
): Promise<IAxiosResponseData<PaymentApiInterface['getApprovedRefunds']>> | void {
  const { paid_content_id, page, limit, status } = params;
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return;
  }
  const { accountId = '', tenantSlug = '', authorization } = config;

  return paymentService
    .getApprovedRefunds(accountId, tenantSlug, authorization, undefined, page, limit, status)
    .then(axiosTakeDataFromResponse);
}
