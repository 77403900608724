import _ from 'lodash';
import { TENANT_NAME_ENUM } from '~core/constants/tenantConstants';

export const ROUTE_NAMES = Object.freeze({
  homePage: 'homePage',
  detailSlugPage: 'detailSlugPage',
  landingSlugPage: 'landingSlugPage',
  ribbonPage: 'ribbonSlugPage',
  favoritePage: 'favoritePage',
  searchPage: 'searchPage',
  searchKeywordPage: 'searchKeywordPage',
  searchKeywordPagePage: 'searchKeywordPagePage',
  profilePage: 'profilePage',
  helpPage: 'helpPage',
  faqPage: 'faqPage',
  tenantPage: 'tenantPage',
  categorySlugPage: 'categorySlugPage',
  blockPage: 'blockPage',
  installAppPage: 'installAppPage',
  youtubeSlugPage: 'youtubeSlugPage',
  youtubeSlugSearchPage: 'youtubeSlugSearchPage',
  detailSlugPreviewPage: 'detailSlugPreviewPage',
  vodListPage: 'vodListPage',
  detailPage: 'detailPage',
  metadataSlug: 'metadataSlug',
  accountConfirm: 'accountConfirm',
  authLoginSso: 'authLoginSso',
  termsOfUse: 'termsOfUse',
  passwordChange: 'passwordChange',
  boxOffers: 'boxOffers',
  termsOfService: 'termsOfService',
  privacyPolicy: 'privacyPolicy',
  reviewPage: 'reviewPage',
  castAndCrewPage: 'cashAndCrewPage',
  searchResultPage: 'searchResultPage',
  logoutPage: 'logoutPage',
});

export const SSR_ROUTE_PROJECTW = [
  ROUTE_NAMES.tenantPage,
  ROUTE_NAMES.vodListPage,
  ROUTE_NAMES.metadataSlug,
];

export const SSR_ROUTE_ODV = [ROUTE_NAMES.tenantPage, ROUTE_NAMES.detailPage];

export const SSR_ROUTES_PER_TENANTS = Object.freeze({
  [TENANT_NAME_ENUM.PROJECTW]: SSR_ROUTE_PROJECTW,
  [TENANT_NAME_ENUM.ODV]: SSR_ROUTE_ODV,
});
interface Route {
  name: string;
  [key: string]: any;
}

export const getCSRRoutes = ({
  allRoutes,
  excludeRoutes,
  tenantName,
}: {
  allRoutes: Route[];
  excludeRoutes: string[];
  tenantName: string;
}) => {
  const ssrRoutesByTenant = excludeRoutes || [];
  const csrRoutes: string[] = [];
  if (_.isEmpty(ssrRoutesByTenant)) {
    return allRoutes;
  }
  allRoutes.map(route => {
    if (ssrRoutesByTenant.includes(route.name)) {
      return null;
    }
    csrRoutes.push(route.name);
  });
  return csrRoutes;
};

export const geObjRoutesByNames = (allObjRoutes: Route[] = [], routeNames: string[] = []) =>
  allObjRoutes.filter(objRoute => routeNames.includes(objRoute.name));
