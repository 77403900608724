/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminContentApi = exports.AdminContentApiFactory = exports.AdminContentApiFp = exports.AdminContentApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * AdminContentApi - axios parameter creator
 * @export
 */
var AdminContentApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Add content to ribbon api
         * @summary Add content to ribbon api
         * @param {string} ribbonId
         * @param {string} tenantSlug
         * @param {AddContentToRibbonRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContentToRibon: function (ribbonId, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'ribbonId' is not null or undefined
                            (0, common_1.assertParamExists)('addContentToRibon', 'ribbonId', ribbonId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('addContentToRibon', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('addContentToRibon', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/ribbon_resources/{ribbon_id}/contents"
                                .replace("{".concat("ribbon_id", "}"), encodeURIComponent(String(ribbonId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Bulk update payment type and TVOD price many content
         * @param {string} tenantId
         * @param {BulkUpdatePaymentTypeAndPriceRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdatePaymentTypeAndPriceOfContents: function (tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('bulkUpdatePaymentTypeAndPriceOfContents', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('bulkUpdatePaymentTypeAndPriceOfContents', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/contents/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create content with info
         * @param {string} tenantId
         * @param {ContentResource} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContent: function (tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('createContent', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createContent', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/contents/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [ADMIN-TOOL-ONLY] Create index
         * @summary [ADMIN-TOOL-ONLY] Create index
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIndexIndices: function (authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/cm/private/manage_index_document/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create one sub category
         * @summary Create one sub category
         * @param {string} tenantSlug
         * @param {CmsSubCategory} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewSubCategory: function (tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createNewSubCategory', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createNewSubCategory', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/sub_categories/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create one tag
         * @summary Create one tag
         * @param {string} tenantSlug
         * @param {CmsTag} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewTag: function (tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createNewTag', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createNewTag', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/tags/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete content
         * @param {string} id A unique value identifying this vod entity.
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContent: function (id, tenantId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('deleteContent', 'id', id);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteContent', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/contents/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete a review
         * @summary Delete a review
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentReview: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('deleteContentReview', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('deleteContentReview', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/reviews/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [ADMIN-TOOL-ONLY] Delete index
         * @summary [ADMIN-TOOL-ONLY] Delete index
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIndexIndices: function (authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/cm/private/manage_index_document/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [ADMIN-TOOL-ONLY] Delete index content by id
         * @summary [ADMIN-TOOL-ONLY] Delete index content by id
         * @param {string} contentId id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIndexOfContent: function (contentId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteIndexOfContent', 'contentId', contentId);
                            localVarPath = "/backend/cm/private/index_document/{content_id}/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete list content
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListContent: function (tenantId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteListContent', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/contents/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get one content info
         * @param {string} id A unique value identifying this vod entity.
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContent: function (id, tenantId, authorization, acceptLanguage, select, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getContent', 'id', id);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getContent', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/contents/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a review
         * @summary Get a review
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentReview: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getContentReview', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentReview', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/reviews/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get reviews base on page and page_size
         * @summary Get reviews base on page and page_sized
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentReviews: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentReviews', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/reviews/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get content statistic api
         * @summary Get content statistic api
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {'LAST_30_DAYS' | 'LAST_MONTH' | 'LAST_3_MONTHS'} [periodicTime] Periodic time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentStatistic: function (tenantSlug, authorization, acceptLanguage, periodicTime, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getContentStatistic', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/content_statistics/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (periodicTime !== undefined) {
                                localVarQueryParameter['periodic_time'] = periodicTime;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get DRM config
         * @summary Get DRM config
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrmConfigDetail: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getDrmConfigDetail', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getDrmConfigDetail', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/drm_configs/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all DRM Config resource
         * @summary List all DRM Config resource
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrmConfigs: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, select, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getDrmConfigs', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/drm_configs/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [ADMIN-TOOL-ONLY] Get index
         * @summary [ADMIN-TOOL-ONLY] Get index
         * @param {string} contentId id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndexOfContent: function (contentId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('getIndexOfContent', 'contentId', contentId);
                            localVarPath = "/backend/cm/private/index_document/{content_id}/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get list content
         * @param {string} tenantId
         * @param {number} [videoSource]
         * @param {string} [slug]
         * @param {string} [paymentType]
         * @param {string} [createdAt]
         * @param {string} [licenseExpireTime]
         * @param {number} [status]
         * @param {string} [showId]
         * @param {string} [seasonId]
         * @param {string} [excludeIds] list of exclude ids: [\&quot;abc\&quot;]
         * @param {string} [price]
         * @param {string} [inMrss]
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [relatedType] Special type for season and episode. Season &#x3D; 3, episode &#x3D; 4
         * @param {string} [ids] list of id: [\&#39;ad\&#39;, \&#39;d\&#39;]
         * @param {string} [mrssId] Mrss id
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListContents: function (tenantId, videoSource, slug, paymentType, createdAt, licenseExpireTime, status, showId, seasonId, excludeIds, price, inMrss, ordering, search, page, pageSize, authorization, acceptLanguage, relatedType, ids, mrssId, select, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getListContents', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/contents/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (videoSource !== undefined) {
                                localVarQueryParameter['video_source'] = videoSource;
                            }
                            if (slug !== undefined) {
                                localVarQueryParameter['slug'] = slug;
                            }
                            if (paymentType !== undefined) {
                                localVarQueryParameter['payment_type'] = paymentType;
                            }
                            if (createdAt !== undefined) {
                                localVarQueryParameter['created_at'] = createdAt;
                            }
                            if (licenseExpireTime !== undefined) {
                                localVarQueryParameter['license_expire_time'] = licenseExpireTime;
                            }
                            if (status !== undefined) {
                                localVarQueryParameter['status'] = status;
                            }
                            if (showId !== undefined) {
                                localVarQueryParameter['show_id'] = showId;
                            }
                            if (seasonId !== undefined) {
                                localVarQueryParameter['season_id'] = seasonId;
                            }
                            if (excludeIds !== undefined) {
                                localVarQueryParameter['exclude_ids'] = excludeIds;
                            }
                            if (price !== undefined) {
                                localVarQueryParameter['price'] = price;
                            }
                            if (inMrss !== undefined) {
                                localVarQueryParameter['in_mrss'] = inMrss;
                            }
                            if (ordering !== undefined) {
                                localVarQueryParameter['ordering'] = ordering;
                            }
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (relatedType !== undefined) {
                                localVarQueryParameter['related_type'] = relatedType;
                            }
                            if (ids !== undefined) {
                                localVarQueryParameter['ids'] = ids;
                            }
                            if (mrssId !== undefined) {
                                localVarQueryParameter['mrss_id'] = mrssId;
                            }
                            if (select !== undefined) {
                                localVarQueryParameter['select'] = select;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Generate presigned url for uploading file to s3 bucket
         * @summary Get presigned url for uploading file to s3 bucket
         * @param {string} tenantSlug
         * @param {GeneratePresignedUrlRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresignedUrlForS3Upload: function (tenantSlug, data, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPresignedUrlForS3Upload', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('getPresignedUrlForS3Upload', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/files/client_upload_urls"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get presigned urls for uploading multipart file
         * @summary Get presigned urls for uploading multipart file
         * @param {string} tenantSlug
         * @param {GeneratePresignedUrlRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {'normal' | 'mrss'} [uploadType] Upload type: normal/mrss
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresignedUrlsForMultipartUpload: function (tenantSlug, data, authorization, acceptLanguage, uploadType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPresignedUrlsForMultipartUpload', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('getPresignedUrlsForMultipartUpload', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/files/client_multipart_upload_urls"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (uploadType !== undefined) {
                                localVarQueryParameter['upload_type'] = uploadType;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all sub categorys
         * @summary List all sub categorys
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubCategories: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getSubCategories', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/sub_categories/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get one sub category
         * @summary Get one sub category
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubCategoryDetail: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getSubCategoryDetail', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getSubCategoryDetail', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/sub_categories/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get one tag
         * @summary Get one tag
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagDetail: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getTagDetail', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getTagDetail', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/tags/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * List all tags
         * @summary List all tags
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getTags', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/tags/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Import Content API
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importContentApi: function (tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('importContentApi', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/import_contents/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [ADMIN-TOOL-ONLY] Index content all
         * @summary [ADMIN-TOOL-ONLY] Index content all
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [tenantSlug] Just index all contents in tenant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexAllContent: function (authorization, acceptLanguage, tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/backend/cm/private/index_all/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (tenantSlug !== undefined) {
                                localVarQueryParameter['tenant_slug'] = tenantSlug;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [ADMIN-TOOL-ONLY] Index content by id
         * @summary [ADMIN-TOOL-ONLY] Index content by id
         * @param {string} contentId id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexContentById: function (contentId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('indexContentById', 'contentId', contentId);
                            localVarPath = "/backend/cm/private/index_document/{content_id}/"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete one sub category
         * @summary Delete one sub category
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSubCategory: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('removeSubCategory', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('removeSubCategory', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/sub_categories/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete one tag
         * @summary Delete one tag
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTag: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('removeTag', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('removeTag', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/tags/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update partial content
         * @param {string} id A unique value identifying this vod entity.
         * @param {string} tenantId
         * @param {ContentPartialUpdate} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsContentsPartialUpdatePartialUpdate: function (id, tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('tenantsContentsPartialUpdatePartialUpdate', 'id', id);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('tenantsContentsPartialUpdatePartialUpdate', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('tenantsContentsPartialUpdatePartialUpdate', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/contents/{id}/partial_update/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update content info
         * @param {string} id A unique value identifying this vod entity.
         * @param {string} tenantId
         * @param {ContentResource} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContent: function (id, tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateContent', 'id', id);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('updateContent', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateContent', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/contents/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update a review
         * @summary Update a review
         * @param {string} id
         * @param {string} tenantSlug
         * @param {UserContentReview} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentReview: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateContentReview', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateContentReview', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateContentReview', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/reviews/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update order tenant pages
         * @summary Update order tenant pages
         * @param {string} platformSlug
         * @param {string} tenantSlug
         * @param {OrderTenantPagesRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderTenantPages: function (platformSlug, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'platformSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateOrderTenantPages', 'platformSlug', platformSlug);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateOrderTenantPages', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateOrderTenantPages', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/tenant_platforms/{platform_slug}/update_order_tenant_pages/"
                                .replace("{".concat("platform_slug", "}"), encodeURIComponent(String(platformSlug)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update one sub category
         * @summary Update one sub category
         * @param {string} id
         * @param {string} tenantSlug
         * @param {CmsSubCategory} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubCategory: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateSubCategory', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateSubCategory', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateSubCategory', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/sub_categories/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update one tag
         * @summary Update one tag
         * @param {string} id
         * @param {string} tenantSlug
         * @param {CmsTag} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateTag', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateTag', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateTag', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/tags/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Upload image file
         * @summary Upload image file
         * @param {string} tenantSlug
         * @param {UploadImageFileRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [keepSize] Keep size for image (Use in MRSS)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: function (tenantSlug, data, authorization, keepSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('uploadImage', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('uploadImage', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/files/images/upload"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (keepSize !== undefined) {
                                localVarQueryParameter['keep_size'] = keepSize;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Upload subtitle
         * @summary Upload subtitle
         * @param {string} tenantSlug
         * @param {any} subtitle Upload subtitle
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSubtitle: function (tenantSlug, subtitle, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('uploadSubtitle', 'tenantSlug', tenantSlug);
                            // verify required parameter 'subtitle' is not null or undefined
                            (0, common_1.assertParamExists)('uploadSubtitle', 'subtitle', subtitle);
                            localVarPath = "/tenants/{tenant_slug}/files/subtitle/upload/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (subtitle !== undefined) {
                                localVarFormParams.append('subtitle', subtitle);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Upload video
         * @summary Upload video
         * @param {string} tenantId Tenant id
         * @param {UploadContentRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadVideo: function (tenantId, data, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('uploadVideo', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('uploadVideo', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/contents/upload"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AdminContentApiAxiosParamCreator = AdminContentApiAxiosParamCreator;
/**
 * AdminContentApi - functional programming interface
 * @export
 */
var AdminContentApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AdminContentApiAxiosParamCreator)(configuration);
    return {
        /**
         * Add content to ribbon api
         * @summary Add content to ribbon api
         * @param {string} ribbonId
         * @param {string} tenantSlug
         * @param {AddContentToRibbonRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContentToRibon: function (ribbonId, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addContentToRibon(ribbonId, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Bulk update payment type and TVOD price many content
         * @param {string} tenantId
         * @param {BulkUpdatePaymentTypeAndPriceRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdatePaymentTypeAndPriceOfContents: function (tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.bulkUpdatePaymentTypeAndPriceOfContents(tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Create content with info
         * @param {string} tenantId
         * @param {ContentResource} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContent: function (tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createContent(tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [ADMIN-TOOL-ONLY] Create index
         * @summary [ADMIN-TOOL-ONLY] Create index
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIndexIndices: function (authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createIndexIndices(authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create one sub category
         * @summary Create one sub category
         * @param {string} tenantSlug
         * @param {CmsSubCategory} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewSubCategory: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createNewSubCategory(tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create one tag
         * @summary Create one tag
         * @param {string} tenantSlug
         * @param {CmsTag} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewTag: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createNewTag(tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete content
         * @param {string} id A unique value identifying this vod entity.
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContent: function (id, tenantId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteContent(id, tenantId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete a review
         * @summary Delete a review
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentReview: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteContentReview(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [ADMIN-TOOL-ONLY] Delete index
         * @summary [ADMIN-TOOL-ONLY] Delete index
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIndexIndices: function (authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteIndexIndices(authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [ADMIN-TOOL-ONLY] Delete index content by id
         * @summary [ADMIN-TOOL-ONLY] Delete index content by id
         * @param {string} contentId id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIndexOfContent: function (contentId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteIndexOfContent(contentId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete list content
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListContent: function (tenantId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteListContent(tenantId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get one content info
         * @param {string} id A unique value identifying this vod entity.
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContent: function (id, tenantId, authorization, acceptLanguage, select, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContent(id, tenantId, authorization, acceptLanguage, select, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get a review
         * @summary Get a review
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentReview: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentReview(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get reviews base on page and page_size
         * @summary Get reviews base on page and page_sized
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentReviews: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentReviews(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get content statistic api
         * @summary Get content statistic api
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {'LAST_30_DAYS' | 'LAST_MONTH' | 'LAST_3_MONTHS'} [periodicTime] Periodic time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentStatistic: function (tenantSlug, authorization, acceptLanguage, periodicTime, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getContentStatistic(tenantSlug, authorization, acceptLanguage, periodicTime, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get DRM config
         * @summary Get DRM config
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrmConfigDetail: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getDrmConfigDetail(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List all DRM Config resource
         * @summary List all DRM Config resource
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrmConfigs: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, select, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getDrmConfigs(tenantSlug, search, page, pageSize, authorization, acceptLanguage, select, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [ADMIN-TOOL-ONLY] Get index
         * @summary [ADMIN-TOOL-ONLY] Get index
         * @param {string} contentId id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndexOfContent: function (contentId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getIndexOfContent(contentId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get list content
         * @param {string} tenantId
         * @param {number} [videoSource]
         * @param {string} [slug]
         * @param {string} [paymentType]
         * @param {string} [createdAt]
         * @param {string} [licenseExpireTime]
         * @param {number} [status]
         * @param {string} [showId]
         * @param {string} [seasonId]
         * @param {string} [excludeIds] list of exclude ids: [\&quot;abc\&quot;]
         * @param {string} [price]
         * @param {string} [inMrss]
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [relatedType] Special type for season and episode. Season &#x3D; 3, episode &#x3D; 4
         * @param {string} [ids] list of id: [\&#39;ad\&#39;, \&#39;d\&#39;]
         * @param {string} [mrssId] Mrss id
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListContents: function (tenantId, videoSource, slug, paymentType, createdAt, licenseExpireTime, status, showId, seasonId, excludeIds, price, inMrss, ordering, search, page, pageSize, authorization, acceptLanguage, relatedType, ids, mrssId, select, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getListContents(tenantId, videoSource, slug, paymentType, createdAt, licenseExpireTime, status, showId, seasonId, excludeIds, price, inMrss, ordering, search, page, pageSize, authorization, acceptLanguage, relatedType, ids, mrssId, select, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Generate presigned url for uploading file to s3 bucket
         * @summary Get presigned url for uploading file to s3 bucket
         * @param {string} tenantSlug
         * @param {GeneratePresignedUrlRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresignedUrlForS3Upload: function (tenantSlug, data, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPresignedUrlForS3Upload(tenantSlug, data, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get presigned urls for uploading multipart file
         * @summary Get presigned urls for uploading multipart file
         * @param {string} tenantSlug
         * @param {GeneratePresignedUrlRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {'normal' | 'mrss'} [uploadType] Upload type: normal/mrss
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresignedUrlsForMultipartUpload: function (tenantSlug, data, authorization, acceptLanguage, uploadType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPresignedUrlsForMultipartUpload(tenantSlug, data, authorization, acceptLanguage, uploadType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List all sub categorys
         * @summary List all sub categorys
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubCategories: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSubCategories(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get one sub category
         * @summary Get one sub category
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubCategoryDetail: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSubCategoryDetail(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get one tag
         * @summary Get one tag
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagDetail: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTagDetail(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List all tags
         * @summary List all tags
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTags(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Import Content API
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importContentApi: function (tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.importContentApi(tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [ADMIN-TOOL-ONLY] Index content all
         * @summary [ADMIN-TOOL-ONLY] Index content all
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [tenantSlug] Just index all contents in tenant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexAllContent: function (authorization, acceptLanguage, tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexAllContent(authorization, acceptLanguage, tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [ADMIN-TOOL-ONLY] Index content by id
         * @summary [ADMIN-TOOL-ONLY] Index content by id
         * @param {string} contentId id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexContentById: function (contentId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexContentById(contentId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete one sub category
         * @summary Delete one sub category
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSubCategory: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.removeSubCategory(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete one tag
         * @summary Delete one tag
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTag: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.removeTag(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update partial content
         * @param {string} id A unique value identifying this vod entity.
         * @param {string} tenantId
         * @param {ContentPartialUpdate} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsContentsPartialUpdatePartialUpdate: function (id, tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tenantsContentsPartialUpdatePartialUpdate(id, tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update content info
         * @param {string} id A unique value identifying this vod entity.
         * @param {string} tenantId
         * @param {ContentResource} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContent: function (id, tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateContent(id, tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update a review
         * @summary Update a review
         * @param {string} id
         * @param {string} tenantSlug
         * @param {UserContentReview} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentReview: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateContentReview(id, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update order tenant pages
         * @summary Update order tenant pages
         * @param {string} platformSlug
         * @param {string} tenantSlug
         * @param {OrderTenantPagesRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderTenantPages: function (platformSlug, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateOrderTenantPages(platformSlug, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update one sub category
         * @summary Update one sub category
         * @param {string} id
         * @param {string} tenantSlug
         * @param {CmsSubCategory} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubCategory: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateSubCategory(id, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update one tag
         * @summary Update one tag
         * @param {string} id
         * @param {string} tenantSlug
         * @param {CmsTag} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateTag(id, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Upload image file
         * @summary Upload image file
         * @param {string} tenantSlug
         * @param {UploadImageFileRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [keepSize] Keep size for image (Use in MRSS)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: function (tenantSlug, data, authorization, keepSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.uploadImage(tenantSlug, data, authorization, keepSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Upload subtitle
         * @summary Upload subtitle
         * @param {string} tenantSlug
         * @param {any} subtitle Upload subtitle
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSubtitle: function (tenantSlug, subtitle, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.uploadSubtitle(tenantSlug, subtitle, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Upload video
         * @summary Upload video
         * @param {string} tenantId Tenant id
         * @param {UploadContentRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadVideo: function (tenantId, data, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.uploadVideo(tenantId, data, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.AdminContentApiFp = AdminContentApiFp;
/**
 * AdminContentApi - factory interface
 * @export
 */
var AdminContentApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AdminContentApiFp)(configuration);
    return {
        /**
         * Add content to ribbon api
         * @summary Add content to ribbon api
         * @param {string} ribbonId
         * @param {string} tenantSlug
         * @param {AddContentToRibbonRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContentToRibon: function (ribbonId, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.addContentToRibon(ribbonId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Bulk update payment type and TVOD price many content
         * @param {string} tenantId
         * @param {BulkUpdatePaymentTypeAndPriceRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdatePaymentTypeAndPriceOfContents: function (tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.bulkUpdatePaymentTypeAndPriceOfContents(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Create content with info
         * @param {string} tenantId
         * @param {ContentResource} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContent: function (tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.createContent(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [ADMIN-TOOL-ONLY] Create index
         * @summary [ADMIN-TOOL-ONLY] Create index
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIndexIndices: function (authorization, acceptLanguage, options) {
            return localVarFp.createIndexIndices(authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create one sub category
         * @summary Create one sub category
         * @param {string} tenantSlug
         * @param {CmsSubCategory} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewSubCategory: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.createNewSubCategory(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create one tag
         * @summary Create one tag
         * @param {string} tenantSlug
         * @param {CmsTag} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewTag: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.createNewTag(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete content
         * @param {string} id A unique value identifying this vod entity.
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContent: function (id, tenantId, authorization, acceptLanguage, options) {
            return localVarFp.deleteContent(id, tenantId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete a review
         * @summary Delete a review
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentReview: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.deleteContentReview(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [ADMIN-TOOL-ONLY] Delete index
         * @summary [ADMIN-TOOL-ONLY] Delete index
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIndexIndices: function (authorization, acceptLanguage, options) {
            return localVarFp.deleteIndexIndices(authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [ADMIN-TOOL-ONLY] Delete index content by id
         * @summary [ADMIN-TOOL-ONLY] Delete index content by id
         * @param {string} contentId id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIndexOfContent: function (contentId, authorization, acceptLanguage, options) {
            return localVarFp.deleteIndexOfContent(contentId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete list content
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListContent: function (tenantId, authorization, acceptLanguage, options) {
            return localVarFp.deleteListContent(tenantId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get one content info
         * @param {string} id A unique value identifying this vod entity.
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContent: function (id, tenantId, authorization, acceptLanguage, select, options) {
            return localVarFp.getContent(id, tenantId, authorization, acceptLanguage, select, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a review
         * @summary Get a review
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentReview: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getContentReview(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get reviews base on page and page_size
         * @summary Get reviews base on page and page_sized
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentReviews: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            return localVarFp.getContentReviews(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get content statistic api
         * @summary Get content statistic api
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {'LAST_30_DAYS' | 'LAST_MONTH' | 'LAST_3_MONTHS'} [periodicTime] Periodic time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentStatistic: function (tenantSlug, authorization, acceptLanguage, periodicTime, options) {
            return localVarFp.getContentStatistic(tenantSlug, authorization, acceptLanguage, periodicTime, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get DRM config
         * @summary Get DRM config
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrmConfigDetail: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getDrmConfigDetail(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all DRM Config resource
         * @summary List all DRM Config resource
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrmConfigs: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, select, options) {
            return localVarFp.getDrmConfigs(tenantSlug, search, page, pageSize, authorization, acceptLanguage, select, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [ADMIN-TOOL-ONLY] Get index
         * @summary [ADMIN-TOOL-ONLY] Get index
         * @param {string} contentId id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndexOfContent: function (contentId, authorization, acceptLanguage, options) {
            return localVarFp.getIndexOfContent(contentId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get list content
         * @param {string} tenantId
         * @param {number} [videoSource]
         * @param {string} [slug]
         * @param {string} [paymentType]
         * @param {string} [createdAt]
         * @param {string} [licenseExpireTime]
         * @param {number} [status]
         * @param {string} [showId]
         * @param {string} [seasonId]
         * @param {string} [excludeIds] list of exclude ids: [\&quot;abc\&quot;]
         * @param {string} [price]
         * @param {string} [inMrss]
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [relatedType] Special type for season and episode. Season &#x3D; 3, episode &#x3D; 4
         * @param {string} [ids] list of id: [\&#39;ad\&#39;, \&#39;d\&#39;]
         * @param {string} [mrssId] Mrss id
         * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListContents: function (tenantId, videoSource, slug, paymentType, createdAt, licenseExpireTime, status, showId, seasonId, excludeIds, price, inMrss, ordering, search, page, pageSize, authorization, acceptLanguage, relatedType, ids, mrssId, select, options) {
            return localVarFp.getListContents(tenantId, videoSource, slug, paymentType, createdAt, licenseExpireTime, status, showId, seasonId, excludeIds, price, inMrss, ordering, search, page, pageSize, authorization, acceptLanguage, relatedType, ids, mrssId, select, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Generate presigned url for uploading file to s3 bucket
         * @summary Get presigned url for uploading file to s3 bucket
         * @param {string} tenantSlug
         * @param {GeneratePresignedUrlRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresignedUrlForS3Upload: function (tenantSlug, data, authorization, options) {
            return localVarFp.getPresignedUrlForS3Upload(tenantSlug, data, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get presigned urls for uploading multipart file
         * @summary Get presigned urls for uploading multipart file
         * @param {string} tenantSlug
         * @param {GeneratePresignedUrlRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {'normal' | 'mrss'} [uploadType] Upload type: normal/mrss
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresignedUrlsForMultipartUpload: function (tenantSlug, data, authorization, acceptLanguage, uploadType, options) {
            return localVarFp.getPresignedUrlsForMultipartUpload(tenantSlug, data, authorization, acceptLanguage, uploadType, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all sub categorys
         * @summary List all sub categorys
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubCategories: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            return localVarFp.getSubCategories(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get one sub category
         * @summary Get one sub category
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubCategoryDetail: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getSubCategoryDetail(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get one tag
         * @summary Get one tag
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagDetail: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getTagDetail(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List all tags
         * @summary List all tags
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            return localVarFp.getTags(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Import Content API
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importContentApi: function (tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.importContentApi(tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [ADMIN-TOOL-ONLY] Index content all
         * @summary [ADMIN-TOOL-ONLY] Index content all
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [tenantSlug] Just index all contents in tenant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexAllContent: function (authorization, acceptLanguage, tenantSlug, options) {
            return localVarFp.indexAllContent(authorization, acceptLanguage, tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [ADMIN-TOOL-ONLY] Index content by id
         * @summary [ADMIN-TOOL-ONLY] Index content by id
         * @param {string} contentId id of content
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexContentById: function (contentId, authorization, acceptLanguage, options) {
            return localVarFp.indexContentById(contentId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete one sub category
         * @summary Delete one sub category
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSubCategory: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.removeSubCategory(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete one tag
         * @summary Delete one tag
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTag: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.removeTag(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update partial content
         * @param {string} id A unique value identifying this vod entity.
         * @param {string} tenantId
         * @param {ContentPartialUpdate} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsContentsPartialUpdatePartialUpdate: function (id, tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.tenantsContentsPartialUpdatePartialUpdate(id, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update content info
         * @param {string} id A unique value identifying this vod entity.
         * @param {string} tenantId
         * @param {ContentResource} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContent: function (id, tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.updateContent(id, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update a review
         * @summary Update a review
         * @param {string} id
         * @param {string} tenantSlug
         * @param {UserContentReview} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentReview: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateContentReview(id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update order tenant pages
         * @summary Update order tenant pages
         * @param {string} platformSlug
         * @param {string} tenantSlug
         * @param {OrderTenantPagesRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderTenantPages: function (platformSlug, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateOrderTenantPages(platformSlug, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update one sub category
         * @summary Update one sub category
         * @param {string} id
         * @param {string} tenantSlug
         * @param {CmsSubCategory} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubCategory: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateSubCategory(id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update one tag
         * @summary Update one tag
         * @param {string} id
         * @param {string} tenantSlug
         * @param {CmsTag} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateTag(id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Upload image file
         * @summary Upload image file
         * @param {string} tenantSlug
         * @param {UploadImageFileRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [keepSize] Keep size for image (Use in MRSS)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: function (tenantSlug, data, authorization, keepSize, options) {
            return localVarFp.uploadImage(tenantSlug, data, authorization, keepSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Upload subtitle
         * @summary Upload subtitle
         * @param {string} tenantSlug
         * @param {any} subtitle Upload subtitle
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSubtitle: function (tenantSlug, subtitle, authorization, options) {
            return localVarFp.uploadSubtitle(tenantSlug, subtitle, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Upload video
         * @summary Upload video
         * @param {string} tenantId Tenant id
         * @param {UploadContentRequest} data
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadVideo: function (tenantId, data, authorization, options) {
            return localVarFp.uploadVideo(tenantId, data, authorization, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AdminContentApiFactory = AdminContentApiFactory;
/**
 * AdminContentApi - object-oriented interface
 * @export
 * @class AdminContentApi
 * @extends {BaseAPI}
 */
var AdminContentApi = /** @class */ (function (_super) {
    __extends(AdminContentApi, _super);
    function AdminContentApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Add content to ribbon api
     * @summary Add content to ribbon api
     * @param {string} ribbonId
     * @param {string} tenantSlug
     * @param {AddContentToRibbonRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.addContentToRibon = function (ribbonId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).addContentToRibon(ribbonId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Bulk update payment type and TVOD price many content
     * @param {string} tenantId
     * @param {BulkUpdatePaymentTypeAndPriceRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.bulkUpdatePaymentTypeAndPriceOfContents = function (tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).bulkUpdatePaymentTypeAndPriceOfContents(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Create content with info
     * @param {string} tenantId
     * @param {ContentResource} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.createContent = function (tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).createContent(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [ADMIN-TOOL-ONLY] Create index
     * @summary [ADMIN-TOOL-ONLY] Create index
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.createIndexIndices = function (authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).createIndexIndices(authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create one sub category
     * @summary Create one sub category
     * @param {string} tenantSlug
     * @param {CmsSubCategory} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.createNewSubCategory = function (tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).createNewSubCategory(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create one tag
     * @summary Create one tag
     * @param {string} tenantSlug
     * @param {CmsTag} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.createNewTag = function (tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).createNewTag(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete content
     * @param {string} id A unique value identifying this vod entity.
     * @param {string} tenantId
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.deleteContent = function (id, tenantId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).deleteContent(id, tenantId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete a review
     * @summary Delete a review
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.deleteContentReview = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).deleteContentReview(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [ADMIN-TOOL-ONLY] Delete index
     * @summary [ADMIN-TOOL-ONLY] Delete index
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.deleteIndexIndices = function (authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).deleteIndexIndices(authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [ADMIN-TOOL-ONLY] Delete index content by id
     * @summary [ADMIN-TOOL-ONLY] Delete index content by id
     * @param {string} contentId id of content
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.deleteIndexOfContent = function (contentId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).deleteIndexOfContent(contentId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete list content
     * @param {string} tenantId
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.deleteListContent = function (tenantId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).deleteListContent(tenantId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get one content info
     * @param {string} id A unique value identifying this vod entity.
     * @param {string} tenantId
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.getContent = function (id, tenantId, authorization, acceptLanguage, select, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).getContent(id, tenantId, authorization, acceptLanguage, select, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a review
     * @summary Get a review
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.getContentReview = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).getContentReview(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get reviews base on page and page_size
     * @summary Get reviews base on page and page_sized
     * @param {string} tenantSlug
     * @param {string} [search] A search term.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.getContentReviews = function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).getContentReviews(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get content statistic api
     * @summary Get content statistic api
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {'LAST_30_DAYS' | 'LAST_MONTH' | 'LAST_3_MONTHS'} [periodicTime] Periodic time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.getContentStatistic = function (tenantSlug, authorization, acceptLanguage, periodicTime, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).getContentStatistic(tenantSlug, authorization, acceptLanguage, periodicTime, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get DRM config
     * @summary Get DRM config
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.getDrmConfigDetail = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).getDrmConfigDetail(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all DRM Config resource
     * @summary List all DRM Config resource
     * @param {string} tenantSlug
     * @param {string} [search] A search term.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.getDrmConfigs = function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, select, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).getDrmConfigs(tenantSlug, search, page, pageSize, authorization, acceptLanguage, select, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [ADMIN-TOOL-ONLY] Get index
     * @summary [ADMIN-TOOL-ONLY] Get index
     * @param {string} contentId id of content
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.getIndexOfContent = function (contentId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).getIndexOfContent(contentId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get list content
     * @param {string} tenantId
     * @param {number} [videoSource]
     * @param {string} [slug]
     * @param {string} [paymentType]
     * @param {string} [createdAt]
     * @param {string} [licenseExpireTime]
     * @param {number} [status]
     * @param {string} [showId]
     * @param {string} [seasonId]
     * @param {string} [excludeIds] list of exclude ids: [\&quot;abc\&quot;]
     * @param {string} [price]
     * @param {string} [inMrss]
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [search] A search term.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {number} [relatedType] Special type for season and episode. Season &#x3D; 3, episode &#x3D; 4
     * @param {string} [ids] list of id: [\&#39;ad\&#39;, \&#39;d\&#39;]
     * @param {string} [mrssId] Mrss id
     * @param {string} [select] Url-encoded stringify dict of serializers appear in response document. Each pair take serializer name as key and array of serializer keys as value. Example: ?select&#x3D;{\&quot;ContentSerializer\&quot;: [\&quot;id\&quot;, \&quot;slug\&quot;]} Actual value when request: ?select&#x3D;%7B%22ContentSerializer%22%3A%20%5B%22id%22%5D%7D
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.getListContents = function (tenantId, videoSource, slug, paymentType, createdAt, licenseExpireTime, status, showId, seasonId, excludeIds, price, inMrss, ordering, search, page, pageSize, authorization, acceptLanguage, relatedType, ids, mrssId, select, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).getListContents(tenantId, videoSource, slug, paymentType, createdAt, licenseExpireTime, status, showId, seasonId, excludeIds, price, inMrss, ordering, search, page, pageSize, authorization, acceptLanguage, relatedType, ids, mrssId, select, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Generate presigned url for uploading file to s3 bucket
     * @summary Get presigned url for uploading file to s3 bucket
     * @param {string} tenantSlug
     * @param {GeneratePresignedUrlRequest} data
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.getPresignedUrlForS3Upload = function (tenantSlug, data, authorization, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).getPresignedUrlForS3Upload(tenantSlug, data, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get presigned urls for uploading multipart file
     * @summary Get presigned urls for uploading multipart file
     * @param {string} tenantSlug
     * @param {GeneratePresignedUrlRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {'normal' | 'mrss'} [uploadType] Upload type: normal/mrss
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.getPresignedUrlsForMultipartUpload = function (tenantSlug, data, authorization, acceptLanguage, uploadType, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).getPresignedUrlsForMultipartUpload(tenantSlug, data, authorization, acceptLanguage, uploadType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all sub categorys
     * @summary List all sub categorys
     * @param {string} tenantSlug
     * @param {string} [search] A search term.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.getSubCategories = function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).getSubCategories(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get one sub category
     * @summary Get one sub category
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.getSubCategoryDetail = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).getSubCategoryDetail(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get one tag
     * @summary Get one tag
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.getTagDetail = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).getTagDetail(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List all tags
     * @summary List all tags
     * @param {string} tenantSlug
     * @param {string} [search] A search term.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.getTags = function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).getTags(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Import Content API
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.importContentApi = function (tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).importContentApi(tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [ADMIN-TOOL-ONLY] Index content all
     * @summary [ADMIN-TOOL-ONLY] Index content all
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [tenantSlug] Just index all contents in tenant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.indexAllContent = function (authorization, acceptLanguage, tenantSlug, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).indexAllContent(authorization, acceptLanguage, tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [ADMIN-TOOL-ONLY] Index content by id
     * @summary [ADMIN-TOOL-ONLY] Index content by id
     * @param {string} contentId id of content
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.indexContentById = function (contentId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).indexContentById(contentId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete one sub category
     * @summary Delete one sub category
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.removeSubCategory = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).removeSubCategory(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete one tag
     * @summary Delete one tag
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.removeTag = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).removeTag(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update partial content
     * @param {string} id A unique value identifying this vod entity.
     * @param {string} tenantId
     * @param {ContentPartialUpdate} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.tenantsContentsPartialUpdatePartialUpdate = function (id, tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).tenantsContentsPartialUpdatePartialUpdate(id, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update content info
     * @param {string} id A unique value identifying this vod entity.
     * @param {string} tenantId
     * @param {ContentResource} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.updateContent = function (id, tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).updateContent(id, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update a review
     * @summary Update a review
     * @param {string} id
     * @param {string} tenantSlug
     * @param {UserContentReview} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.updateContentReview = function (id, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).updateContentReview(id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update order tenant pages
     * @summary Update order tenant pages
     * @param {string} platformSlug
     * @param {string} tenantSlug
     * @param {OrderTenantPagesRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.updateOrderTenantPages = function (platformSlug, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).updateOrderTenantPages(platformSlug, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update one sub category
     * @summary Update one sub category
     * @param {string} id
     * @param {string} tenantSlug
     * @param {CmsSubCategory} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.updateSubCategory = function (id, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).updateSubCategory(id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update one tag
     * @summary Update one tag
     * @param {string} id
     * @param {string} tenantSlug
     * @param {CmsTag} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.updateTag = function (id, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).updateTag(id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Upload image file
     * @summary Upload image file
     * @param {string} tenantSlug
     * @param {UploadImageFileRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [keepSize] Keep size for image (Use in MRSS)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.uploadImage = function (tenantSlug, data, authorization, keepSize, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).uploadImage(tenantSlug, data, authorization, keepSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Upload subtitle
     * @summary Upload subtitle
     * @param {string} tenantSlug
     * @param {any} subtitle Upload subtitle
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.uploadSubtitle = function (tenantSlug, subtitle, authorization, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).uploadSubtitle(tenantSlug, subtitle, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Upload video
     * @summary Upload video
     * @param {string} tenantId Tenant id
     * @param {UploadContentRequest} data
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContentApi
     */
    AdminContentApi.prototype.uploadVideo = function (tenantId, data, authorization, options) {
        var _this = this;
        return (0, exports.AdminContentApiFp)(this.configuration).uploadVideo(tenantId, data, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AdminContentApi;
}(base_1.BaseAPI));
exports.AdminContentApi = AdminContentApi;
