import { PayloadAction } from '@reduxjs/toolkit';
import { Search } from '~vimai-api-adapter/api-adapters/api-adapter-vimai/models';
import * as types from './actionTypes';

export interface ISearch {
  showSearchPopup: boolean;
  searchTerm: string;
  searchResults: Search['items'];
}
const initialState: ISearch = {
  showSearchPopup: false,
  searchTerm: '',
  searchResults: [],
};

const search = (state = initialState, action: PayloadAction<Partial<ISearch>>) => {
  switch (action.type) {
    case types.OPEN_SEARCH_POPUP:
      return {
        ...state,
        showSearchPopup: true,
      };

    case types.CLOSE_SEARCH_POPUP:
      return initialState;
    case types.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      };

    case types.SHOW_SEARCH_RESULT:
      return {
        ...state,
        searchResults: action.payload,
      };
    case types.CLEAR_SEARCH_TERM:
      return {
        ...state,
        searchTerm: '',
      };
    case types.CLEAR_SEARCH_RESULT:
      return {
        ...state,
        searchResults: [],
      };
    case types.RESET_SEARCH_DATA:
      return {
        ...state,
        searchTerm: '',
        searchResults: [],
      };

    default:
      return state;
  }
};

export default search;
