Object.defineProperty(exports, "__esModule", { value: true });
exports.applyProxy = void 0;
var adapter_type_1 = require("../adapter-type");
var ProxyInstance;
if (window.Proxy) {
    ProxyInstance = Proxy;
}
else {
    ProxyInstance = require('proxy-polyfill/src/proxy')();
}
function getEnvironment(name) {
    // Check process is available
    if (typeof process.env !== 'undefined') {
        try {
            return process.env[name];
        }
        catch (e) {
            return undefined;
        }
    }
    return undefined;
}
function normalizeFunction(func) {
    return func
        .toString()
        .replace(/\/\*[\s\S]*?\*\//g, '')
        .replace(/\/\/(.)*/g, '')
        .replace(/{[\s\S]*}/, '')
        .replace(/[=>]/g, '')
        .trim();
}
// function getFunctionName(func: any) {
//   NOT USABLE FOR ES5
//   const str = normalizeFunction(func)
//   const end = str.indexOf('(')
//   return str.substring(0, end)
// }
function getParamsName(func) {
    var str = normalizeFunction(func);
    var start = str.indexOf('(') + 1;
    var end = str.length - 1;
    var result = str.substring(start, end).split(', ');
    var params = [];
    result.forEach(function (element) {
        element = element.replace(/[=][\s\S]*/g, '').trim();
        if (element.length > 0) {
            params.push(element);
        }
    });
    return params;
}
function applyProxy(service, parameterProvider, debug, adapterType) {
    if (debug === void 0) { debug = false; }
    if (adapterType === void 0) { adapterType = adapter_type_1.AdapterType.VIMAI; }
    // these must only set for adapter development purpose and should not appear anywhere in production code
    var debugEnabled = debug || getEnvironment('REACT_APP_ADAPTER_FORCE_DEBUG') === 'true';
    var isVerbose = getEnvironment('REACT_APP_ADAPTER_VERBOSE') === 'true';
    var log = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        debugEnabled && console.log.apply(console, args);
    };
    var inject = function (func, args) {
        getParamsName(func).map(function (p, i) {
            if (parameterProvider.injectList.indexOf(p) > -1 && args[i] === undefined) {
                args[i] = parameterProvider.get(p);
            }
        });
        return args;
    };
    var checkResponse = function (funcName, argumentList, id, startTime) {
        return function (response) {
            var _a;
            var time = new Date().getTime() - startTime;
            if (debugEnabled) {
                // @ts-ignore
                var hasConsoleRe = typeof ((_a = console.re) === null || _a === void 0 ? void 0 : _a.log) !== 'undefined';
                if (hasConsoleRe) {
                    log("==> API-RESPONSE ".concat(id, " ").concat(funcName, "(").concat(argumentList, ") response code ").concat(response.status, " in ").concat(time, "ms"));
                }
                else {
                    console.groupCollapsed("%c==> API-RESPONSE ".concat(id, " ").concat(funcName, "(").concat(argumentList, ") response code ").concat(response.status, " in ").concat(time, "ms"), 'background: #333; color: #808080; padding: 1px 5px;');
                    isVerbose && log(response);
                    console.groupEnd();
                }
            }
            return response;
        };
    };
    var checkException = function (funcName, id) {
        return function (error) {
            var _a, _b;
            if (typeof error === 'string') {
                if (error === 'Method not implemented') {
                    log("%cERROR%c".concat(id, "%cFunction ").concat(funcName, " is not implemented by ").concat(adapterType, " adapter.!"), 'background: #333; color: #8c343a; padding: 1px 5px;', 'background: #333; color: #808080; padding: 1px 5px;', 'background: #333; color: #8c343a; padding: 1px 5px;');
                }
            }
            else {
                var status_1 = (_a = error.response) === null || _a === void 0 ? void 0 : _a.status;
                if (status_1) {
                    log("%c==|| API-ERROR ".concat(id, " ").concat(funcName, " response code ").concat((_b = error.response) === null || _b === void 0 ? void 0 : _b.status), 'background: #333; color: #8c343a; padding: 1px 5px;');
                }
                else {
                    log(error);
                }
            }
            return Promise.reject(error);
        };
    };
    var makeProxyFunction = function (target, prop) {
        return new ProxyInstance(target[prop], {
            apply: function (tg, thisArg, argumentsList) {
                var _a;
                argumentsList = inject(tg, argumentsList);
                var id = Math.round(Math.random() * (9999 - 1000)) + 1000;
                var startTime = new Date().getTime();
                // @ts-ignore
                var hasConsoleRe = typeof ((_a = console.re) === null || _a === void 0 ? void 0 : _a.log) !== 'undefined';
                if (debugEnabled) {
                    if (hasConsoleRe) {
                        log("API-REQUEST ".concat(id, " Fetching ").concat(adapterType, " - ").concat(prop, "(").concat(argumentsList, ")"));
                    }
                    else {
                        log("%cAPI-REQUEST%c".concat(id, "%cFetching ").concat(adapterType, " - ").concat(prop, "(").concat(argumentsList, ")"), 'background: #ffffff77; color: blue; padding: 3px 10px; font-size: 10px; font-weight: bold; border-radius: 3px', 'background: #333; color: #808080; padding: 1px 5px;', 'background: #333; color: #BADA55; padding: 1px 5px;');
                    }
                }
                return Reflect.apply(tg, thisArg, argumentsList)
                    .then(checkResponse(prop, argumentsList, id, startTime))
                    .catch(checkException(prop, id));
            },
        });
    };
    if (!window.Proxy) {
        // Proxy-Polyfill will not working with prototype objects.!
        // So we have to destructure prototype object into json object
        var servicePrototypes_1 = Object.getPrototypeOf(service);
        var servicePrototypeNames = Object.getOwnPropertyNames(servicePrototypes_1);
        servicePrototypeNames.forEach(function (method) {
            if (method !== 'constructor') {
                Object.defineProperty(service, method, { value: servicePrototypes_1[method] });
            }
        });
    }
    return new ProxyInstance(service, {
        get: function (target, prop) {
            if (typeof target[prop] === 'function') {
                return makeProxyFunction(target, prop);
            }
            else {
                return Reflect.get(target, prop);
            }
        },
    });
}
exports.applyProxy = applyProxy;
