var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OdxIntegration = exports.OdxCWLService = exports.OdxCWService = exports.OdxService = void 0;
var base_1 = require("../api-adapter-vimai/base");
var utils_1 = require("./utils");
var api_client_odx_cw_1 = require("../../integration-services/api-client-odx-cw");
var api_client_odx_cwl_1 = require("../../integration-services/api-client-odx-cwl");
var cover_api_1 = require("../../integration-services/api-client-odx/apis/cover-api");
var curation_api_1 = require("../../integration-services/api-client-odx/apis/curation-api");
var navigation_api_1 = require("../../integration-services/api-client-odx/apis/navigation-api");
var user_api_1 = require("../../integration-services/api-client-odx/apis/user-api");
var profile_avatars_api_1 = require("../../integration-services/api-client-odx/apis/profile-avatars-api");
var profile_api_1 = require("../../integration-services/api-client-odx/apis/profile-api");
var profile_favorites_api_1 = require("../../integration-services/api-client-odx/apis/profile-favorites-api");
var user_qr_login_api_1 = require("../../integration-services/api-client-odx/apis/user-qr-login-api");
var user_qr_pass_api_1 = require("../../integration-services/api-client-odx/apis/user-qr-pass-api");
var user_reset_password_api_1 = require("../../integration-services/api-client-odx/apis/user-reset-password-api");
var category_api_1 = require("../../integration-services/api-client-odx/apis/category-api");
var programs_api_1 = require("../../integration-services/api-client-odx/apis/programs-api");
var search_api_1 = require("../../integration-services/api-client-odx/apis/search-api");
var user_favorites_api_1 = require("../../integration-services/api-client-odx/apis/user-favorites-api");
var program_api_1 = require("../../integration-services/api-client-odx/apis/program-api");
var playback_api_1 = require("../../integration-services/api-client-odx/apis/playback-api");
var channels_api_1 = require("../../integration-services/api-client-odx/apis/channels-api");
var episode_recommendation_api_1 = require("../../integration-services/api-client-odx/apis/episode-recommendation-api");
var episode_api_1 = require("../../integration-services/api-client-odx/apis/episode-api");
var base_api_1 = require("../../integration-services/api-client-odx/apis/base-api");
var payment_api_1 = require("../../integration-services/api-client-odx/apis/payment-api");
var tv_guide_api_1 = require("../../integration-services/api-client-odx/apis/tv-guide-api");
var OdxService = /** @class */ (function () {
    function OdxService(configuration, basePath, axios, environment) {
        if (environment === void 0) { environment = 'production'; }
        this.configuration = configuration;
        this.basePath = basePath;
        this.axios = axios;
        this.environment = environment;
        this.odxCoverApi = undefined;
        this.odxCurationApi = undefined;
        this.odxNavigationApi = undefined;
        this.odxUserApi = undefined;
        this.odxProfileAvatarsApi = undefined;
        this.odxProfileApi = undefined;
        this.odxProfileFavoritesApi = undefined;
        this.odxUserQrLoginApi = undefined;
        this.odxUserQrPassApi = undefined;
        this.odxUserResetPasswordApi = undefined;
        this.odxCategoryApi = undefined;
        this.odxProgramsApi = undefined;
        this.odxSearchApi = undefined;
        this.odxUserFavoritesApi = undefined;
        this.odxProgramApi = undefined;
        this.odxPlaybackApi = undefined;
        this.odxChannelsApi = undefined;
        this.odxEpisodeRecommendationApi = undefined;
        this.odxEpisodeApi = undefined;
        this.odxBaseApi = undefined;
        this.odxPaymentApi = undefined;
        this.odxTvGuideApi = undefined;
        this.createServices();
    }
    OdxService.prototype.createServices = function () {
        this.setApiKey('auto');
        this.odxCoverApi = this.create(cover_api_1.CoverApi);
        this.odxCurationApi = this.create(curation_api_1.CurationApi);
        this.odxNavigationApi = this.create(navigation_api_1.NavigationApi);
        this.odxUserApi = this.create(user_api_1.UserApi);
        this.odxUserQrLoginApi = this.create(user_qr_login_api_1.UserQrLoginApi);
        this.odxUserQrPassApi = this.create(user_qr_pass_api_1.UserQrPassApi);
        this.odxUserResetPasswordApi = this.create(user_reset_password_api_1.UserResetPasswordApi);
        this.odxCategoryApi = this.create(category_api_1.CategoryApi);
        this.odxProgramsApi = this.create(programs_api_1.ProgramsApi);
        this.odxSearchApi = this.create(search_api_1.SearchApi);
        this.odxUserFavoritesApi = this.create(user_favorites_api_1.UserFavoritesApi);
        this.odxProgramApi = this.create(program_api_1.ProgramApi);
        this.odxPlaybackApi = this.create(playback_api_1.PlaybackApi);
        this.odxChannelsApi = this.create(channels_api_1.ChannelsApi);
        this.odxEpisodeApi = this.create(episode_api_1.EpisodeApi);
        this.odxBaseApi = this.create(base_api_1.BaseApi);
        this.odxPaymentApi = this.create(payment_api_1.PaymentApi);
        this.odxTvGuideApi = this.create(tv_guide_api_1.TvGuideApi);
        this.odxProfileApi = this.create(profile_api_1.ProfileApi);
        this.odxProfileAvatarsApi = this.create(profile_avatars_api_1.ProfileAvatarsApi);
        this.odxProfileFavoritesApi = this.create(profile_favorites_api_1.ProfileFavoritesApi);
        this.odxEpisodeRecommendationApi = this.create(episode_recommendation_api_1.EpisodeRecommendationApi);
    };
    OdxService.prototype.create = function (cls) {
        return new cls(this.configuration, this.basePath, this.axios);
    };
    OdxService.prototype.setBasePath = function (path) {
        if (this.configuration) {
            this.configuration.basePath = path;
        }
        this.basePath = path;
        this.createServices();
    };
    OdxService.prototype.setApiKey = function (key) {
        this.configuration = this.configuration || utils_1.defaultConfig;
        if (key === 'auto' || key === undefined) {
            this.configuration.apiKey = function (authKey) {
                var _a, _b;
                if (authKey === 'Authorization') {
                    return (_a = OdxIntegration.currentProvider) === null || _a === void 0 ? void 0 : _a.get('authorization');
                }
                if (authKey === 'Profile-Token') {
                    return (_b = OdxIntegration.currentProvider) === null || _b === void 0 ? void 0 : _b.get('profileToken');
                }
                return undefined;
            };
        }
        else if (typeof key === 'object') {
            this.configuration.apiKey = function (authKey) {
                return key[authKey];
            };
        }
        else {
            this.configuration.apiKey = key;
        }
    };
    return OdxService;
}());
exports.OdxService = OdxService;
var OdxCWService = /** @class */ (function () {
    function OdxCWService(configuration, basePath, axios) {
        this.configuration = configuration;
        this.basePath = basePath;
        this.axios = axios;
        this.odxCW = undefined;
        this.createServices();
    }
    OdxCWService.prototype.createServices = function () {
        this.odxCW = this.create(api_client_odx_cw_1.DefaultApi);
    };
    OdxCWService.prototype.create = function (cls) {
        return new cls(this.configuration, this.basePath, this.axios);
    };
    OdxCWService.prototype.setBasePath = function (path) {
        if (this.configuration) {
            this.configuration.basePath = path;
        }
        this.basePath = path;
        this.createServices();
    };
    OdxCWService.prototype.setApiKey = function (key) {
        this.configuration = this.configuration || utils_1.defaultConfig;
        this.configuration.apiKey = key;
        this.createServices();
    };
    return OdxCWService;
}());
exports.OdxCWService = OdxCWService;
var OdxCWLService = /** @class */ (function () {
    function OdxCWLService(configuration, basePath, axios) {
        this.configuration = configuration;
        this.basePath = basePath;
        this.axios = axios;
        this.odxCWL = undefined;
        this.createServices();
    }
    OdxCWLService.prototype.createServices = function () {
        this.odxCWL = this.create(api_client_odx_cwl_1.DefaultApi);
    };
    OdxCWLService.prototype.create = function (cls) {
        return new cls(this.configuration, this.basePath, this.axios);
    };
    OdxCWLService.prototype.setBasePath = function (path) {
        if (this.configuration) {
            this.configuration.basePath = path;
        }
        this.basePath = path;
        this.createServices();
    };
    OdxCWLService.prototype.setApiKey = function (key) {
        this.configuration = this.configuration || utils_1.defaultConfig;
        this.configuration.apiKey = key;
        this.createServices();
    };
    return OdxCWLService;
}());
exports.OdxCWLService = OdxCWLService;
var OdxIntegration = /** @class */ (function (_super) {
    __extends(OdxIntegration, _super);
    function OdxIntegration(configuration, basePath, axios, provider) {
        var _this = _super.call(this, configuration, basePath, axios) || this;
        _this.odxService = undefined;
        _this.odxCWService = undefined;
        _this.odxCWLService = undefined;
        _this.provider = undefined;
        _this.environment = 'production';
        if (_this.basePath.includes('staging.odk')) {
            _this.environment = 'staging';
        }
        _this.provider = provider;
        _this.odxService = new OdxService(_this.configuration, _this.basePath, _this.axios, _this.environment);
        _this.odxCWService = new OdxCWService(_this.configuration, _this.getCWServiceBasePath(_this.basePath), _this.axios);
        _this.odxCWLService = new OdxCWLService(_this.configuration, _this.getCWLServiceBasePath(), _this.axios);
        OdxIntegration.currentProvider = provider;
        OdxIntegration.currentCwService = _this.odxCWService;
        OdxIntegration.currentCwlService = _this.odxCWLService;
        OdxIntegration.currentService = _this.odxService;
        return _this;
    }
    OdxIntegration.prototype.getCWServiceBasePath = function (odxApiPath) {
        var _a;
        // If provided, use the provided path
        var providedPath = (_a = this.provider) === null || _a === void 0 ? void 0 : _a.get('odxCWServiceHost');
        if (providedPath) {
            return providedPath;
        }
        // Otherwise, use the path of the ODX API
        if (odxApiPath.includes('dev.odk')) {
            return 'https://cw-dev.odkmedia.io/v2';
        }
        if (odxApiPath.includes('staging.odk')) {
            return 'https://cw-staging.odkmedia.io/v2';
        }
        return 'https://cw.odkmedia.io/v2';
    };
    OdxIntegration.prototype.getCWLServiceBasePath = function () {
        var _a;
        // If provided, use the provided path
        var providedPath = (_a = this.provider) === null || _a === void 0 ? void 0 : _a.get('odxCWLServiceHost');
        if (providedPath) {
            return providedPath;
        }
        // Otherwise, use the path of the ODX API
        var odxApiPath = this.basePath;
        if (odxApiPath.includes('dev.odk')) {
            return 'https://odxevent.staging1.odkmedia.io';
        }
        if (odxApiPath.includes('staging.odk')) {
            return 'https://odxevent.staging1.odkmedia.io';
        }
        return 'https://odxevent.odkmedia.io';
    };
    OdxIntegration.currentService = null;
    OdxIntegration.currentProvider = null;
    OdxIntegration.currentCwService = null;
    OdxIntegration.currentCwlService = null;
    return OdxIntegration;
}(base_1.BaseAPI));
exports.OdxIntegration = OdxIntegration;
