/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentApi = exports.PaymentApiFactory = exports.PaymentApiFp = exports.PaymentApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * PaymentApi - axios parameter creator
 * @export
 */
var PaymentApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create subscription for account api
         * @summary Create subscription for account api
         * @param {string} tenantSlug
         * @param {CreateSubscriptionForAccountRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAccountSubscription: function (tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('adminCreateAccountSubscription', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('adminCreateAccountSubscription', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/create_subscription_for_account"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Apply coupon for account customer
         * @summary Apply coupon for account customer
         * @param {string} accountId
         * @param {string} tenantId
         * @param {ApplyCouponForCustomerRequestSeriazlier} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyCouponForUser: function (accountId, tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('applyCouponForUser', 'accountId', accountId);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('applyCouponForUser', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('applyCouponForUser', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/accounts/{account_id}/coupon/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Apply reddem code to account api
         * @summary Apply reddem code to account api
         * @param {string} accountId
         * @param {string} tenantId
         * @param {ApplyRedeemCodeToAccount} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {boolean} [confirm] Check Flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyRedeemCodeToAccount: function (accountId, tenantId, data, authorization, acceptLanguage, confirm, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('applyRedeemCodeToAccount', 'accountId', accountId);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('applyRedeemCodeToAccount', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('applyRedeemCodeToAccount', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/accounts/{account_id}/redeem_code"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (confirm !== undefined) {
                                localVarQueryParameter['confirm'] = confirm;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Calculate payment subscription for stripe/sms/paypal/momo
         * @summary Calculate payment subscription for stripe/sms/paypal/momo
         * @param {string} accountId
         * @param {string} paymentProvider
         * @param {string} tenantId
         * @param {CreateSubscription} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcPaymentSubscription: function (accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('calcPaymentSubscription', 'accountId', accountId);
                            // verify required parameter 'paymentProvider' is not null or undefined
                            (0, common_1.assertParamExists)('calcPaymentSubscription', 'paymentProvider', paymentProvider);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('calcPaymentSubscription', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('calcPaymentSubscription', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/accounts/{account_id}/payment_subscriptions/{payment_provider}/calc"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("payment_provider", "}"), encodeURIComponent(String(paymentProvider)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Cancel request refund by user
         * @summary Cancel request refund by user
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRequestRefund: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('cancelRequestRefund', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('cancelRequestRefund', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/paid_content_refund/{id}cancel_refund/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Cancel user subscription
         * @summary Cancel user subscription
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUserSubscription: function (tenantId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('cancelUserSubscription', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/account/cancel_current_subscription_plan/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Check available region for subscription
         * @summary Check available region for subscription
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCountryAllowedSubscription: function (tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('checkCountryAllowedSubscription', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/configs/check_available_region_for_subscription/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Check payment subscription
         * @summary Check payment subscription
         * @param {string} accountId
         * @param {string} paymentProvider
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPaymentSubscriptionApi: function (accountId, paymentProvider, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('checkPaymentSubscriptionApi', 'accountId', accountId);
                            // verify required parameter 'paymentProvider' is not null or undefined
                            (0, common_1.assertParamExists)('checkPaymentSubscriptionApi', 'paymentProvider', paymentProvider);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('checkPaymentSubscriptionApi', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/payment_subscriptions/{payment_provider}/check"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("payment_provider", "}"), encodeURIComponent(String(paymentProvider)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Confirm payment subscription for stripe/sms/paypal
         * @summary Confirm payment subscription for stripe/sms/paypal
         * @param {string} accountId
         * @param {string} paymentProvider
         * @param {string} tenantId
         * @param {ConfirmPaymentSubscriptionRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPaymentSubscription: function (accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('confirmPaymentSubscription', 'accountId', accountId);
                            // verify required parameter 'paymentProvider' is not null or undefined
                            (0, common_1.assertParamExists)('confirmPaymentSubscription', 'paymentProvider', paymentProvider);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('confirmPaymentSubscription', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('confirmPaymentSubscription', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/accounts/{account_id}/payment_subscriptions/{payment_provider}/confirm"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("payment_provider", "}"), encodeURIComponent(String(paymentProvider)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Confirm paypal purchase
         * @summary Confirm paypal purchase
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ConfirmPaypalPurchaseRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPaypalPurchase: function (accountId, tenantSlug, data, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('confirmPaypalPurchase', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('confirmPaypalPurchase', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('confirmPaypalPurchase', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/paypal_subscriptions/confirm"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Confirm subscription per content
         * @summary Confirm subscription per content
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ConfirmSubscriptionPerContentRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [idempotencyKey] Idempotency-key
         * @param {string} [timezone] User timezone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmSingleContentSubscription: function (accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('confirmSingleContentSubscription', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('confirmSingleContentSubscription', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('confirmSingleContentSubscription', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/confirm_payment_per_content/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (timezone !== undefined) {
                                localVarQueryParameter['timezone'] = timezone;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            if (idempotencyKey !== undefined && idempotencyKey !== null) {
                                localVarHeaderParameter['Idempotency-key'] = String(idempotencyKey);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Make payment content for user
         * @summary Make payment content for user
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {UserContentPaymentRequest} data
         * @param {string} [idempotencyKey] Idempotency-key
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIapSubscription: function (accountId, tenantSlug, data, idempotencyKey, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('createIapSubscription', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createIapSubscription', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createIapSubscription', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/contents/payment/iap"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (idempotencyKey !== undefined && idempotencyKey !== null) {
                                localVarHeaderParameter['Idempotency-key'] = String(idempotencyKey);
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * In App Purchase API
         * @summary In App Purchase API
         * @param {string} tenantSlug
         * @param {InAppPurchaseRequest} data
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIapSubscription_1: function (tenantSlug, data, acceptLanguage, authorization, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createIapSubscription_1', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createIapSubscription_1', 'data', data);
                            localVarPath = "/v2/tenants/{tenant_slug}/accounts/subscription/iap"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create payment subscription for stripe/sms/paypal/momo
         * @summary Create payment subscription for stripe/sms/paypal/momo
         * @param {string} accountId
         * @param {string} paymentProvider
         * @param {string} tenantId
         * @param {CreateSubscription} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentSubscription: function (accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('createPaymentSubscription', 'accountId', accountId);
                            // verify required parameter 'paymentProvider' is not null or undefined
                            (0, common_1.assertParamExists)('createPaymentSubscription', 'paymentProvider', paymentProvider);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('createPaymentSubscription', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createPaymentSubscription', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/accounts/{account_id}/payment_subscriptions/{payment_provider}/create"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("payment_provider", "}"), encodeURIComponent(String(paymentProvider)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create paypal purchase
         * @summary Create paypal purchase
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {CreatePaypalPurchaseRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaypalPurchase: function (accountId, tenantSlug, data, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('createPaypalPurchase', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createPaypalPurchase', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createPaypalPurchase', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/paypal_subscriptions/create"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create subscription per content
         * @summary Create subscription per content
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {PaySubscriptionPerContentRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [idempotencyKey] Idempotency-key
         * @param {string} [timezone] User timezone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSingleContentSubscription: function (accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('createSingleContentSubscription', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createSingleContentSubscription', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createSingleContentSubscription', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/payment_per_content/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (timezone !== undefined) {
                                localVarQueryParameter['timezone'] = timezone;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            if (idempotencyKey !== undefined && idempotencyKey !== null) {
                                localVarHeaderParameter['Idempotency-key'] = String(idempotencyKey);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create (stripe) subscription
         * @summary Create (stripe) subscription
         * @param {string} tenantId
         * @param {CreateStripeSubscription} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {boolean} [draft] use for subscription haven\&#39;t been used.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createStripeSubscription: function (tenantId, data, authorization, acceptLanguage, draft, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('createStripeSubscription', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createStripeSubscription', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/stripe/subscriptions/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (draft !== undefined) {
                                localVarQueryParameter['draft'] = draft;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Export payments
         * @summary Export payments
         * @param {string} tenantSlug
         * @param {string} paymentMethod Get payment resource of payment method
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [search] Searching field
         * @param {string} [ordering] Ordering field
         * @param {number} [page] Paging
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPayments: function (tenantSlug, paymentMethod, authorization, acceptLanguage, search, ordering, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('exportPayments', 'tenantSlug', tenantSlug);
                            // verify required parameter 'paymentMethod' is not null or undefined
                            (0, common_1.assertParamExists)('exportPayments', 'paymentMethod', paymentMethod);
                            localVarPath = "/tenants/{tenant_slug}/payments/export/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (ordering !== undefined) {
                                localVarQueryParameter['ordering'] = ordering;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (paymentMethod !== undefined) {
                                localVarQueryParameter['payment_method'] = paymentMethod;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get list of subscription plan
         * @summary Get list of subscription plan
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubscriptionPlans: function (tenantId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getAllSubscriptionPlans', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/subscription_plans/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get all approved refunds of user (filter by status)
         * @summary Get all approved refunds of user (filter by status)
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {number} [status] Refund status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApprovedRefunds: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, status, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('getApprovedRefunds', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getApprovedRefunds', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/paid_content_refund"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (status !== undefined) {
                                localVarQueryParameter['status'] = status;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get current subscription plan
         * @summary Get current actived subscription plan
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSubscriptionPlan: function (tenantId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getCurrentSubscriptionPlan', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/account/get_actived_subscription_plan/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Sync subscription to Stripe server when payment via iAP
         * @summary Sync subscription to Stripe server when payment via iAP
         * @param {string} tenantId
         * @param {GetStatusIAPRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIapStatus: function (tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getIapStatus', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('getIapStatus', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/accounts/subscription/iap/get_status_iap"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get list of subscription plan
         * @summary Get list of subscription plan
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [environment] Environment filter
         * @param {string} [tenantPlatform] Tenant platform
         * @param {string} [planId] ID of plan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSubscriptionPlan: function (tenantId, authorization, acceptLanguage, environment, tenantPlatform, planId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getListSubscriptionPlan', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/plans/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (environment !== undefined) {
                                localVarQueryParameter['environment'] = environment;
                            }
                            if (tenantPlatform !== undefined) {
                                localVarQueryParameter['tenant_platform'] = tenantPlatform;
                            }
                            if (planId !== undefined) {
                                localVarQueryParameter['plan_id'] = planId;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get list of subscription product
         * @summary Get list of subscription product
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [environment] Environment filter
         * @param {string} [tenantPlatform] Tenant platform
         * @param {string} [planId] ID of plan
         * @param {boolean} [showMultiMonths] Show multi months
         * @param {boolean} [showMobifone9029] Show mobifone 9029. To compatible with mobile sctv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSubscriptionProduct: function (tenantId, authorization, acceptLanguage, environment, tenantPlatform, planId, showMultiMonths, showMobifone9029, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getListSubscriptionProduct', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/products/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (environment !== undefined) {
                                localVarQueryParameter['environment'] = environment;
                            }
                            if (tenantPlatform !== undefined) {
                                localVarQueryParameter['tenant_platform'] = tenantPlatform;
                            }
                            if (planId !== undefined) {
                                localVarQueryParameter['plan_id'] = planId;
                            }
                            if (showMultiMonths !== undefined) {
                                localVarQueryParameter['show_multi_months'] = showMultiMonths;
                            }
                            if (showMobifone9029 !== undefined) {
                                localVarQueryParameter['show_mobifone_9029'] = showMobifone9029;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get list payments
         * @summary Get list payments
         * @param {string} tenantSlug
         * @param {string} paymentMethod Get payment resource of payment method
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [search] Searching field
         * @param {string} [ordering] Ordering field
         * @param {number} [page] Paging
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayments: function (tenantSlug, paymentMethod, authorization, acceptLanguage, search, ordering, page, pageSize, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPayments', 'tenantSlug', tenantSlug);
                            // verify required parameter 'paymentMethod' is not null or undefined
                            (0, common_1.assertParamExists)('getPayments', 'paymentMethod', paymentMethod);
                            localVarPath = "/tenants/{tenant_slug}/payments/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (ordering !== undefined) {
                                localVarQueryParameter['ordering'] = ordering;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (paymentMethod !== undefined) {
                                localVarQueryParameter['payment_method'] = paymentMethod;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get list of paypal payer info
         * @summary Get list of paypal payer info
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaypalPayers: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('getPaypalPayers', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPaypalPayers', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/paypal_payers"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve a subscription plan
         * @summary Retrieve a subscription plan
         * @param {string} planId
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [environment] Environment filter
         * @param {string} [tenantPlatform] Tenant platform
         * @param {boolean} [showMultiMonths] Show multi months
         * @param {boolean} [showMobifone9029] Show mobifone 9029. To compatible with mobile sctv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPlanDetail: function (planId, tenantId, authorization, acceptLanguage, environment, tenantPlatform, showMultiMonths, showMobifone9029, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'planId' is not null or undefined
                            (0, common_1.assertParamExists)('getSubscriptionPlanDetail', 'planId', planId);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getSubscriptionPlanDetail', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/plans/{plan_id}/"
                                .replace("{".concat("plan_id", "}"), encodeURIComponent(String(planId)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (environment !== undefined) {
                                localVarQueryParameter['environment'] = environment;
                            }
                            if (tenantPlatform !== undefined) {
                                localVarQueryParameter['tenant_platform'] = tenantPlatform;
                            }
                            if (showMultiMonths !== undefined) {
                                localVarQueryParameter['show_multi_months'] = showMultiMonths;
                            }
                            if (showMobifone9029 !== undefined) {
                                localVarQueryParameter['show_mobifone_9029'] = showMobifone9029;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionTypes: function (tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getSubscriptionTypes', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/subscription_status/.*"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Payment method multitenancy config
         * @summary Payment method multitenancy config
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantPaymentMethods: function (tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getTenantPaymentMethods', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/available_payment_methods/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get user invoice history
         * @summary Get user invoice history
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInvoiceHistory: function (tenantId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getUserInvoiceHistory', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/account/subscription_history/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get payment method for customer
         * @summary Get payment method for customer
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPaymentMethod: function (tenantId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getUserPaymentMethod', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/account/payment_method/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get user subscription history
         * @summary Get user subscription history
         * @param {string} accountId
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSubscriptionHistory: function (accountId, tenantId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('getUserSubscriptionHistory', 'accountId', accountId);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getUserSubscriptionHistory', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/accounts/{account_id}/invoiced_payments/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Paypal webhook api.
         * @summary Paypal webhook api
         * @param {string} paymentSlug
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paypalWebhook: function (paymentSlug, tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'paymentSlug' is not null or undefined
                            (0, common_1.assertParamExists)('paypalWebhook', 'paymentSlug', paymentSlug);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('paypalWebhook', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/payment_gateway/{payment_slug}/webhook"
                                .replace("{".concat("payment_slug", "}"), encodeURIComponent(String(paymentSlug)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Paypal webhook api.
         * @summary Paypal webhook api
         * @param {string} paymentSlug
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paypalWebhook_2: function (paymentSlug, tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'paymentSlug' is not null or undefined
                            (0, common_1.assertParamExists)('paypalWebhook_2', 'paymentSlug', paymentSlug);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('paypalWebhook_2', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/payment_gateway/{payment_slug}/webhook"
                                .replace("{".concat("payment_slug", "}"), encodeURIComponent(String(paymentSlug)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Sync subscription to Stripe server when payment via iAP
         * @summary Sync subscription to Stripe server when payment via iAP
         * @param {string} tenantId
         * @param {string} originalTransactionId use for subscription haven\&#39;t been used.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [appOs] use for subscription haven\&#39;t been used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preCheckSubscription: function (tenantId, originalTransactionId, authorization, acceptLanguage, appOs, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('preCheckSubscription', 'tenantId', tenantId);
                            // verify required parameter 'originalTransactionId' is not null or undefined
                            (0, common_1.assertParamExists)('preCheckSubscription', 'originalTransactionId', originalTransactionId);
                            localVarPath = "/tenants/{tenant_id}/accounts/subscription/iap/check_before_subscription"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (originalTransactionId !== undefined) {
                                localVarQueryParameter['original_transaction_id'] = originalTransactionId;
                            }
                            if (appOs !== undefined) {
                                localVarQueryParameter['app_os'] = appOs;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Pre Confirm subscription per content
         * @summary Pre Confirm subscription per content
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {PreConfirmSubscriptionPerContentRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [idempotencyKey] Idempotency-key
         * @param {string} [timezone] User timezone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preconfirmSingleContentSubscription: function (accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('preconfirmSingleContentSubscription', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('preconfirmSingleContentSubscription', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('preconfirmSingleContentSubscription', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/pre_confirm_payment_per_content/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (timezone !== undefined) {
                                localVarQueryParameter['timezone'] = timezone;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            if (idempotencyKey !== undefined && idempotencyKey !== null) {
                                localVarHeaderParameter['Idempotency-key'] = String(idempotencyKey);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * The remaining purchases of the account
         * @summary The remaining purchases of the account
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remainingPurchasesAccount: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('remainingPurchasesAccount', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('remainingPurchasesAccount', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/remaining_purchases"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Request a refund the content
         * @summary Request a refund the content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestContentRefund: function (contentId, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'contentId' is not null or undefined
                            (0, common_1.assertParamExists)('requestContentRefund', 'contentId', contentId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('requestContentRefund', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/contents/{content_id}/paid_content_refund"
                                .replace("{".concat("content_id", "}"), encodeURIComponent(String(contentId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * [ODX] Subscribe content via code
         * @summary [ODX] Subscribe content via code
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {SubscribeContentInfo} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeContentByCode: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('subscribeContentByCode', 'accountId', accountId);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('subscribeContentByCode', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('subscribeContentByCode', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/accounts/{account_id}/pay_via_code/"
                                .replace("{".concat("account_id", "}"), encodeURIComponent(String(accountId)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Sync subscription to Stripe server when payment via iAP
         * @summary Sync subscription to Stripe server when payment via iAP
         * @param {string} tenantId
         * @param {SubscriptionViaIAPRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncIapSubscriptionToStripe: function (tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('syncIapSubscriptionToStripe', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('syncIapSubscriptionToStripe', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/accounts/subscription/iap"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Transfer Subscription
         * @summary Transfer Subscription
         * @param {string} tenantId
         * @param {TransferSubscriptionRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferSubscription: function (tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('transferSubscription', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('transferSubscription', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/accounts/subscription/transfer"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerWebhookEvent: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/payments/webhook/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerWebhookEvent_3: function (tenantSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('triggerWebhookEvent_3', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/payments/webhook/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update mobiphone package (Webhook)
         * @summary Webhook to be called by mobiphone to update package
         * @param {string} mobiGw
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobiphonePackage: function (mobiGw, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'mobiGw' is not null or undefined
                            (0, common_1.assertParamExists)('updateMobiphonePackage', 'mobiGw', mobiGw);
                            localVarPath = "/payment_gateway/{mobi_gw}/webhook/update_package"
                                .replace("{".concat("mobi_gw", "}"), encodeURIComponent(String(mobiGw)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update mobiphone package (Webhook)
         * @summary Webhook to be called by mobiphone to update package
         * @param {string} mobiGw
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobiphonePackage_4: function (mobiGw, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'mobiGw' is not null or undefined
                            (0, common_1.assertParamExists)('updateMobiphonePackage_4', 'mobiGw', mobiGw);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateMobiphonePackage_4', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/payment_gateway/{mobi_gw}/webhook/update_package"
                                .replace("{".concat("mobi_gw", "}"), encodeURIComponent(String(mobiGw)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update default payment method for customer
         * @summary Update default payment method for customer
         * @param {string} tenantId
         * @param {UpdatePaymentMethodRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPaymentMethod: function (tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('updateUserPaymentMethod', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateUserPaymentMethod', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/account/payment_method/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PaymentApiAxiosParamCreator = PaymentApiAxiosParamCreator;
/**
 * PaymentApi - functional programming interface
 * @export
 */
var PaymentApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.PaymentApiAxiosParamCreator)(configuration);
    return {
        /**
         * Create subscription for account api
         * @summary Create subscription for account api
         * @param {string} tenantSlug
         * @param {CreateSubscriptionForAccountRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAccountSubscription: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.adminCreateAccountSubscription(tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Apply coupon for account customer
         * @summary Apply coupon for account customer
         * @param {string} accountId
         * @param {string} tenantId
         * @param {ApplyCouponForCustomerRequestSeriazlier} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyCouponForUser: function (accountId, tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.applyCouponForUser(accountId, tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Apply reddem code to account api
         * @summary Apply reddem code to account api
         * @param {string} accountId
         * @param {string} tenantId
         * @param {ApplyRedeemCodeToAccount} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {boolean} [confirm] Check Flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyRedeemCodeToAccount: function (accountId, tenantId, data, authorization, acceptLanguage, confirm, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.applyRedeemCodeToAccount(accountId, tenantId, data, authorization, acceptLanguage, confirm, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Calculate payment subscription for stripe/sms/paypal/momo
         * @summary Calculate payment subscription for stripe/sms/paypal/momo
         * @param {string} accountId
         * @param {string} paymentProvider
         * @param {string} tenantId
         * @param {CreateSubscription} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcPaymentSubscription: function (accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.calcPaymentSubscription(accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Cancel request refund by user
         * @summary Cancel request refund by user
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRequestRefund: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cancelRequestRefund(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Cancel user subscription
         * @summary Cancel user subscription
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUserSubscription: function (tenantId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cancelUserSubscription(tenantId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Check available region for subscription
         * @summary Check available region for subscription
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCountryAllowedSubscription: function (tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.checkCountryAllowedSubscription(tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Check payment subscription
         * @summary Check payment subscription
         * @param {string} accountId
         * @param {string} paymentProvider
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPaymentSubscriptionApi: function (accountId, paymentProvider, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.checkPaymentSubscriptionApi(accountId, paymentProvider, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Confirm payment subscription for stripe/sms/paypal
         * @summary Confirm payment subscription for stripe/sms/paypal
         * @param {string} accountId
         * @param {string} paymentProvider
         * @param {string} tenantId
         * @param {ConfirmPaymentSubscriptionRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPaymentSubscription: function (accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.confirmPaymentSubscription(accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Confirm paypal purchase
         * @summary Confirm paypal purchase
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ConfirmPaypalPurchaseRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPaypalPurchase: function (accountId, tenantSlug, data, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.confirmPaypalPurchase(accountId, tenantSlug, data, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Confirm subscription per content
         * @summary Confirm subscription per content
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ConfirmSubscriptionPerContentRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [idempotencyKey] Idempotency-key
         * @param {string} [timezone] User timezone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmSingleContentSubscription: function (accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.confirmSingleContentSubscription(accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Make payment content for user
         * @summary Make payment content for user
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {UserContentPaymentRequest} data
         * @param {string} [idempotencyKey] Idempotency-key
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIapSubscription: function (accountId, tenantSlug, data, idempotencyKey, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createIapSubscription(accountId, tenantSlug, data, idempotencyKey, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * In App Purchase API
         * @summary In App Purchase API
         * @param {string} tenantSlug
         * @param {InAppPurchaseRequest} data
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIapSubscription_1: function (tenantSlug, data, acceptLanguage, authorization, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createIapSubscription_1(tenantSlug, data, acceptLanguage, authorization, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create payment subscription for stripe/sms/paypal/momo
         * @summary Create payment subscription for stripe/sms/paypal/momo
         * @param {string} accountId
         * @param {string} paymentProvider
         * @param {string} tenantId
         * @param {CreateSubscription} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentSubscription: function (accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createPaymentSubscription(accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create paypal purchase
         * @summary Create paypal purchase
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {CreatePaypalPurchaseRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaypalPurchase: function (accountId, tenantSlug, data, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createPaypalPurchase(accountId, tenantSlug, data, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create subscription per content
         * @summary Create subscription per content
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {PaySubscriptionPerContentRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [idempotencyKey] Idempotency-key
         * @param {string} [timezone] User timezone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSingleContentSubscription: function (accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createSingleContentSubscription(accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create (stripe) subscription
         * @summary Create (stripe) subscription
         * @param {string} tenantId
         * @param {CreateStripeSubscription} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {boolean} [draft] use for subscription haven\&#39;t been used.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createStripeSubscription: function (tenantId, data, authorization, acceptLanguage, draft, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createStripeSubscription(tenantId, data, authorization, acceptLanguage, draft, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Export payments
         * @summary Export payments
         * @param {string} tenantSlug
         * @param {string} paymentMethod Get payment resource of payment method
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [search] Searching field
         * @param {string} [ordering] Ordering field
         * @param {number} [page] Paging
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPayments: function (tenantSlug, paymentMethod, authorization, acceptLanguage, search, ordering, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.exportPayments(tenantSlug, paymentMethod, authorization, acceptLanguage, search, ordering, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get list of subscription plan
         * @summary Get list of subscription plan
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubscriptionPlans: function (tenantId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAllSubscriptionPlans(tenantId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get all approved refunds of user (filter by status)
         * @summary Get all approved refunds of user (filter by status)
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {number} [status] Refund status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApprovedRefunds: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, status, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getApprovedRefunds(accountId, tenantSlug, authorization, acceptLanguage, page, limit, status, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get current subscription plan
         * @summary Get current actived subscription plan
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSubscriptionPlan: function (tenantId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCurrentSubscriptionPlan(tenantId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Sync subscription to Stripe server when payment via iAP
         * @summary Sync subscription to Stripe server when payment via iAP
         * @param {string} tenantId
         * @param {GetStatusIAPRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIapStatus: function (tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getIapStatus(tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get list of subscription plan
         * @summary Get list of subscription plan
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [environment] Environment filter
         * @param {string} [tenantPlatform] Tenant platform
         * @param {string} [planId] ID of plan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSubscriptionPlan: function (tenantId, authorization, acceptLanguage, environment, tenantPlatform, planId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getListSubscriptionPlan(tenantId, authorization, acceptLanguage, environment, tenantPlatform, planId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get list of subscription product
         * @summary Get list of subscription product
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [environment] Environment filter
         * @param {string} [tenantPlatform] Tenant platform
         * @param {string} [planId] ID of plan
         * @param {boolean} [showMultiMonths] Show multi months
         * @param {boolean} [showMobifone9029] Show mobifone 9029. To compatible with mobile sctv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSubscriptionProduct: function (tenantId, authorization, acceptLanguage, environment, tenantPlatform, planId, showMultiMonths, showMobifone9029, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getListSubscriptionProduct(tenantId, authorization, acceptLanguage, environment, tenantPlatform, planId, showMultiMonths, showMobifone9029, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get list payments
         * @summary Get list payments
         * @param {string} tenantSlug
         * @param {string} paymentMethod Get payment resource of payment method
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [search] Searching field
         * @param {string} [ordering] Ordering field
         * @param {number} [page] Paging
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayments: function (tenantSlug, paymentMethod, authorization, acceptLanguage, search, ordering, page, pageSize, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPayments(tenantSlug, paymentMethod, authorization, acceptLanguage, search, ordering, page, pageSize, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get list of paypal payer info
         * @summary Get list of paypal payer info
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaypalPayers: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPaypalPayers(accountId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Retrieve a subscription plan
         * @summary Retrieve a subscription plan
         * @param {string} planId
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [environment] Environment filter
         * @param {string} [tenantPlatform] Tenant platform
         * @param {boolean} [showMultiMonths] Show multi months
         * @param {boolean} [showMobifone9029] Show mobifone 9029. To compatible with mobile sctv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPlanDetail: function (planId, tenantId, authorization, acceptLanguage, environment, tenantPlatform, showMultiMonths, showMobifone9029, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSubscriptionPlanDetail(planId, tenantId, authorization, acceptLanguage, environment, tenantPlatform, showMultiMonths, showMobifone9029, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionTypes: function (tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSubscriptionTypes(tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Payment method multitenancy config
         * @summary Payment method multitenancy config
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantPaymentMethods: function (tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTenantPaymentMethods(tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get user invoice history
         * @summary Get user invoice history
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInvoiceHistory: function (tenantId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserInvoiceHistory(tenantId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get payment method for customer
         * @summary Get payment method for customer
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPaymentMethod: function (tenantId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserPaymentMethod(tenantId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get user subscription history
         * @summary Get user subscription history
         * @param {string} accountId
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSubscriptionHistory: function (accountId, tenantId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserSubscriptionHistory(accountId, tenantId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Paypal webhook api.
         * @summary Paypal webhook api
         * @param {string} paymentSlug
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paypalWebhook: function (paymentSlug, tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.paypalWebhook(paymentSlug, tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Paypal webhook api.
         * @summary Paypal webhook api
         * @param {string} paymentSlug
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paypalWebhook_2: function (paymentSlug, tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.paypalWebhook_2(paymentSlug, tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Sync subscription to Stripe server when payment via iAP
         * @summary Sync subscription to Stripe server when payment via iAP
         * @param {string} tenantId
         * @param {string} originalTransactionId use for subscription haven\&#39;t been used.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [appOs] use for subscription haven\&#39;t been used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preCheckSubscription: function (tenantId, originalTransactionId, authorization, acceptLanguage, appOs, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.preCheckSubscription(tenantId, originalTransactionId, authorization, acceptLanguage, appOs, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Pre Confirm subscription per content
         * @summary Pre Confirm subscription per content
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {PreConfirmSubscriptionPerContentRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [idempotencyKey] Idempotency-key
         * @param {string} [timezone] User timezone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preconfirmSingleContentSubscription: function (accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.preconfirmSingleContentSubscription(accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * The remaining purchases of the account
         * @summary The remaining purchases of the account
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remainingPurchasesAccount: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.remainingPurchasesAccount(accountId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Request a refund the content
         * @summary Request a refund the content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestContentRefund: function (contentId, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.requestContentRefund(contentId, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * [ODX] Subscribe content via code
         * @summary [ODX] Subscribe content via code
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {SubscribeContentInfo} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeContentByCode: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.subscribeContentByCode(accountId, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Sync subscription to Stripe server when payment via iAP
         * @summary Sync subscription to Stripe server when payment via iAP
         * @param {string} tenantId
         * @param {SubscriptionViaIAPRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncIapSubscriptionToStripe: function (tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.syncIapSubscriptionToStripe(tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Transfer Subscription
         * @summary Transfer Subscription
         * @param {string} tenantId
         * @param {TransferSubscriptionRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferSubscription: function (tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.transferSubscription(tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerWebhookEvent: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.triggerWebhookEvent(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerWebhookEvent_3: function (tenantSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.triggerWebhookEvent_3(tenantSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update mobiphone package (Webhook)
         * @summary Webhook to be called by mobiphone to update package
         * @param {string} mobiGw
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobiphonePackage: function (mobiGw, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateMobiphonePackage(mobiGw, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update mobiphone package (Webhook)
         * @summary Webhook to be called by mobiphone to update package
         * @param {string} mobiGw
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobiphonePackage_4: function (mobiGw, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateMobiphonePackage_4(mobiGw, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update default payment method for customer
         * @summary Update default payment method for customer
         * @param {string} tenantId
         * @param {UpdatePaymentMethodRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPaymentMethod: function (tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateUserPaymentMethod(tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.PaymentApiFp = PaymentApiFp;
/**
 * PaymentApi - factory interface
 * @export
 */
var PaymentApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.PaymentApiFp)(configuration);
    return {
        /**
         * Create subscription for account api
         * @summary Create subscription for account api
         * @param {string} tenantSlug
         * @param {CreateSubscriptionForAccountRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAccountSubscription: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.adminCreateAccountSubscription(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Apply coupon for account customer
         * @summary Apply coupon for account customer
         * @param {string} accountId
         * @param {string} tenantId
         * @param {ApplyCouponForCustomerRequestSeriazlier} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyCouponForUser: function (accountId, tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.applyCouponForUser(accountId, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Apply reddem code to account api
         * @summary Apply reddem code to account api
         * @param {string} accountId
         * @param {string} tenantId
         * @param {ApplyRedeemCodeToAccount} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {boolean} [confirm] Check Flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyRedeemCodeToAccount: function (accountId, tenantId, data, authorization, acceptLanguage, confirm, options) {
            return localVarFp.applyRedeemCodeToAccount(accountId, tenantId, data, authorization, acceptLanguage, confirm, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Calculate payment subscription for stripe/sms/paypal/momo
         * @summary Calculate payment subscription for stripe/sms/paypal/momo
         * @param {string} accountId
         * @param {string} paymentProvider
         * @param {string} tenantId
         * @param {CreateSubscription} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcPaymentSubscription: function (accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.calcPaymentSubscription(accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Cancel request refund by user
         * @summary Cancel request refund by user
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRequestRefund: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.cancelRequestRefund(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Cancel user subscription
         * @summary Cancel user subscription
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUserSubscription: function (tenantId, authorization, acceptLanguage, options) {
            return localVarFp.cancelUserSubscription(tenantId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Check available region for subscription
         * @summary Check available region for subscription
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCountryAllowedSubscription: function (tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.checkCountryAllowedSubscription(tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Check payment subscription
         * @summary Check payment subscription
         * @param {string} accountId
         * @param {string} paymentProvider
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPaymentSubscriptionApi: function (accountId, paymentProvider, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.checkPaymentSubscriptionApi(accountId, paymentProvider, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Confirm payment subscription for stripe/sms/paypal
         * @summary Confirm payment subscription for stripe/sms/paypal
         * @param {string} accountId
         * @param {string} paymentProvider
         * @param {string} tenantId
         * @param {ConfirmPaymentSubscriptionRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPaymentSubscription: function (accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.confirmPaymentSubscription(accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Confirm paypal purchase
         * @summary Confirm paypal purchase
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ConfirmPaypalPurchaseRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPaypalPurchase: function (accountId, tenantSlug, data, options) {
            return localVarFp.confirmPaypalPurchase(accountId, tenantSlug, data, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Confirm subscription per content
         * @summary Confirm subscription per content
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {ConfirmSubscriptionPerContentRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [idempotencyKey] Idempotency-key
         * @param {string} [timezone] User timezone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmSingleContentSubscription: function (accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options) {
            return localVarFp.confirmSingleContentSubscription(accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Make payment content for user
         * @summary Make payment content for user
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {UserContentPaymentRequest} data
         * @param {string} [idempotencyKey] Idempotency-key
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIapSubscription: function (accountId, tenantSlug, data, idempotencyKey, authorization, options) {
            return localVarFp.createIapSubscription(accountId, tenantSlug, data, idempotencyKey, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * In App Purchase API
         * @summary In App Purchase API
         * @param {string} tenantSlug
         * @param {InAppPurchaseRequest} data
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [authorization] Access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIapSubscription_1: function (tenantSlug, data, acceptLanguage, authorization, options) {
            return localVarFp.createIapSubscription_1(tenantSlug, data, acceptLanguage, authorization, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create payment subscription for stripe/sms/paypal/momo
         * @summary Create payment subscription for stripe/sms/paypal/momo
         * @param {string} accountId
         * @param {string} paymentProvider
         * @param {string} tenantId
         * @param {CreateSubscription} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentSubscription: function (accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.createPaymentSubscription(accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create paypal purchase
         * @summary Create paypal purchase
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {CreatePaypalPurchaseRequest} data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaypalPurchase: function (accountId, tenantSlug, data, options) {
            return localVarFp.createPaypalPurchase(accountId, tenantSlug, data, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create subscription per content
         * @summary Create subscription per content
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {PaySubscriptionPerContentRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [idempotencyKey] Idempotency-key
         * @param {string} [timezone] User timezone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSingleContentSubscription: function (accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options) {
            return localVarFp.createSingleContentSubscription(accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create (stripe) subscription
         * @summary Create (stripe) subscription
         * @param {string} tenantId
         * @param {CreateStripeSubscription} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {boolean} [draft] use for subscription haven\&#39;t been used.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createStripeSubscription: function (tenantId, data, authorization, acceptLanguage, draft, options) {
            return localVarFp.createStripeSubscription(tenantId, data, authorization, acceptLanguage, draft, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Export payments
         * @summary Export payments
         * @param {string} tenantSlug
         * @param {string} paymentMethod Get payment resource of payment method
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [search] Searching field
         * @param {string} [ordering] Ordering field
         * @param {number} [page] Paging
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPayments: function (tenantSlug, paymentMethod, authorization, acceptLanguage, search, ordering, page, pageSize, options) {
            return localVarFp.exportPayments(tenantSlug, paymentMethod, authorization, acceptLanguage, search, ordering, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get list of subscription plan
         * @summary Get list of subscription plan
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubscriptionPlans: function (tenantId, authorization, acceptLanguage, options) {
            return localVarFp.getAllSubscriptionPlans(tenantId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get all approved refunds of user (filter by status)
         * @summary Get all approved refunds of user (filter by status)
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {number} [status] Refund status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApprovedRefunds: function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, status, options) {
            return localVarFp.getApprovedRefunds(accountId, tenantSlug, authorization, acceptLanguage, page, limit, status, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get current subscription plan
         * @summary Get current actived subscription plan
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSubscriptionPlan: function (tenantId, authorization, acceptLanguage, options) {
            return localVarFp.getCurrentSubscriptionPlan(tenantId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Sync subscription to Stripe server when payment via iAP
         * @summary Sync subscription to Stripe server when payment via iAP
         * @param {string} tenantId
         * @param {GetStatusIAPRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIapStatus: function (tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.getIapStatus(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get list of subscription plan
         * @summary Get list of subscription plan
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [environment] Environment filter
         * @param {string} [tenantPlatform] Tenant platform
         * @param {string} [planId] ID of plan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSubscriptionPlan: function (tenantId, authorization, acceptLanguage, environment, tenantPlatform, planId, options) {
            return localVarFp.getListSubscriptionPlan(tenantId, authorization, acceptLanguage, environment, tenantPlatform, planId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get list of subscription product
         * @summary Get list of subscription product
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [environment] Environment filter
         * @param {string} [tenantPlatform] Tenant platform
         * @param {string} [planId] ID of plan
         * @param {boolean} [showMultiMonths] Show multi months
         * @param {boolean} [showMobifone9029] Show mobifone 9029. To compatible with mobile sctv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListSubscriptionProduct: function (tenantId, authorization, acceptLanguage, environment, tenantPlatform, planId, showMultiMonths, showMobifone9029, options) {
            return localVarFp.getListSubscriptionProduct(tenantId, authorization, acceptLanguage, environment, tenantPlatform, planId, showMultiMonths, showMobifone9029, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get list payments
         * @summary Get list payments
         * @param {string} tenantSlug
         * @param {string} paymentMethod Get payment resource of payment method
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [search] Searching field
         * @param {string} [ordering] Ordering field
         * @param {number} [page] Paging
         * @param {number} [pageSize] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayments: function (tenantSlug, paymentMethod, authorization, acceptLanguage, search, ordering, page, pageSize, options) {
            return localVarFp.getPayments(tenantSlug, paymentMethod, authorization, acceptLanguage, search, ordering, page, pageSize, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get list of paypal payer info
         * @summary Get list of paypal payer info
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaypalPayers: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getPaypalPayers(accountId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve a subscription plan
         * @summary Retrieve a subscription plan
         * @param {string} planId
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [environment] Environment filter
         * @param {string} [tenantPlatform] Tenant platform
         * @param {boolean} [showMultiMonths] Show multi months
         * @param {boolean} [showMobifone9029] Show mobifone 9029. To compatible with mobile sctv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPlanDetail: function (planId, tenantId, authorization, acceptLanguage, environment, tenantPlatform, showMultiMonths, showMobifone9029, options) {
            return localVarFp.getSubscriptionPlanDetail(planId, tenantId, authorization, acceptLanguage, environment, tenantPlatform, showMultiMonths, showMobifone9029, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionTypes: function (tenantSlug, options) {
            return localVarFp.getSubscriptionTypes(tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Payment method multitenancy config
         * @summary Payment method multitenancy config
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantPaymentMethods: function (tenantSlug, options) {
            return localVarFp.getTenantPaymentMethods(tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get user invoice history
         * @summary Get user invoice history
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInvoiceHistory: function (tenantId, authorization, acceptLanguage, options) {
            return localVarFp.getUserInvoiceHistory(tenantId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get payment method for customer
         * @summary Get payment method for customer
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPaymentMethod: function (tenantId, authorization, acceptLanguage, options) {
            return localVarFp.getUserPaymentMethod(tenantId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get user subscription history
         * @summary Get user subscription history
         * @param {string} accountId
         * @param {string} tenantId
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSubscriptionHistory: function (accountId, tenantId, authorization, acceptLanguage, options) {
            return localVarFp.getUserSubscriptionHistory(accountId, tenantId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Paypal webhook api.
         * @summary Paypal webhook api
         * @param {string} paymentSlug
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paypalWebhook: function (paymentSlug, tenantSlug, options) {
            return localVarFp.paypalWebhook(paymentSlug, tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Paypal webhook api.
         * @summary Paypal webhook api
         * @param {string} paymentSlug
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paypalWebhook_2: function (paymentSlug, tenantSlug, options) {
            return localVarFp.paypalWebhook_2(paymentSlug, tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Sync subscription to Stripe server when payment via iAP
         * @summary Sync subscription to Stripe server when payment via iAP
         * @param {string} tenantId
         * @param {string} originalTransactionId use for subscription haven\&#39;t been used.
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [appOs] use for subscription haven\&#39;t been used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preCheckSubscription: function (tenantId, originalTransactionId, authorization, acceptLanguage, appOs, options) {
            return localVarFp.preCheckSubscription(tenantId, originalTransactionId, authorization, acceptLanguage, appOs, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Pre Confirm subscription per content
         * @summary Pre Confirm subscription per content
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {PreConfirmSubscriptionPerContentRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {string} [idempotencyKey] Idempotency-key
         * @param {string} [timezone] User timezone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preconfirmSingleContentSubscription: function (accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options) {
            return localVarFp.preconfirmSingleContentSubscription(accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * The remaining purchases of the account
         * @summary The remaining purchases of the account
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remainingPurchasesAccount: function (accountId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.remainingPurchasesAccount(accountId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Request a refund the content
         * @summary Request a refund the content
         * @param {string} contentId
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestContentRefund: function (contentId, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.requestContentRefund(contentId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * [ODX] Subscribe content via code
         * @summary [ODX] Subscribe content via code
         * @param {string} accountId
         * @param {string} tenantSlug
         * @param {SubscribeContentInfo} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeContentByCode: function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.subscribeContentByCode(accountId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Sync subscription to Stripe server when payment via iAP
         * @summary Sync subscription to Stripe server when payment via iAP
         * @param {string} tenantId
         * @param {SubscriptionViaIAPRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncIapSubscriptionToStripe: function (tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.syncIapSubscriptionToStripe(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Transfer Subscription
         * @summary Transfer Subscription
         * @param {string} tenantId
         * @param {TransferSubscriptionRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferSubscription: function (tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.transferSubscription(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerWebhookEvent: function (options) {
            return localVarFp.triggerWebhookEvent(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} tenantSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerWebhookEvent_3: function (tenantSlug, options) {
            return localVarFp.triggerWebhookEvent_3(tenantSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update mobiphone package (Webhook)
         * @summary Webhook to be called by mobiphone to update package
         * @param {string} mobiGw
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobiphonePackage: function (mobiGw, authorization, acceptLanguage, options) {
            return localVarFp.updateMobiphonePackage(mobiGw, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update mobiphone package (Webhook)
         * @summary Webhook to be called by mobiphone to update package
         * @param {string} mobiGw
         * @param {string} tenantSlug
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobiphonePackage_4: function (mobiGw, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.updateMobiphonePackage_4(mobiGw, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update default payment method for customer
         * @summary Update default payment method for customer
         * @param {string} tenantId
         * @param {UpdatePaymentMethodRequest} data
         * @param {string} [authorization] Access token
         * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPaymentMethod: function (tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.updateUserPaymentMethod(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PaymentApiFactory = PaymentApiFactory;
/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
var PaymentApi = /** @class */ (function (_super) {
    __extends(PaymentApi, _super);
    function PaymentApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create subscription for account api
     * @summary Create subscription for account api
     * @param {string} tenantSlug
     * @param {CreateSubscriptionForAccountRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.adminCreateAccountSubscription = function (tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).adminCreateAccountSubscription(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Apply coupon for account customer
     * @summary Apply coupon for account customer
     * @param {string} accountId
     * @param {string} tenantId
     * @param {ApplyCouponForCustomerRequestSeriazlier} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.applyCouponForUser = function (accountId, tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).applyCouponForUser(accountId, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Apply reddem code to account api
     * @summary Apply reddem code to account api
     * @param {string} accountId
     * @param {string} tenantId
     * @param {ApplyRedeemCodeToAccount} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {boolean} [confirm] Check Flag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.applyRedeemCodeToAccount = function (accountId, tenantId, data, authorization, acceptLanguage, confirm, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).applyRedeemCodeToAccount(accountId, tenantId, data, authorization, acceptLanguage, confirm, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Calculate payment subscription for stripe/sms/paypal/momo
     * @summary Calculate payment subscription for stripe/sms/paypal/momo
     * @param {string} accountId
     * @param {string} paymentProvider
     * @param {string} tenantId
     * @param {CreateSubscription} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.calcPaymentSubscription = function (accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).calcPaymentSubscription(accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Cancel request refund by user
     * @summary Cancel request refund by user
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.cancelRequestRefund = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).cancelRequestRefund(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Cancel user subscription
     * @summary Cancel user subscription
     * @param {string} tenantId
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.cancelUserSubscription = function (tenantId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).cancelUserSubscription(tenantId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Check available region for subscription
     * @summary Check available region for subscription
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.checkCountryAllowedSubscription = function (tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).checkCountryAllowedSubscription(tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Check payment subscription
     * @summary Check payment subscription
     * @param {string} accountId
     * @param {string} paymentProvider
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.checkPaymentSubscriptionApi = function (accountId, paymentProvider, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).checkPaymentSubscriptionApi(accountId, paymentProvider, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Confirm payment subscription for stripe/sms/paypal
     * @summary Confirm payment subscription for stripe/sms/paypal
     * @param {string} accountId
     * @param {string} paymentProvider
     * @param {string} tenantId
     * @param {ConfirmPaymentSubscriptionRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.confirmPaymentSubscription = function (accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).confirmPaymentSubscription(accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Confirm paypal purchase
     * @summary Confirm paypal purchase
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {ConfirmPaypalPurchaseRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.confirmPaypalPurchase = function (accountId, tenantSlug, data, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).confirmPaypalPurchase(accountId, tenantSlug, data, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Confirm subscription per content
     * @summary Confirm subscription per content
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {ConfirmSubscriptionPerContentRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [idempotencyKey] Idempotency-key
     * @param {string} [timezone] User timezone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.confirmSingleContentSubscription = function (accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).confirmSingleContentSubscription(accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Make payment content for user
     * @summary Make payment content for user
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {UserContentPaymentRequest} data
     * @param {string} [idempotencyKey] Idempotency-key
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.createIapSubscription = function (accountId, tenantSlug, data, idempotencyKey, authorization, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).createIapSubscription(accountId, tenantSlug, data, idempotencyKey, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * In App Purchase API
     * @summary In App Purchase API
     * @param {string} tenantSlug
     * @param {InAppPurchaseRequest} data
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [authorization] Access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.createIapSubscription_1 = function (tenantSlug, data, acceptLanguage, authorization, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).createIapSubscription_1(tenantSlug, data, acceptLanguage, authorization, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create payment subscription for stripe/sms/paypal/momo
     * @summary Create payment subscription for stripe/sms/paypal/momo
     * @param {string} accountId
     * @param {string} paymentProvider
     * @param {string} tenantId
     * @param {CreateSubscription} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.createPaymentSubscription = function (accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).createPaymentSubscription(accountId, paymentProvider, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create paypal purchase
     * @summary Create paypal purchase
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {CreatePaypalPurchaseRequest} data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.createPaypalPurchase = function (accountId, tenantSlug, data, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).createPaypalPurchase(accountId, tenantSlug, data, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create subscription per content
     * @summary Create subscription per content
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {PaySubscriptionPerContentRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [idempotencyKey] Idempotency-key
     * @param {string} [timezone] User timezone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.createSingleContentSubscription = function (accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).createSingleContentSubscription(accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create (stripe) subscription
     * @summary Create (stripe) subscription
     * @param {string} tenantId
     * @param {CreateStripeSubscription} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {boolean} [draft] use for subscription haven\&#39;t been used.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.createStripeSubscription = function (tenantId, data, authorization, acceptLanguage, draft, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).createStripeSubscription(tenantId, data, authorization, acceptLanguage, draft, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Export payments
     * @summary Export payments
     * @param {string} tenantSlug
     * @param {string} paymentMethod Get payment resource of payment method
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [search] Searching field
     * @param {string} [ordering] Ordering field
     * @param {number} [page] Paging
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.exportPayments = function (tenantSlug, paymentMethod, authorization, acceptLanguage, search, ordering, page, pageSize, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).exportPayments(tenantSlug, paymentMethod, authorization, acceptLanguage, search, ordering, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get list of subscription plan
     * @summary Get list of subscription plan
     * @param {string} tenantId
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.getAllSubscriptionPlans = function (tenantId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).getAllSubscriptionPlans(tenantId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get all approved refunds of user (filter by status)
     * @summary Get all approved refunds of user (filter by status)
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {number} [status] Refund status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.getApprovedRefunds = function (accountId, tenantSlug, authorization, acceptLanguage, page, limit, status, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).getApprovedRefunds(accountId, tenantSlug, authorization, acceptLanguage, page, limit, status, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get current subscription plan
     * @summary Get current actived subscription plan
     * @param {string} tenantId
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.getCurrentSubscriptionPlan = function (tenantId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).getCurrentSubscriptionPlan(tenantId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Sync subscription to Stripe server when payment via iAP
     * @summary Sync subscription to Stripe server when payment via iAP
     * @param {string} tenantId
     * @param {GetStatusIAPRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.getIapStatus = function (tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).getIapStatus(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get list of subscription plan
     * @summary Get list of subscription plan
     * @param {string} tenantId
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [environment] Environment filter
     * @param {string} [tenantPlatform] Tenant platform
     * @param {string} [planId] ID of plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.getListSubscriptionPlan = function (tenantId, authorization, acceptLanguage, environment, tenantPlatform, planId, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).getListSubscriptionPlan(tenantId, authorization, acceptLanguage, environment, tenantPlatform, planId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get list of subscription product
     * @summary Get list of subscription product
     * @param {string} tenantId
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [environment] Environment filter
     * @param {string} [tenantPlatform] Tenant platform
     * @param {string} [planId] ID of plan
     * @param {boolean} [showMultiMonths] Show multi months
     * @param {boolean} [showMobifone9029] Show mobifone 9029. To compatible with mobile sctv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.getListSubscriptionProduct = function (tenantId, authorization, acceptLanguage, environment, tenantPlatform, planId, showMultiMonths, showMobifone9029, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).getListSubscriptionProduct(tenantId, authorization, acceptLanguage, environment, tenantPlatform, planId, showMultiMonths, showMobifone9029, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get list payments
     * @summary Get list payments
     * @param {string} tenantSlug
     * @param {string} paymentMethod Get payment resource of payment method
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [search] Searching field
     * @param {string} [ordering] Ordering field
     * @param {number} [page] Paging
     * @param {number} [pageSize] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.getPayments = function (tenantSlug, paymentMethod, authorization, acceptLanguage, search, ordering, page, pageSize, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).getPayments(tenantSlug, paymentMethod, authorization, acceptLanguage, search, ordering, page, pageSize, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get list of paypal payer info
     * @summary Get list of paypal payer info
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.getPaypalPayers = function (accountId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).getPaypalPayers(accountId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve a subscription plan
     * @summary Retrieve a subscription plan
     * @param {string} planId
     * @param {string} tenantId
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [environment] Environment filter
     * @param {string} [tenantPlatform] Tenant platform
     * @param {boolean} [showMultiMonths] Show multi months
     * @param {boolean} [showMobifone9029] Show mobifone 9029. To compatible with mobile sctv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.getSubscriptionPlanDetail = function (planId, tenantId, authorization, acceptLanguage, environment, tenantPlatform, showMultiMonths, showMobifone9029, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).getSubscriptionPlanDetail(planId, tenantId, authorization, acceptLanguage, environment, tenantPlatform, showMultiMonths, showMobifone9029, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.getSubscriptionTypes = function (tenantSlug, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).getSubscriptionTypes(tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Payment method multitenancy config
     * @summary Payment method multitenancy config
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.getTenantPaymentMethods = function (tenantSlug, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).getTenantPaymentMethods(tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get user invoice history
     * @summary Get user invoice history
     * @param {string} tenantId
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.getUserInvoiceHistory = function (tenantId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).getUserInvoiceHistory(tenantId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get payment method for customer
     * @summary Get payment method for customer
     * @param {string} tenantId
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.getUserPaymentMethod = function (tenantId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).getUserPaymentMethod(tenantId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get user subscription history
     * @summary Get user subscription history
     * @param {string} accountId
     * @param {string} tenantId
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.getUserSubscriptionHistory = function (accountId, tenantId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).getUserSubscriptionHistory(accountId, tenantId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Paypal webhook api.
     * @summary Paypal webhook api
     * @param {string} paymentSlug
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.paypalWebhook = function (paymentSlug, tenantSlug, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).paypalWebhook(paymentSlug, tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Paypal webhook api.
     * @summary Paypal webhook api
     * @param {string} paymentSlug
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.paypalWebhook_2 = function (paymentSlug, tenantSlug, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).paypalWebhook_2(paymentSlug, tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Sync subscription to Stripe server when payment via iAP
     * @summary Sync subscription to Stripe server when payment via iAP
     * @param {string} tenantId
     * @param {string} originalTransactionId use for subscription haven\&#39;t been used.
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [appOs] use for subscription haven\&#39;t been used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.preCheckSubscription = function (tenantId, originalTransactionId, authorization, acceptLanguage, appOs, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).preCheckSubscription(tenantId, originalTransactionId, authorization, acceptLanguage, appOs, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Pre Confirm subscription per content
     * @summary Pre Confirm subscription per content
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {PreConfirmSubscriptionPerContentRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {string} [idempotencyKey] Idempotency-key
     * @param {string} [timezone] User timezone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.preconfirmSingleContentSubscription = function (accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).preconfirmSingleContentSubscription(accountId, tenantSlug, data, authorization, acceptLanguage, idempotencyKey, timezone, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * The remaining purchases of the account
     * @summary The remaining purchases of the account
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.remainingPurchasesAccount = function (accountId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).remainingPurchasesAccount(accountId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Request a refund the content
     * @summary Request a refund the content
     * @param {string} contentId
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.requestContentRefund = function (contentId, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).requestContentRefund(contentId, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * [ODX] Subscribe content via code
     * @summary [ODX] Subscribe content via code
     * @param {string} accountId
     * @param {string} tenantSlug
     * @param {SubscribeContentInfo} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.subscribeContentByCode = function (accountId, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).subscribeContentByCode(accountId, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Sync subscription to Stripe server when payment via iAP
     * @summary Sync subscription to Stripe server when payment via iAP
     * @param {string} tenantId
     * @param {SubscriptionViaIAPRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.syncIapSubscriptionToStripe = function (tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).syncIapSubscriptionToStripe(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Transfer Subscription
     * @summary Transfer Subscription
     * @param {string} tenantId
     * @param {TransferSubscriptionRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.transferSubscription = function (tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).transferSubscription(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.triggerWebhookEvent = function (options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).triggerWebhookEvent(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} tenantSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.triggerWebhookEvent_3 = function (tenantSlug, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).triggerWebhookEvent_3(tenantSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update mobiphone package (Webhook)
     * @summary Webhook to be called by mobiphone to update package
     * @param {string} mobiGw
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.updateMobiphonePackage = function (mobiGw, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).updateMobiphonePackage(mobiGw, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update mobiphone package (Webhook)
     * @summary Webhook to be called by mobiphone to update package
     * @param {string} mobiGw
     * @param {string} tenantSlug
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.updateMobiphonePackage_4 = function (mobiGw, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).updateMobiphonePackage_4(mobiGw, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update default payment method for customer
     * @summary Update default payment method for customer
     * @param {string} tenantId
     * @param {UpdatePaymentMethodRequest} data
     * @param {string} [authorization] Access token
     * @param {string} [acceptLanguage] For response message in difference language  ( en / vi )
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    PaymentApi.prototype.updateUserPaymentMethod = function (tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.PaymentApiFp)(this.configuration).updateUserPaymentMethod(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PaymentApi;
}(base_1.BaseAPI));
exports.PaymentApi = PaymentApi;
