import { store } from '~store';
import {
  CreateStripeSubscription,
  CreatePaypalPurchaseRequest,
  ConfirmPaypalPurchaseRequest,
  ConfirmPaymentSubscriptionRequest,
  CreateSubscription,
} from '~vimai-api-adapter/api-adapters/api-adapter-vimai/models';
import { IAxiosResponseData } from '~core/models';

import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';

const { paymentService } = apiAdapter;

const postSubscriptions = ({
  data = {},
  draft = false,
}: {
  data: CreateStripeSubscription;
  draft: boolean;
}): Promise<IAxiosResponseData<typeof paymentService.createStripeSubscription>> => {
  const { tenantSlug = '', authorization, acceptLanguage } = config;
  return paymentService
    .createStripeSubscription(tenantSlug, data, authorization, acceptLanguage, draft)
    .then(axiosTakeDataFromResponse);
};

const createPaypalSubcription = ({
  data = {},
}: {
  data: CreatePaypalPurchaseRequest;
}): Promise<IAxiosResponseData<typeof paymentService.createPaypalPurchase>> | void => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return;
  }
  const { plan_id, success_url, fail_url, platform, promo_code, product } = data;

  const payload = {
    plan_id,
    success_url,
    fail_url,
    platform,
    promo_code,
    product,
  };
  const { accountId = '', tenantSlug = '' } = config;
  return paymentService
    .createPaypalPurchase(accountId, tenantSlug, payload)
    .then(axiosTakeDataFromResponse);
};

const confirmPaypalSubcription = (
  data: ConfirmPaypalPurchaseRequest = {},
): Promise<IAxiosResponseData<typeof paymentService.confirmPaypalPurchase>> | void => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return;
  }
  const { token, subscription_id, ba_token } = data;
  const payload = { token, subscription_id, ba_token };
  const { accountId = '', tenantSlug = '' } = config;
  return paymentService
    .confirmPaypalPurchase(accountId, tenantSlug, payload)
    .then(axiosTakeDataFromResponse);
};

const smsPaymentConfirm = (
  data: ConfirmPaymentSubscriptionRequest = {},
): Promise<IAxiosResponseData<typeof paymentService.confirmPaymentSubscription>> | void => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return;
  }

  const { otp, transaction_id, order_id } = data;
  const payload = { otp, transaction_id, order_id };
  const { accountId = '', tenantSlug = '', authorization, acceptLanguage } = config;
  return paymentService
    .confirmPaymentSubscription(
      accountId,
      'mobifone_9092',
      tenantSlug,
      payload,
      authorization,
      acceptLanguage,
    )
    .then(axiosTakeDataFromResponse);
};

const pgMomoCreate = (
  params: CreateSubscription = {},
): Promise<IAxiosResponseData<typeof paymentService.createPaymentSubscription>> | void => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return;
  }
  const { plan_id, payment_method_id, product, promo_code, success_url, phone_number, email } =
    params;
  const payload = {
    plan_id,
    payment_method_id,
    product,
    promo_code,
    success_url,
    phone_number,
    email,
  };
  const { accountId = '', tenantSlug = '', authorization, acceptLanguage } = config;
  return paymentService
    .createPaymentSubscription(
      accountId,
      'momo',
      tenantSlug,
      payload,
      authorization,
      acceptLanguage,
    )
    .then(axiosTakeDataFromResponse);
};

const smsOTPCreate = (
  data: CreateSubscription = {},
): Promise<IAxiosResponseData<typeof paymentService.createPaymentSubscription>> | void => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return;
  }

  const payload = data;
  const { accountId = '', tenantSlug = '', authorization, acceptLanguage } = config;
  return paymentService
    .createPaymentSubscription(
      accountId,
      'mobifone_9092',
      tenantSlug,
      payload,
      authorization,
      acceptLanguage,
    )
    .then(axiosTakeDataFromResponse);
};

const pgMomoConfirm = (
  params: ConfirmPaymentSubscriptionRequest = {},
): Promise<IAxiosResponseData<typeof paymentService.confirmPaymentSubscription>> | void => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return;
  }

  const payload = params;
  const { accountId = '', tenantSlug = '', authorization, acceptLanguage } = config;
  return paymentService
    .confirmPaymentSubscription(
      accountId,
      'momo',
      tenantSlug,
      payload,
      authorization,
      acceptLanguage,
    )
    .then(axiosTakeDataFromResponse);
};

function getPaymentPlans(
  plan: { plan_id: string } | undefined = undefined,
): Promise<IAxiosResponseData<typeof paymentService.getListSubscriptionProduct>> {
  const { plan_id } = plan || {};
  const { tenantSlug = '', authorization, platformSlug, acceptLanguage, environment } = config;
  return paymentService
    .getListSubscriptionProduct(
      tenantSlug,
      authorization,
      acceptLanguage,
      environment,
      platformSlug,
      plan_id,
    )
    .then(axiosTakeDataFromResponse);
}

export {
  postSubscriptions,
  createPaypalSubcription,
  confirmPaypalSubcription,
  smsOTPCreate,
  smsPaymentConfirm,
  pgMomoConfirm,
  pgMomoCreate,
  getPaymentPlans,
};
