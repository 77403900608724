import { componentLoader } from '~root/app/functions/lazy-retry';

import Loadable from 'react-loadable';
import React from 'react';
import { ROUTE_NAMES } from '~core/constants';

import { isNode } from '~utils/ssr';

const META_DATA_LIST = [
  'genres',
  'nationalities',
  'directors',
  'casts',
  'producers',
  'categories',
  'sub-categories',
];

const HomePage = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ './features/homepage/views/homePage'),
  loading: () => <div>loading...</div>,
  modules: ['homepage'],
});
const TermsOfUse = componentLoader(() => import('./features/terms-of-use/views'));
const Confirmation = componentLoader(() => import('./features/confirmation/views/index'));
const LoginSso = componentLoader(() => import('./features/auth/pages/loginSSO/views/loginSSOPage'));
const DetailPage = componentLoader(() => import('./features/detailpage/views/playerPage'));
const LandingPage = componentLoader(() => import('./features/landingpage/views'));
const LivePage = componentLoader(() => import('./features/live/views/livePage'));
const RibbonPage = componentLoader(() => import('./features/ribbon/views/ribbonPage'));
const MyList = componentLoader(() => import('./features/myList/views/myList'));
const SearchPage = componentLoader(() => import('./features/search/views/searchPage'));
const Profile = componentLoader(() => import('./features/profile/views/profile'));
const Box = componentLoader(() => import('./features/box/views/boxPage'));
const ChangePass = componentLoader(() => import('./features/auth/components/changePass'));

const BlockCountry = componentLoader(() => import('./features/blockCountry'));
const BoxSvod = componentLoader(() =>
  import('./features/box/pages/subcriptionPlanPage/views/boxSupscriptionSvod'),
);
const YoutubePage = componentLoader(() => import('./features/youtubePage/views/index'));
const CategoryPage = componentLoader(() => import('./features/categoryPage/views/index'));
// const Metadata = componentLoader(() => import('./features/metadata/views/index'));
const Metadata = Loadable({
  loader: () => import('./features/metadata/views/index'),
  loading: () => null,
  modules: ['metadata-page'],
});

const FAQW = Loadable({
  loader: () => import('~features/Faq/projectw/FAQW'),
  loading: () => null,
  modules: ['faq-w-page'],
});

const MoreLikeThis = componentLoader(() =>
  import('./features/detailpage/videoComponents/escondido/moreLikeThis'),
);

const InstallApp = componentLoader(() => import('./features/install-app/views'));
const PageNotFound = componentLoader(() => import('~root/pages/404'));

const VodListPage = Loadable({
  loader: () => import('~features/vodListPage/projectw/views/VodListPage'),
  loading: () => null,
  modules: ['vod-list-page'],
});

const TermsOfServiceW = componentLoader(() =>
  import('~features/terms-of-use/views/projectw/TermsOfService'),
);
const PrivacyPolicyW = componentLoader(() =>
  import('~features/terms-of-use/views/projectw/PrivacyPolicy'),
);

const ReviewPage = componentLoader(() => import('~features/reviewPage/components/ReviewPage'));

const SearchPageW = componentLoader(() => import('./pages/searchPageW/components/SearchPageW'));

const CastCrewPage = componentLoader(() => import('./pages/castCrewPage/components/CastCrewPage'));

const FavoritePage = componentLoader(() => import('./pages/projectw/favoritePage/FavoritePage'));

const LogOut = componentLoader(() => import('./pages/logOutPage/LogoutPage'));

// const baseRouteUrl = `/:lng(${stringLanguage})`
const baseRouteUrl = isNode() ? '/:lg?' : '/:lg';
const routes = [
  {
    path: `${baseRouteUrl}/account/confirmation`,
    exact: true,
    main: () => Confirmation,
    name: ROUTE_NAMES.accountConfirm,
  },
  {
    path: `${baseRouteUrl}/auth/login/sso`,
    exact: true,
    main: () => LoginSso,
    name: ROUTE_NAMES.authLoginSso,
  },
  {
    path: `${baseRouteUrl}/terms-of-use`,
    exact: true,
    main: () => TermsOfUse,
    name: ROUTE_NAMES.termsOfUse,
  },
  {
    path: `${baseRouteUrl}/detail/:slug`,
    exact: true,
    main: () => DetailPage,
    name: ROUTE_NAMES.detailSlugPage,
  },
  {
    path: `${baseRouteUrl}/landing/:slug`,
    exact: true,
    main: () => LandingPage,
    name: ROUTE_NAMES.landingSlugPage,
  },
  // {
  //   path: `${baseRouteUrl}/live/:slug`,
  //   exact: true,
  //   main: () => LivePage,
  // },
  {
    path: `${baseRouteUrl}/ribbon/:slug`,
    exact: true,
    main: () => RibbonPage,
    name: ROUTE_NAMES.ribbonSlugPage,
  },
  {
    path: `${baseRouteUrl}/favorite`,
    exact: true,
    main: () => MyList,
    name: ROUTE_NAMES.favoritePage,
  },
  {
    path: `${baseRouteUrl}/search`,
    exact: true,
    main: () => SearchPage,
    name: ROUTE_NAMES.searchPage,
  },
  {
    path: `${baseRouteUrl}/search/:keyword`,
    exact: true,
    main: () => SearchPage,
    name: ROUTE_NAMES.searchKeywordPage,
  },
  {
    path: `${baseRouteUrl}/search/:keyword/page/:page`,
    exact: true,
    main: () => SearchPage,
    name: ROUTE_NAMES.searchKeywordPagePage,
  },
  {
    path: `${baseRouteUrl}/profile`,
    main: () => Profile,
    name: ROUTE_NAMES.profilePage,
  },
  {
    path: `${baseRouteUrl}/help`,
    exact: true,
    main: () => Profile,
    name: ROUTE_NAMES.helpPage,
  },
  {
    switch: true,
    exact: true,
    router: [
      {
        path: `${baseRouteUrl}/faq/:slugBlockAds`,
      },
      {
        path: `${baseRouteUrl}/faq`,
      },
    ],
    main: () => FAQW,
    name: ROUTE_NAMES.faqPage,
  },

  {
    path: `${baseRouteUrl}/category/:slug`,
    exact: true,
    main: () => CategoryPage,
    name: ROUTE_NAMES.categorySlugPage,
  },
  // {
  //   path: `${baseRouteUrl}/blocks`,
  //   exact: true,
  //   main: () => UpgradeSubcription
  // },
  {
    path: `${baseRouteUrl}/password/change`,
    exact: true,
    main: () => ChangePass,
    name: ROUTE_NAMES.passwordChange,
  },
  {
    path: `${baseRouteUrl}/block`,
    exact: true,
    main: () => BlockCountry,
    name: ROUTE_NAMES.blockPage,
  },
  // {
  //   path: `${baseRouteUrl}/box/:plant`,
  //   exact: true,
  //   main: () => Box,
  // },
  {
    path: `${baseRouteUrl}/install-app`,
    exact: true,
    main: () => InstallApp,
    name: ROUTE_NAMES.installAppPage,
  },
  {
    switch: true,
    exact: true,
    router: [
      {
        path: `${baseRouteUrl}/box/offers/svod`,
        flag: true,
        main: () => BoxSvod,
        data: {
          svod: true,
        },
      },
      {
        path: `${baseRouteUrl}/box/:plant`,
      },
      {
        path: `${baseRouteUrl}/box`,
      },
    ],
    main: () => Box,
  },
  {
    path: `${baseRouteUrl}/youtube/:slug`,
    exact: true,
    main: () => YoutubePage,
    name: ROUTE_NAMES.youtubeSlugPage,
  },
  {
    path: `${baseRouteUrl}/youtube/:slug/search`,
    exact: false,
    props: { search: true },
    main: () => YoutubePage,
    name: ROUTE_NAMES.youtubeSlugSearchPage,
  },
  {
    path: `${baseRouteUrl}/detail/:slug/preview`,
    exact: true,
    main: () => MoreLikeThis,
    name: ROUTE_NAMES.detailSlugPreviewPage,
  },
  {
    path: `${baseRouteUrl}/:metadataSlug(${META_DATA_LIST.join('|')})/:slug`,
    exact: true,
    main: () => Metadata,
    name: ROUTE_NAMES.metadataSlug,
  },

  {
    path: `${baseRouteUrl}/vod-list-page`,
    exact: true,
    main: () => VodListPage,
    name: ROUTE_NAMES.vodListPage,
  },
  {
    path: `${baseRouteUrl}/terms-of-service`,
    exact: true,
    main: () => TermsOfServiceW,
    name: ROUTE_NAMES.termsOfService,
  },
  {
    path: `${baseRouteUrl}/privacy-policy`,
    exact: true,
    main: () => PrivacyPolicyW,
    name: ROUTE_NAMES.privacyPolicy,
  },
  {
    path: `${baseRouteUrl}/contents/:slug/review-page`,
    exact: true,
    main: () => ReviewPage,
    name: ROUTE_NAMES.reviewPage,
  },
  {
    path: `${baseRouteUrl}/contents/:slug/cast-and-crew-page`,
    exact: true,
    main: () => CastCrewPage,
    name: ROUTE_NAMES.castAndCrewPage,
  },
  {
    path: `${baseRouteUrl}/search-results`,
    exact: true,
    main: () => SearchPageW,
    name: ROUTE_NAMES.searchResultPage,
  },
  {
    path: `${baseRouteUrl}/contents/favorite-page`,
    exact: true,
    main: () => FavoritePage,
  },
  {
    path: `${baseRouteUrl}/logout`,
    exact: true,
    main: () => LogOut,
  },
  {
    path: [`${baseRouteUrl}/:tenants(page|live)/:slug`, `${baseRouteUrl}`],

    exact: true,
    main: () => HomePage,
    name: ROUTE_NAMES.tenantPage,
  },
  {
    exact: true,
    path: `${baseRouteUrl}/404`,
    main: () => PageNotFound,
  },
];

export default routes;
