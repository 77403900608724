var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapOdxLiveTvToPageLiveShedulesRes = exports.mapOdxLiveTvToRibbonLiveSchedulesRes = exports.mapOdxLiveTvChannelsToChannelLiveScheduleRes = exports.mapOdxRecommendationProgramItemToRecommendationContent = exports.mapOdxProfileToAccountHybridProfile = exports.mapOdxProfileAvatarToHybridProfileAvatar = exports.mapOdxTVGuideProgramItemToRibbonDetail = exports.mapOdxPlaybackDRMToDRMSessionInfo = exports.mapCWContinuousWatchingHistoryToInteractedContent = exports.mapOdxClientBaseToCheckVersionResponse = exports.mapOdxV1ContinuousWatchingHistoryToRibbonItem = exports.mapCarouselsForChannelToRibbonItem = exports.mapOdxPlaybackTextTracksInnerToContentSubtitleResponse = exports.mapOdxEpisodeListToEpisode = exports.mapOdxEpisodeListToContentViewInfo = exports.mapOdxEpisodeListToTrailer = exports.mapOdxChannelsToContentViewInfo = exports.mapOdxChannelsToContentDetail = exports.mapOdxEPGToEpgFeedRes = exports.mapOdxChannelsToChannelLiveScheduleRes = exports.mapOdxProgramToContentDetail = exports.mapFavoritesToMovieDetail = exports.mapOdxSearchToSearchItem = exports.mapOdxProgramsToRibbonItem = exports.mapCategoryFilterOptionItemInnerToRibbonDetail = exports.mapCoverDataToBannerItem = exports.mapCurationToRibbonData = exports.mapProgramListToRibbonItem = exports.mapSimpleProgramListToRibbonItem = exports.mapEpisodeListToRibbonItem = exports.mapSimpleEpisodeListToRibbonItem = void 0;
var constants_1 = require("../constants");
var factory_1 = require("../factory");
var content_utils_1 = require("../utils/content-utils");
var image_utils_1 = require("../utils/image-utils");
var ribbon_utils_1 = require("../utils/ribbon-utils");
var mapSimpleEpisodeListToRibbonItem = function (i) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var badgeSerializer = (0, content_utils_1.getBadgeSerializer)((_a = i.view) === null || _a === void 0 ? void 0 : _a.badges);
    var showIdentity = (0, content_utils_1.buildContentIdentity)({
        id: parseInt("".concat((_c = (_b = i.action) === null || _b === void 0 ? void 0 : _b.program_id) !== null && _c !== void 0 ? _c : 0), 10),
        slug: (_d = i.analytics) === null || _d === void 0 ? void 0 : _d.program_slug,
        type: 'show',
    });
    var directlyContentIdentity = (0, content_utils_1.buildContentIdentity)({
        id: parseInt("".concat((_f = (_e = i.action) === null || _e === void 0 ? void 0 : _e.episode_id) !== null && _f !== void 0 ? _f : 0), 10),
        slug: '__truncated__',
        type: 'episode',
    });
    var thumbnailUrl = '';
    var posterUrl = '';
    if (((_h = (_g = i.view) === null || _g === void 0 ? void 0 : _g.image) === null || _h === void 0 ? void 0 : _h.orientation) === 'landscape') {
        thumbnailUrl = (_k = (_j = i.view) === null || _j === void 0 ? void 0 : _j.image) === null || _k === void 0 ? void 0 : _k.url;
    }
    else {
        posterUrl = (_m = (_l = i.view) === null || _l === void 0 ? void 0 : _l.image) === null || _m === void 0 ? void 0 : _m.url;
    }
    return __assign(__assign({}, factory_1.defaultRibbonItem), { id: showIdentity, slug: showIdentity, type: constants_1.ContentType.SHOW, title: (_o = i.view) === null || _o === void 0 ? void 0 : _o.title, short_title: (_p = i.view) === null || _p === void 0 ? void 0 : _p.title, origin_title: (_q = i.view) === null || _q === void 0 ? void 0 : _q.title, images: {
            thumbnail: (0, image_utils_1.getResizedUrl)('thumbnail', thumbnailUrl),
            poster: (0, image_utils_1.getResizedUrl)('poster', posterUrl),
            poster_banner: (0, image_utils_1.getResizedUrl)('thumbnail', posterUrl),
        }, direct_play_content: {
            id: directlyContentIdentity,
            slug: directlyContentIdentity,
            type: constants_1.ContentType.EPISODE,
            title: (_r = i.view) === null || _r === void 0 ? void 0 : _r.title,
        }, is_favorite: (_s = i.view) === null || _s === void 0 ? void 0 : _s.is_favorite, top_index: badgeSerializer.topIndex, is_premium: badgeSerializer.isPremium, is_new_release: badgeSerializer.isNewRelease, restriction: badgeSerializer.restricted ? 1 : 0, has_free_content: !badgeSerializer.isPremium, payment_type: badgeSerializer.getPaymentType(), badges: badgeSerializer.asVimaiBadges() });
};
exports.mapSimpleEpisodeListToRibbonItem = mapSimpleEpisodeListToRibbonItem;
var mapEpisodeListToRibbonItem = function (i) {
    var _a, _b, _c, _d, _e, _f, _g;
    var badgeSerializer = (0, content_utils_1.getBadgeSerializer)(i.badges);
    var identity = (0, content_utils_1.buildContentIdentity)({ id: (_a = i.program) === null || _a === void 0 ? void 0 : _a.id, slug: (_b = i.program) === null || _b === void 0 ? void 0 : _b.slug, type: 'show' });
    var directlyContentIdentity = (0, content_utils_1.buildContentIdentity)({ id: i.id, slug: '__truncated__', type: 'episode' });
    return __assign(__assign({}, factory_1.defaultRibbonItem), { id: identity, slug: identity, type: constants_1.ContentType.SHOW, title: i.title, short_title: i.title, origin_title: i.title, images: {
            thumbnail: (0, image_utils_1.getResizedUrl)('thumbnail', (_c = i.images) === null || _c === void 0 ? void 0 : _c.thumbnail),
            poster: (0, image_utils_1.getResizedUrl)('poster', (_e = (_d = i.program) === null || _d === void 0 ? void 0 : _d.images) === null || _e === void 0 ? void 0 : _e.poster_portrait),
            poster_banner: (0, image_utils_1.getResizedUrl)('thumbnail', (_g = (_f = i.program) === null || _f === void 0 ? void 0 : _f.images) === null || _g === void 0 ? void 0 : _g.poster_landscape),
        }, direct_play_content: {
            id: directlyContentIdentity,
            slug: directlyContentIdentity,
            type: constants_1.ContentType.EPISODE,
            title: i.title,
        }, top_index: badgeSerializer.topIndex, is_premium: badgeSerializer.isPremium, is_new_release: badgeSerializer.isNewRelease, restriction: badgeSerializer.restricted ? 1 : 0, has_free_content: !badgeSerializer.isPremium, payment_type: badgeSerializer.getPaymentType(), badges: badgeSerializer.asVimaiBadges() });
};
exports.mapEpisodeListToRibbonItem = mapEpisodeListToRibbonItem;
var mapSimpleProgramListToRibbonItem = function (i) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var badgeSerializer = (0, content_utils_1.getBadgeSerializer)((_a = i.view) === null || _a === void 0 ? void 0 : _a.badges);
    var identity = (0, content_utils_1.buildContentIdentity)({
        id: parseInt("".concat((_c = (_b = i.action) === null || _b === void 0 ? void 0 : _b.program_id) !== null && _c !== void 0 ? _c : '0'), 10),
        slug: (_d = i.action) === null || _d === void 0 ? void 0 : _d.program_slug,
        type: 'show',
    });
    var thumbnailUrl = '';
    var posterUrl = '';
    if (((_f = (_e = i.view) === null || _e === void 0 ? void 0 : _e.image) === null || _f === void 0 ? void 0 : _f.orientation) === 'landscape') {
        thumbnailUrl = (_h = (_g = i.view) === null || _g === void 0 ? void 0 : _g.image) === null || _h === void 0 ? void 0 : _h.url;
    }
    else {
        posterUrl = (_k = (_j = i.view) === null || _j === void 0 ? void 0 : _j.image) === null || _k === void 0 ? void 0 : _k.url;
    }
    return __assign(__assign({}, factory_1.defaultRibbonItem), { id: identity, slug: identity, type: constants_1.ContentType.SHOW, title: (_l = i.view) === null || _l === void 0 ? void 0 : _l.title, short_title: (_m = i.view) === null || _m === void 0 ? void 0 : _m.title, origin_title: (_o = i.view) === null || _o === void 0 ? void 0 : _o.title, images: {
            thumbnail: (0, image_utils_1.getResizedUrl)('thumbnail', thumbnailUrl),
            poster: (0, image_utils_1.getResizedUrl)('poster', posterUrl),
            poster_banner: (0, image_utils_1.getResizedUrl)('poster', posterUrl),
        }, is_favorite: (_p = i.view) === null || _p === void 0 ? void 0 : _p.is_favorite, top_index: badgeSerializer.topIndex, is_premium: badgeSerializer.isPremium, is_new_release: badgeSerializer.isNewRelease, restriction: badgeSerializer.restricted ? 1 : 0, has_free_content: !badgeSerializer.isPremium, payment_type: badgeSerializer.getPaymentType(), badges: badgeSerializer.asVimaiBadges() });
};
exports.mapSimpleProgramListToRibbonItem = mapSimpleProgramListToRibbonItem;
var mapProgramListToRibbonItem = function (i) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var badgeSerializer = (0, content_utils_1.getBadgeSerializer)(i.badges);
    var identity = (0, content_utils_1.buildContentIdentity)({ id: i.id, slug: i.slug, type: 'show' });
    return __assign(__assign({}, factory_1.defaultRibbonItem), { id: identity, slug: identity, type: constants_1.ContentType.SHOW, title: i.title, short_title: i.title, origin_title: i.title, total_episodes: (_a = i.meta) === null || _a === void 0 ? void 0 : _a.episode_total, images: {
            // @ts-ignore
            thumbnail: (0, image_utils_1.getResizedUrl)('thumbnail', ((_b = i.images) === null || _b === void 0 ? void 0 : _b.thumbnail) || ((_c = i.images) === null || _c === void 0 ? void 0 : _c.poster_landscape)),
            poster: (0, image_utils_1.getResizedUrl)('poster', (_d = i.images) === null || _d === void 0 ? void 0 : _d.poster_portrait),
            poster_banner: (0, image_utils_1.getResizedUrl)('poster', (_e = i.images) === null || _e === void 0 ? void 0 : _e.poster_landscape),
        }, content_categories: (_f = i.categories) === null || _f === void 0 ? void 0 : _f.map(function (ct) {
            var _a;
            return ({
                id: (_a = ct.id) === null || _a === void 0 ? void 0 : _a.toString(),
                slug: ct.slug,
                name: ct.title,
            });
        }), genre: (_g = i.genres) === null || _g === void 0 ? void 0 : _g.map(function (g) { return g.title; }), release_year: i.produce_year, language: (_h = i.meta) === null || _h === void 0 ? void 0 : _h.cc_languages, metadata: {
            content_ratings: [],
        }, top_index: badgeSerializer.topIndex, is_premium: badgeSerializer.isPremium, is_new_release: badgeSerializer.isNewRelease, restriction: badgeSerializer.restricted ? 1 : 0, has_free_content: !badgeSerializer.isPremium, payment_type: badgeSerializer.getPaymentType(), badges: badgeSerializer.asVimaiBadges() });
};
exports.mapProgramListToRibbonItem = mapProgramListToRibbonItem;
var mapCurationToRibbonData = function (curation, index) {
    var _a, _b, _c;
    var ribbonItems = [];
    var ribbonType = constants_1.RibbonType.THUMBNAIL;
    var ribbonDisplayStyle = constants_1.RibbonDisplayStyle.THUMBNAIL;
    var ribbonOptions = { content_navigation_option: 'default' };
    var isOdkChannel;
    try {
        // @ts-ignore
        isOdkChannel = ((_c = (_b = (_a = curation.carousels) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.info) === null || _c === void 0 ? void 0 : _c.type) === 'channel';
    }
    catch (e) {
        isOdkChannel = false;
    }
    /** region Map carousels to ribbon items */
    if (isOdkChannel) {
        var items = curation.carousels;
        ribbonItems = items.map(exports.mapCarouselsForChannelToRibbonItem);
    }
    else if (curation.logic_ordering === 'episode-recent-updated-by-date') {
        var items = curation.carousels;
        var lastDay_1 = null;
        Object.keys(items).forEach(function (d) {
            if (!lastDay_1 || new Date(lastDay_1) < new Date(d)) {
                lastDay_1 = d;
            }
        });
        if (lastDay_1) {
            ribbonItems = items[lastDay_1].map(exports.mapEpisodeListToRibbonItem);
        }
    }
    else if (curation.logic_ordering === 'episode-continue-watching') {
        var items = curation.carousels;
        ribbonItems = items.map(exports.mapOdxV1ContinuousWatchingHistoryToRibbonItem);
    }
    else if (curation.type === 'program') {
        if (['special', 'normal', 'local'].includes(curation.carousel_type)) {
            var items = curation.carousels;
            ribbonItems = items.map(exports.mapSimpleProgramListToRibbonItem);
        }
        else {
            var items = curation.carousels;
            ribbonItems = items.map(exports.mapProgramListToRibbonItem);
        }
    }
    else if (curation.type === 'episode') {
        if (['special', 'normal', 'local'].includes(curation.carousel_type)) {
            var items = curation.carousels;
            ribbonItems = items.map(exports.mapSimpleEpisodeListToRibbonItem);
        }
        else {
            var items = curation.carousels;
            ribbonItems = items.map(exports.mapEpisodeListToRibbonItem);
        }
    } // endregion
    /** region Switch to program poster in specifics curation */
    var preferPosterCurationSlugs = [
        // Staging
        'featured-collection',
        'episode-reco',
        'episode-recent-updated-by-date',
        '24h-free',
        // Production
        'whats-new',
        'free-content',
        'recommended-for-you',
    ];
    if (curation.type === 'episode' && preferPosterCurationSlugs.includes(curation.slug)) {
        ribbonItems.forEach(function (c) {
            c.images.thumbnail = c.images.poster_banner;
        });
    } // endregion
    /** region Define ribbon type base on logic ordering */
    if (curation.logic_ordering === 'episode-continue-watching') {
        ribbonType = constants_1.RibbonType.RECENT_MOVIE;
    }
    else if (curation.logic_ordering === 'program-favorite') {
        ribbonType = constants_1.RibbonType.FAVORITE_CONTENTS;
    }
    else if (curation.type === 'episode') {
        // https://pm.youthdev.net/issues/52703
        ribbonDisplayStyle = constants_1.RibbonDisplayStyle.THUMBNAIL_WITH_TITLE;
    }
    else if (curation.ratio === 'portrait') {
        ribbonType = constants_1.RibbonType.POSTER;
        ribbonDisplayStyle = constants_1.RibbonDisplayStyle.POSTER;
    }
    // endregion
    /** region Define navigation logic for ribbon */
    if (curation.type === 'episode') {
        ribbonOptions.content_navigation_option = 'directly_to_player';
    } // endregion
    return {
        id: curation.slug,
        slug: curation.slug,
        type: ribbonType,
        name: curation.title,
        sub_name: curation.title,
        display_type: ribbonDisplayStyle,
        odr: index,
        is_visible_in_ribbon_main_section: true,
        is_default_display: false,
        is_visible_in_side_navigation_section: false,
        is_visible_in_sub_navigation_bar: false,
        show_flag_odr: false,
        video_source: 0,
        items: ribbonItems,
        ribbon_options: ribbonOptions,
        metadata: {
            source: curation,
        },
    };
};
exports.mapCurationToRibbonData = mapCurationToRibbonData;
var mapCoverDataToBannerItem = function (coverData) {
    var banner = coverData.program;
    var directPlayEpisode = coverData.directPlayEpisode;
    var contentType = banner.content_type === 'channel' ? constants_1.ContentType.LIVE_CHANNEL : constants_1.ContentType.SHOW;
    var contentSlug = banner.content_type === 'channel' ? '' : banner.content_slug;
    var identity = (0, content_utils_1.buildContentIdentity)({
        id: banner.content_id,
        slug: contentSlug,
        type: (0, content_utils_1.getIdentityTypeFromContentType)(contentType),
    });
    var directContentIdentity = (0, content_utils_1.buildContentIdentity)({
        id: directPlayEpisode === null || directPlayEpisode === void 0 ? void 0 : directPlayEpisode.id,
        slug: directPlayEpisode === null || directPlayEpisode === void 0 ? void 0 : directPlayEpisode.slug,
        type: 'episode',
    });
    var isPremium = ['PREMIUM', 'PPV'].includes(banner.content_sales_type);
    var paymentType;
    switch (banner.content_sales_type) {
        case 'GUEST': {
            paymentType = 'guest';
            break;
        }
        case 'FREE': {
            paymentType = 'free';
            break;
        }
        case 'PPV': {
            paymentType = 'tvod';
            break;
        }
        default:
            paymentType = 'svod';
    }
    return __assign(__assign({}, factory_1.defaultBannerItem), { title: banner.title, id: identity, slug: identity, type: contentType, long_description: banner.description, is_premium: isPremium, has_free_content: !isPremium, payment_type: paymentType, images: {
            banner: (0, image_utils_1.getResizedUrl)('banner', banner.thumbnail || banner.image_landscape),
            thumbnail: (0, image_utils_1.getResizedUrl)('thumbnail', banner.thumbnail || banner.image_landscape),
        }, short_title: banner.title, title_en: banner.title, origin_title: banner.title, trailers: banner.video
            ? [
                {
                    id: constants_1.ODXKey.defaultUnknown,
                    slug: constants_1.ODXKey.defaultUnknown,
                    title: banner.title,
                    images: {},
                    is_premium: false,
                    can_preview: false,
                    video_source: 0,
                    is_new_release: false,
                    type: constants_1.ContentType.TRAILER,
                    restriction: 0,
                    link_play: banner.video,
                    runtime: 0,
                    short_description: '',
                    long_description: '',
                    release_date: null,
                },
            ]
            : [], direct_play_content: directPlayEpisode
            ? {
                id: directContentIdentity,
                slug: directContentIdentity,
                type: constants_1.ContentType.EPISODE,
                title: directPlayEpisode.title,
            }
            : null });
};
exports.mapCoverDataToBannerItem = mapCoverDataToBannerItem;
var mapCategoryFilterOptionItemInnerToRibbonDetail = function (categoryFilter, index, identity, items) {
    if (items === void 0) { items = []; }
    var categorySlug = (0, ribbon_utils_1.getFilterOptionsFromRibbonSlug)(identity).categorySlug;
    var ribbonDisplayType;
    var ribbonType;
    if (categorySlug === 'korean-news') {
        ribbonDisplayType = constants_1.RibbonDisplayStyle.THUMBNAIL_WITH_PROGRAM_INFO;
    }
    else {
        ribbonDisplayType = constants_1.RibbonDisplayStyle.THUMBNAIL;
    }
    if (categorySlug === 'movies') {
        ribbonType = constants_1.RibbonType.POSTER;
    }
    else {
        ribbonType = constants_1.RibbonType.THUMBNAIL;
    }
    return {
        id: identity,
        slug: identity,
        type: ribbonType,
        name: categoryFilter.title,
        sub_name: categoryFilter.title,
        display_type: ribbonDisplayType,
        odr: index,
        is_visible_in_ribbon_main_section: true,
        is_default_display: false,
        is_visible_in_side_navigation_section: false,
        is_visible_in_sub_navigation_bar: false,
        show_flag_odr: false,
        video_source: 0,
        items: items,
    };
};
exports.mapCategoryFilterOptionItemInnerToRibbonDetail = mapCategoryFilterOptionItemInnerToRibbonDetail;
var mapOdxProgramsToRibbonItem = function (item) {
    var _a, _b, _c;
    var badgeSerializer = (0, content_utils_1.getBadgeSerializer)(item.badges);
    var identity = (0, content_utils_1.buildContentIdentity)({ id: item.id, slug: item.slug, type: 'movie' });
    return __assign(__assign({}, factory_1.defaultRibbonItem), { id: identity, slug: identity, type: constants_1.ContentType.MOVIE, title: new Date(item.latest_publish_start).toLocaleDateString(), on_air_time: item.latest_publish_start, images: __assign(__assign({}, factory_1.defaultRibbonItem.images), { thumbnail: (0, image_utils_1.getResizedUrl)('thumbnail', (_a = item.images) === null || _a === void 0 ? void 0 : _a.poster_landscape), poster: (0, image_utils_1.getResizedUrl)('poster', (_b = item.images) === null || _b === void 0 ? void 0 : _b.poster_portrait), banner: (0, image_utils_1.getResizedUrl)('banner', (_c = item.images) === null || _c === void 0 ? void 0 : _c.poster) }), top_index: badgeSerializer.topIndex, is_premium: badgeSerializer.isPremium, is_new_release: badgeSerializer.isNewRelease, restriction: badgeSerializer.restricted ? 1 : 0, has_free_content: !badgeSerializer.isPremium, payment_type: badgeSerializer.getPaymentType(), badges: badgeSerializer.asVimaiBadges() });
};
exports.mapOdxProgramsToRibbonItem = mapOdxProgramsToRibbonItem;
var mapOdxSearchToSearchItem = function (item) {
    var _a, _b, _c;
    var badgeSerializer = (0, content_utils_1.getBadgeSerializer)(item.badges);
    var identity = (0, content_utils_1.buildContentIdentity)({ id: item.id, slug: item.slug, type: 'show' });
    var paymentType = badgeSerializer.getPaymentType();
    var paymentTypeInt = paymentType === 'svod' ? 1 : paymentType === 'tvod' ? 2 : 0;
    return __assign(__assign({}, factory_1.defaultMovie), { id: identity, slug: identity, title: item.title, images: __assign(__assign({}, factory_1.defaultMovie.images), { thumbnail: (0, image_utils_1.getResizedUrl)('thumbnail', (_a = item.images) === null || _a === void 0 ? void 0 : _a.poster_landscape), poster: (0, image_utils_1.getResizedUrl)('poster', (_b = item.images) === null || _b === void 0 ? void 0 : _b.poster_portrait), banner: (0, image_utils_1.getResizedUrl)('banner', (_c = item.images) === null || _c === void 0 ? void 0 : _c.poster) }), is_premium: badgeSerializer.isPremium, payment_type: paymentTypeInt, has_free_content: badgeSerializer.isPremium, is_new_release: badgeSerializer.isNewRelease, top_index: badgeSerializer.topIndex, restriction: badgeSerializer.restricted ? 1 : 0, badges: badgeSerializer.asVimaiBadges() });
};
exports.mapOdxSearchToSearchItem = mapOdxSearchToSearchItem;
var mapFavoritesToMovieDetail = function (favorites) {
    var _a, _b, _c, _d, _e, _f, _g;
    var identity = (0, content_utils_1.buildContentIdentity)({ id: favorites.id, slug: favorites.slug, type: 'show' });
    return __assign(__assign({}, factory_1.defaultMovieDetail), { id: identity, slug: identity, images: __assign(__assign({}, factory_1.defaultMovieDetail.images), { thumbnail: (0, image_utils_1.getResizedUrl)('thumbnail', (_a = favorites.images) === null || _a === void 0 ? void 0 : _a.poster_landscape), poster: (0, image_utils_1.getResizedUrl)('poster', (_b = favorites.images) === null || _b === void 0 ? void 0 : _b.poster_portrait), banner: (0, image_utils_1.getResizedUrl)('banner', (_c = favorites.images) === null || _c === void 0 ? void 0 : _c.poster) }), current_season: {
            season_name: constants_1.ODXKey.defaultUnknown,
            season_index: 0,
            current_episode: {
                id: (_e = (_d = favorites.direct_play_episode) === null || _d === void 0 ? void 0 : _d.id) === null || _e === void 0 ? void 0 : _e.toString(),
                slug: (_g = (_f = favorites.direct_play_episode) === null || _f === void 0 ? void 0 : _f.id) === null || _g === void 0 ? void 0 : _g.toString(),
                progress: 0,
                episode: 1,
                episode_name: '',
                episode_index: 1,
            },
            id: constants_1.ODXKey.defaultUnknown,
            total_episodes: 1,
            total_released_episodes: 1,
        } });
};
exports.mapFavoritesToMovieDetail = mapFavoritesToMovieDetail;
var mapOdxProgramToContentDetail = function (program) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var saleType = program.default_sales_type;
    var isPremium = !['FREE', 'GUEST'].includes(saleType);
    var isPPV = saleType === 'PPV';
    var contentType = (0, content_utils_1.getContentTypeFromKindsOfEpisodes)(program.kinds_of_episodes);
    var type = (0, content_utils_1.getIdentityTypeFromContentType)(contentType);
    var seasons = [];
    if ((0, content_utils_1.isShow)(program)) {
        var seasonIdentity = (0, content_utils_1.buildContentIdentity)({
            id: program.id,
            slug: program.slug,
            type: 'season',
        });
        seasons = [
            {
                id: seasonIdentity,
                slug: seasonIdentity,
                group: program.slug,
                title: program.title,
                type: constants_1.ContentType.SEASON,
                known_as: program.title,
            },
        ];
    }
    var identity = (0, content_utils_1.buildContentIdentity)({
        id: program.id,
        slug: program.slug,
        type: type,
    });
    var rules = [];
    if ((_a = program === null || program === void 0 ? void 0 : program.meta) === null || _a === void 0 ? void 0 : _a.hide_ep_date) {
        rules.push('hide_episode_release_date');
    }
    return __assign(__assign({}, factory_1.defaultContentDetail), { id: identity, slug: identity, title: program.title, short_title: program.title, origin_title: program.title, long_description: program.synopsis, type: contentType, release_year: program.produce_year, images: __assign(__assign({}, factory_1.defaultContentDetail.images), { thumbnail: (0, image_utils_1.getResizedUrl)('thumbnail', (_b = program.images) === null || _b === void 0 ? void 0 : _b.poster_landscape), poster: (0, image_utils_1.getResizedUrl)('poster', (_c = program.images) === null || _c === void 0 ? void 0 : _c.poster_portrait), backdrop: (0, image_utils_1.getResizedUrl)('backdrop', (_d = program.images) === null || _d === void 0 ? void 0 : _d.poster_landscape) }), total_episodes: program.meta.episode_total, runtime: (_e = program.meta) === null || _e === void 0 ? void 0 : _e.running_time, duration: (((_f = program.meta) === null || _f === void 0 ? void 0 : _f.running_time) || 0) * 60, released_episode_count: program.meta.episode_total, content_categories: (_g = program.categories) === null || _g === void 0 ? void 0 : _g.map(function (category) {
            var _a;
            return ({
                id: (_a = category.id) === null || _a === void 0 ? void 0 : _a.toString(),
                name: category.title,
                slug: category.slug,
            });
        }), genre: (_h = program.genres) === null || _h === void 0 ? void 0 : _h.map(function (g) { return g.title; }), people: (_j = program.people) === null || _j === void 0 ? void 0 : _j.map(function (p) { return ({
            role: p.role,
            name: p.name,
        }); }), metadata: __assign(__assign({}, factory_1.defaultContentDetail.metadata), { regions: ((_l = (_k = program.meta) === null || _k === void 0 ? void 0 : _k.produce_countries) === null || _l === void 0 ? void 0 : _l.map(function (p) { return ({
                id: constants_1.ODXKey.defaultUnknown,
                slug: constants_1.ODXKey.defaultUnknown,
                name: p,
            }); })) || [], peoples: ((_m = program.people) === null || _m === void 0 ? void 0 : _m.map(function (p) { return ({
                role: p.role,
                name: p.name,
                slug: p.slug,
                avatar: '',
            }); })) || [], genres: ((_o = program.genres) === null || _o === void 0 ? void 0 : _o.map(function (g) { return ({
                id: g.id,
                name: g.title,
                slug: g.slug,
            }); })) || [] }), restriction: 0, is_premium: isPremium, has_free_content: !isPremium, payment_type: isPPV ? 'tvod' : isPremium ? 'svod' : 'free', language: (_p = program.meta) === null || _p === void 0 ? void 0 : _p.cc_languages, regions: (_q = program.meta) === null || _q === void 0 ? void 0 : _q.produce_countries, air_info: __assign(__assign({}, factory_1.defaultContentDetail.air_info), { air_status: (0, content_utils_1.getProgramAirStatus)(program) }), seasons: seasons, display_option: __assign(__assign({}, factory_1.defaultContentDetail.display_option), { rules: rules }) });
};
exports.mapOdxProgramToContentDetail = mapOdxProgramToContentDetail;
var mapOdxChannelsToChannelLiveScheduleRes = function (channel) {
    var _a, _b, _c, _d;
    var identity = (0, content_utils_1.buildContentIdentity)({ id: channel.id, slug: '', type: 'live_channel' });
    return {
        id: identity,
        slug: identity,
        title: channel.title,
        images: [
            {
                type: 'channel_wide_logo',
                url: (0, image_utils_1.getResizedUrl)('thumbnail', channel.images.logo),
            },
            {
                type: 'thumbnail',
                url: (0, image_utils_1.getResizedUrl)('thumbnail', ((_a = channel.images) === null || _a === void 0 ? void 0 : _a.poster_landscape) || channel.images.logo),
            },
            {
                type: 'backdrop',
                url: (0, image_utils_1.getResizedUrl)('thumbnail', ((_b = channel.images) === null || _b === void 0 ? void 0 : _b.poster_landscape) || channel.images.logo),
            },
            {
                type: 'poster',
                url: (0, image_utils_1.getResizedUrl)('poster', ((_c = channel.images) === null || _c === void 0 ? void 0 : _c.poster_portrait) || channel.images.logo),
            },
        ],
        epgs: (_d = channel.epg) === null || _d === void 0 ? void 0 : _d.map(exports.mapOdxEPGToEpgFeedRes),
        content_type: constants_1.ContentType.LIVE_CHANNEL,
        link_play: channel.stream_url,
        is_premium: false,
        restriction: 0,
    };
};
exports.mapOdxChannelsToChannelLiveScheduleRes = mapOdxChannelsToChannelLiveScheduleRes;
var mapOdxEPGToEpgFeedRes = function (epg) {
    var _a;
    return {
        id: (_a = epg.id) === null || _a === void 0 ? void 0 : _a.toString(),
        title: epg.title,
        start_at: new Date(epg.start_time).getTime() / 1000,
        end_at: new Date(epg.end_time).getTime() / 1000,
        real_duration: epg.duration,
        content_ref: epg.content_uuid,
        link_play: '',
        is_blackout: 0,
        description: epg.description,
        sub_categories: [],
        thumbnail_url: epg.thumbnail,
        title_image_url: '',
    };
};
exports.mapOdxEPGToEpgFeedRes = mapOdxEPGToEpgFeedRes;
var mapOdxChannelsToContentDetail = function (channel) {
    var identity = (0, content_utils_1.buildContentIdentity)({ id: channel.id, slug: '', type: 'live_channel' });
    return __assign(__assign({}, factory_1.defaultContentDetail), { type: constants_1.ContentType.LIVE_CHANNEL, id: identity, slug: identity, title: channel.title });
};
exports.mapOdxChannelsToContentDetail = mapOdxChannelsToContentDetail;
var mapOdxChannelsToContentViewInfo = function (channel) {
    var identity = (0, content_utils_1.buildContentIdentity)({ id: channel.id, slug: '', type: 'live_channel' });
    return __assign(__assign({}, factory_1.defaultContentViewInfo), { link_play: channel.stream_url, id: identity, slug: identity, title: channel.title });
};
exports.mapOdxChannelsToContentViewInfo = mapOdxChannelsToContentViewInfo;
var mapOdxEpisodeListToTrailer = function (episode) {
    var _a;
    var badgeSerializer = (0, content_utils_1.getBadgeSerializer)(episode.badges);
    var identity = (0, content_utils_1.buildContentIdentity)({
        id: episode.id,
        slug: episode.slug,
        type: episode.kind === "clip" ? 'clip' : 'trailer',
    });
    var type = constants_1.ContentType.TRAILER;
    if (episode.kind === 'clip') {
        type = constants_1.ContentType.CLIP;
    }
    return __assign(__assign({}, factory_1.defaultTrailer), { id: identity, slug: identity, type: type, title: episode.title, images: {
            thumbnail: (0, image_utils_1.getResizedUrl)('thumbnail', (_a = episode.images) === null || _a === void 0 ? void 0 : _a.thumbnail),
        }, is_premium: !['FREE', 'GUEST'].includes(episode.sales_type), link_play: '', runtime: Math.ceil((episode.video_duration || 0) / 1000 / 60), duration: Math.ceil((episode.video_duration || 0) / 1000), release_date: episode.release_date, badges: badgeSerializer.asVimaiBadges() });
};
exports.mapOdxEpisodeListToTrailer = mapOdxEpisodeListToTrailer;
var mapOdxEpisodeListToContentViewInfo = function (episode) {
    var type = (0, content_utils_1.getTypeIDFromEpisodeKind)(episode.kind);
    var identity = (0, content_utils_1.buildContentIdentity)({ id: episode.id, slug: episode.slug, type: type });
    return __assign(__assign({}, factory_1.defaultContentViewInfo), { id: identity, slug: identity, short_title: episode.title, title: episode.title, origin_title: episode.title });
};
exports.mapOdxEpisodeListToContentViewInfo = mapOdxEpisodeListToContentViewInfo;
var mapOdxEpisodeListToEpisode = function (episode) {
    var _a;
    var badgeSerializer = (0, content_utils_1.getBadgeSerializer)(episode.badges);
    var identity = (0, content_utils_1.buildContentIdentity)({
        id: episode.id,
        slug: episode.slug,
        type: 'episode',
    });
    var isPremium = badgeSerializer.isPremium || ['PREMIUM', 'PPV'].includes(episode.sales_type);
    return __assign(__assign({}, factory_1.defaultEpisode), { id: identity, slug: identity, title: episode.title, short_title: episode.title, episode: episode.number, origin_title: episode.title, long_description: episode.synopsis, release_date: episode.release_date, images: __assign(__assign({}, factory_1.defaultEpisode.images), { thumbnail: (0, image_utils_1.getResizedUrl)('thumbnail', (_a = episode.images) === null || _a === void 0 ? void 0 : _a.thumbnail) }), is_premium: isPremium, has_free_content: true, can_preview: badgeSerializer.is24hFree || !isPremium, runtime: Math.ceil((episode.video_duration || 0) / 1000 / 60), air_info: {
            air_status: constants_1.ContentAirStatus.AIR_STATUS_AIRED,
            playable_time: badgeSerializer.getPlayableTime(),
        }, language: episode.video_cc_languages, badges: badgeSerializer.asVimaiBadges() });
};
exports.mapOdxEpisodeListToEpisode = mapOdxEpisodeListToEpisode;
var mapOdxPlaybackTextTracksInnerToContentSubtitleResponse = function (textTrack) {
    return {
        id: textTrack.label,
        name: textTrack.label,
        language: textTrack.language === 'en' ? constants_1.LanguageCode.EN : constants_1.LanguageCode.KO,
        subtitle_url: textTrack.codec === 'srt' ? textTrack.url : null,
        subtitle_vtt_url: textTrack.codec === 'vtt' ? textTrack.url : null,
        language_code: textTrack.language,
        language_name: textTrack.label,
        subtitle_color: null,
        border_text_color: null,
        updated_date: null,
        created_date: null,
    };
};
exports.mapOdxPlaybackTextTracksInnerToContentSubtitleResponse = mapOdxPlaybackTextTracksInnerToContentSubtitleResponse;
var mapCarouselsForChannelToRibbonItem = function (item) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
    var identity = (0, content_utils_1.buildContentIdentity)({ id: (_a = item.action) === null || _a === void 0 ? void 0 : _a.channel_id, slug: '', type: 'live_channel' });
    var epg = null;
    if ((_c = (_b = item.view) === null || _b === void 0 ? void 0 : _b.channel) === null || _c === void 0 ? void 0 : _c.start_time) {
        epg = {
            id: (_e = (_d = item === null || item === void 0 ? void 0 : item.action) === null || _d === void 0 ? void 0 : _d.epg_id) === null || _e === void 0 ? void 0 : _e.toString(),
            title: (_f = item === null || item === void 0 ? void 0 : item.view) === null || _f === void 0 ? void 0 : _f.title,
            start_at: new Date((_h = (_g = item.view) === null || _g === void 0 ? void 0 : _g.channel) === null || _h === void 0 ? void 0 : _h.start_time).getTime() / 1000,
            end_at: new Date((_k = (_j = item.view) === null || _j === void 0 ? void 0 : _j.channel) === null || _k === void 0 ? void 0 : _k.end_time).getTime() / 1000,
            real_duration: (_m = (_l = item.view) === null || _l === void 0 ? void 0 : _l.channel) === null || _m === void 0 ? void 0 : _m.duration,
            content_ref: null,
            link_play: '',
            is_blackout: 0,
            thumbnail_url: (_p = (_o = item.view) === null || _o === void 0 ? void 0 : _o.image) === null || _p === void 0 ? void 0 : _p.url,
            title_image_url: '',
        };
    }
    var badgeSerializer = (0, content_utils_1.getBadgeSerializer)((_q = item === null || item === void 0 ? void 0 : item.view) === null || _q === void 0 ? void 0 : _q.badges);
    return __assign(__assign({}, factory_1.defaultRibbonItem), { id: identity, slug: identity, type: constants_1.ContentType.LIVE_CHANNEL, title: (_r = item.view) === null || _r === void 0 ? void 0 : _r.title, short_title: (_s = item.view) === null || _s === void 0 ? void 0 : _s.title, origin_title: (_t = item.view) === null || _t === void 0 ? void 0 : _t.title, images: {
            thumbnail: (0, image_utils_1.getResizedUrl)('thumbnail', (_v = (_u = item.view) === null || _u === void 0 ? void 0 : _u.image) === null || _v === void 0 ? void 0 : _v.url),
            poster: (0, image_utils_1.getResizedUrl)('poster', (_x = (_w = item.view) === null || _w === void 0 ? void 0 : _w.image) === null || _x === void 0 ? void 0 : _x.url),
            poster_banner: (0, image_utils_1.getResizedUrl)('poster', (_z = (_y = item.view) === null || _y === void 0 ? void 0 : _y.image) === null || _z === void 0 ? void 0 : _z.url),
            channel_logo: (0, image_utils_1.getResizedUrl)('thumbnail', (_1 = (_0 = item.view) === null || _0 === void 0 ? void 0 : _0.image) === null || _1 === void 0 ? void 0 : _1.url),
        }, live_info: {
            current_live_cursor: epg,
            next_content: null,
        }, link_play: (_2 = item === null || item === void 0 ? void 0 : item.action) === null || _2 === void 0 ? void 0 : _2.stream_url, top_index: badgeSerializer.topIndex, is_premium: badgeSerializer.isPremium, is_new_release: badgeSerializer.isNewRelease, restriction: badgeSerializer.restricted ? 1 : 0, has_free_content: !badgeSerializer.isPremium, payment_type: badgeSerializer.getPaymentType(), badges: badgeSerializer.asVimaiBadges() });
};
exports.mapCarouselsForChannelToRibbonItem = mapCarouselsForChannelToRibbonItem;
var mapOdxV1ContinuousWatchingHistoryToRibbonItem = function (item) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4;
    var isShowContent = ((_a = item.episode) === null || _a === void 0 ? void 0 : _a.kind) === 'series';
    var identity = isShowContent
        ? (0, content_utils_1.buildContentIdentity)({
            id: (_c = (_b = item.episode) === null || _b === void 0 ? void 0 : _b.program) === null || _c === void 0 ? void 0 : _c.id,
            slug: (_e = (_d = item.episode) === null || _d === void 0 ? void 0 : _d.program) === null || _e === void 0 ? void 0 : _e.slug,
            type: 'show',
        })
        : (0, content_utils_1.buildContentIdentity)({
            id: (_g = (_f = item.episode) === null || _f === void 0 ? void 0 : _f.program) === null || _g === void 0 ? void 0 : _g.id,
            slug: (_j = (_h = item.episode) === null || _h === void 0 ? void 0 : _h.program) === null || _j === void 0 ? void 0 : _j.slug,
            type: 'movie',
        });
    var defaultSeasonIdentity = (0, content_utils_1.buildContentIdentity)({
        id: (_l = (_k = item.episode) === null || _k === void 0 ? void 0 : _k.program) === null || _l === void 0 ? void 0 : _l.id,
        slug: (_o = (_m = item.episode) === null || _m === void 0 ? void 0 : _m.program) === null || _o === void 0 ? void 0 : _o.slug,
        type: 'season',
    });
    var defaultEpisodeIdentity = (0, content_utils_1.buildContentIdentity)({
        id: (_p = item.episode) === null || _p === void 0 ? void 0 : _p.id,
        slug: (_q = item.episode) === null || _q === void 0 ? void 0 : _q.slug,
        type: 'episode',
    });
    var defaultSeason;
    if (isShowContent) {
        defaultSeason = __assign(__assign({}, factory_1.defaultContentViewInfo.current_season), { id: defaultSeasonIdentity, total_episodes: null, total_released_episodes: null, season_index: 1, season_name: '', current_episode: {
                id: defaultEpisodeIdentity,
                slug: defaultEpisodeIdentity,
                progress: item.last_position,
                episode: ((_r = item.episode) === null || _r === void 0 ? void 0 : _r.number) || 1,
                episode_name: '',
                episode_index: ((_s = item.episode) === null || _s === void 0 ? void 0 : _s.number) || 1,
                runtime: Math.ceil((((_t = item.episode) === null || _t === void 0 ? void 0 : _t.video_duration) || 0) / 1000 / 60),
                duration: Math.ceil((((_u = item.episode) === null || _u === void 0 ? void 0 : _u.video_duration) || 0) / 1000),
            } });
    }
    else {
        defaultSeason = null;
    }
    var directPlayContent = null;
    if (isShowContent) {
        var directPlayIdentity = (0, content_utils_1.buildContentIdentity)({
            id: item.episode.id,
            slug: item.episode.slug,
            type: 'episode',
        });
        directPlayContent = {
            id: directPlayIdentity,
            slug: directPlayIdentity,
            type: constants_1.ContentType.EPISODE,
            title: (_v = item.episode) === null || _v === void 0 ? void 0 : _v.title,
        };
    }
    var badgeSerializer = (0, content_utils_1.getBadgeSerializer)((_w = item.episode) === null || _w === void 0 ? void 0 : _w.badges);
    var title = isShowContent ? (_x = item.episode) === null || _x === void 0 ? void 0 : _x.title : (_z = (_y = item.episode) === null || _y === void 0 ? void 0 : _y.program) === null || _z === void 0 ? void 0 : _z.title;
    return __assign(__assign({}, factory_1.defaultRibbonItem), { id: identity, slug: identity, type: isShowContent ? constants_1.ContentType.SHOW : constants_1.ContentType.MOVIE, title: title, short_title: title, origin_title: title, images: __assign(__assign({}, factory_1.defaultRibbonItem.images), { thumbnail: (0, image_utils_1.getResizedUrl)('thumbnail', (_2 = (_1 = (_0 = item.episode) === null || _0 === void 0 ? void 0 : _0.program) === null || _1 === void 0 ? void 0 : _1.images) === null || _2 === void 0 ? void 0 : _2.poster_landscape) }), top_index: badgeSerializer.topIndex, is_premium: badgeSerializer.isPremium, is_new_release: badgeSerializer.isNewRelease, restriction: badgeSerializer.restricted ? 1 : 0, has_free_content: !badgeSerializer.isPremium, payment_type: badgeSerializer.getPaymentType(), badges: badgeSerializer.asVimaiBadges(), current_season: defaultSeason, progress: item.last_position, runtime: Math.ceil((((_3 = item.episode) === null || _3 === void 0 ? void 0 : _3.video_duration) || 0) / 1000 / 60), duration: Math.ceil((((_4 = item.episode) === null || _4 === void 0 ? void 0 : _4.video_duration) || 0) / 1000), direct_play_content: directPlayContent });
};
exports.mapOdxV1ContinuousWatchingHistoryToRibbonItem = mapOdxV1ContinuousWatchingHistoryToRibbonItem;
var mapOdxClientBaseToCheckVersionResponse = function (clientBase) {
    var _a, _b, _c;
    // @ts-ignore
    var isBeta = ((_c = (_b = (_a = clientBase === null || clientBase === void 0 ? void 0 : clientBase.client_config) === null || _a === void 0 ? void 0 : _a.app_config) === null || _b === void 0 ? void 0 : _b.beta_version) === null || _c === void 0 ? void 0 : _c.value) === 'true';
    return {
        current_version: {
            config: {
                is_beta: isBeta,
            },
        },
        latest_version: null,
        has_new_version: false,
    };
};
exports.mapOdxClientBaseToCheckVersionResponse = mapOdxClientBaseToCheckVersionResponse;
var mapCWContinuousWatchingHistoryToInteractedContent = function (cw) {
    var contentId = (0, content_utils_1.buildContentIdentity)({
        id: cw.episode_id,
        type: 'episode',
        slug: '__truncated__',
    });
    return {
        content_id: contentId,
        progress: cw.last_position,
        liked: undefined,
        added_to_my_list: undefined,
        subtitle_id: undefined,
    };
};
exports.mapCWContinuousWatchingHistoryToInteractedContent = mapCWContinuousWatchingHistoryToInteractedContent;
var mapOdxPlaybackDRMToDRMSessionInfo = function (drmInfo) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    if (!((_a = drmInfo === null || drmInfo === void 0 ? void 0 : drmInfo.base) === null || _a === void 0 ? void 0 : _a.session_id)) {
        return null;
    }
    return {
        user_id: (_b = drmInfo === null || drmInfo === void 0 ? void 0 : drmInfo.base) === null || _b === void 0 ? void 0 : _b.user_id,
        operator_id: null,
        session: null,
        session_id: (_c = drmInfo === null || drmInfo === void 0 ? void 0 : drmInfo.base) === null || _c === void 0 ? void 0 : _c.session_id,
        fairplay_license_path: (_e = (_d = drmInfo === null || drmInfo === void 0 ? void 0 : drmInfo.authorization) === null || _d === void 0 ? void 0 : _d.fairplay) === null || _e === void 0 ? void 0 : _e.process_spc_url,
        fairplay_cert_url: (_g = (_f = drmInfo === null || drmInfo === void 0 ? void 0 : drmInfo.authorization) === null || _f === void 0 ? void 0 : _f.fairplay) === null || _g === void 0 ? void 0 : _g.certificate_url,
        widevide_license_path: (_j = (_h = drmInfo === null || drmInfo === void 0 ? void 0 : drmInfo.authorization) === null || _h === void 0 ? void 0 : _h.widevine) === null || _j === void 0 ? void 0 : _j.key_server_url,
        playready_license_path: (_l = (_k = drmInfo === null || drmInfo === void 0 ? void 0 : drmInfo.authorization) === null || _k === void 0 ? void 0 : _k.playready) === null || _l === void 0 ? void 0 : _l.key_server_url,
        merchant: (_m = drmInfo === null || drmInfo === void 0 ? void 0 : drmInfo.base) === null || _m === void 0 ? void 0 : _m.merchant,
        auth_token: null,
        asset_id: (_o = drmInfo === null || drmInfo === void 0 ? void 0 : drmInfo.base) === null || _o === void 0 ? void 0 : _o.asset_id,
    };
};
exports.mapOdxPlaybackDRMToDRMSessionInfo = mapOdxPlaybackDRMToDRMSessionInfo;
var mapOdxTVGuideProgramItemToRibbonDetail = function (tvGuideProgramItem) {
    var _a;
    var category = tvGuideProgramItem;
    var identity = (0, ribbon_utils_1.createRibbonSlugByForCategoryFilter)({
        type: 'tv-guide',
        categorySlug: category.slug,
    });
    return {
        id: identity,
        slug: identity,
        type: constants_1.RibbonType.THUMBNAIL,
        name: category.category_title,
        sub_name: category.category_title,
        display_type: constants_1.RibbonDisplayStyle.THUMBNAIL_WITH_TITLE,
        odr: 0,
        is_visible_in_ribbon_main_section: true,
        is_default_display: false,
        is_visible_in_side_navigation_section: false,
        is_visible_in_sub_navigation_bar: false,
        show_flag_odr: false,
        video_source: 0,
        items: (_a = category === null || category === void 0 ? void 0 : category.items) === null || _a === void 0 ? void 0 : _a.map(mapOdxTVGuideProgramItemItemsInnerToRibbonItem),
        ribbon_options: {
            require_secret: false,
            content_navigation_option: 'directly_to_player',
        },
        metadata: {
            source: category,
        },
    };
};
exports.mapOdxTVGuideProgramItemToRibbonDetail = mapOdxTVGuideProgramItemToRibbonDetail;
var mapTVGuideProgramToRibbonItem = function (i) {
    var _a, _b, _c, _d;
    var episode = i.episode;
    var badgeSerializer = (0, content_utils_1.getBadgeSerializer)(episode === null || episode === void 0 ? void 0 : episode.badges);
    var identity = (0, content_utils_1.buildContentIdentity)({ id: i.id, slug: i.slug, type: 'show' });
    var episodeIdentity = (0, content_utils_1.buildContentIdentity)({ id: episode === null || episode === void 0 ? void 0 : episode.id, slug: episode === null || episode === void 0 ? void 0 : episode.slug, type: 'episode' });
    // @ts-ignore
    var title = i.category_title || i.title;
    return __assign(__assign({}, factory_1.defaultRibbonItem), { id: identity, slug: identity, type: constants_1.ContentType.SHOW, title: title, short_title: title, origin_title: title, total_episodes: 1, images: {
            // @ts-ignore
            thumbnail: (0, image_utils_1.getResizedUrl)('thumbnail', ((_a = i.images) === null || _a === void 0 ? void 0 : _a.thumbnail) || ((_b = i.images) === null || _b === void 0 ? void 0 : _b.poster_landscape)),
            poster: (0, image_utils_1.getResizedUrl)('poster', (_c = i.images) === null || _c === void 0 ? void 0 : _c.poster_portrait),
            poster_banner: (0, image_utils_1.getResizedUrl)('poster', (_d = i.images) === null || _d === void 0 ? void 0 : _d.poster_landscape),
        }, direct_play_content: episode
            ? {
                type: constants_1.ContentType.EPISODE,
                id: episodeIdentity,
                slug: episodeIdentity,
                title: episode === null || episode === void 0 ? void 0 : episode.title,
            }
            : null, top_index: badgeSerializer.topIndex, is_premium: badgeSerializer.isPremium, is_new_release: badgeSerializer.isNewRelease, restriction: badgeSerializer.restricted ? 1 : 0, has_free_content: !badgeSerializer.isPremium, payment_type: badgeSerializer.getPaymentType(), badges: badgeSerializer.asVimaiBadges() });
};
var mapTVGuideChannelToRibbonItem = function (i) {
    var _a, _b, _c;
    var identity = (0, content_utils_1.buildContentIdentity)({ id: i.id, slug: '', type: 'live_channel' });
    var epg = null;
    if (i.epg && 'id' in i) {
        epg = (0, exports.mapOdxEPGToEpgFeedRes)(i.epg);
    }
    var badgeSerializer = (0, content_utils_1.getBadgeSerializer)(i === null || i === void 0 ? void 0 : i.badges);
    return __assign(__assign({}, factory_1.defaultRibbonItem), { id: identity, slug: identity, type: constants_1.ContentType.LIVE_CHANNEL, title: i.title, short_title: i.title, origin_title: i.title, images: {
            thumbnail: (0, image_utils_1.getResizedUrl)('thumbnail', (_a = i.images) === null || _a === void 0 ? void 0 : _a.poster_landscape),
            poster: (0, image_utils_1.getResizedUrl)('poster', (_b = i.images) === null || _b === void 0 ? void 0 : _b.poster_portrait),
            poster_banner: (0, image_utils_1.getResizedUrl)('poster', (_c = i.images) === null || _c === void 0 ? void 0 : _c.poster_portrait),
            channel_logo: (0, image_utils_1.getResizedUrl)('thumbnail', i.logo),
        }, live_info: {
            current_live_cursor: epg,
            next_content: null,
        }, top_index: badgeSerializer.topIndex, is_premium: badgeSerializer.isPremium, is_new_release: badgeSerializer.isNewRelease, restriction: badgeSerializer.restricted ? 1 : 0, has_free_content: !badgeSerializer.isPremium, payment_type: badgeSerializer.getPaymentType(), badges: badgeSerializer.asVimaiBadges() });
};
var mapOdxTVGuideProgramItemItemsInnerToRibbonItem = function (program) {
    if ('epg' in program) {
        return mapTVGuideChannelToRibbonItem(program);
    }
    else {
        return mapTVGuideProgramToRibbonItem(program);
    }
};
var mapOdxProfileAvatarToHybridProfileAvatar = function (avatar) {
    // Same model
    return avatar ? __assign({}, avatar) : null;
};
exports.mapOdxProfileAvatarToHybridProfileAvatar = mapOdxProfileAvatarToHybridProfileAvatar;
var mapOdxProfileToAccountHybridProfile = function (profile) {
    // Same model
    return profile ? __assign({}, profile) : null;
};
exports.mapOdxProfileToAccountHybridProfile = mapOdxProfileToAccountHybridProfile;
var mapOdxRecommendationProgramItemToRecommendationContent = function (cnt) {
    var identity = (0, content_utils_1.buildContentIdentity)({
        id: cnt.id,
        slug: cnt.slug,
        type: 'show',
    });
    var badgeSerializer = (0, content_utils_1.getBadgeSerializer)(cnt.badges);
    var recommendEpisode = cnt.recommendation_episode;
    var recommendEpisodeIdentity = (0, content_utils_1.buildContentIdentity)({
        id: recommendEpisode.id,
        slug: recommendEpisode.slug,
        type: 'episode',
    });
    var directPlayContent = {
        id: recommendEpisodeIdentity,
        slug: recommendEpisodeIdentity,
        title: recommendEpisode.title,
    };
    return {
        id: identity,
        slug: identity,
        title: cnt.title,
        images: {
            thumbnail: (0, image_utils_1.getResizedUrl)('thumbnail', cnt.image),
        },
        direct_play_content: directPlayContent,
        long_description: cnt.synopsis,
        top_index: badgeSerializer.topIndex,
        is_premium: badgeSerializer.isPremium,
        is_new_release: badgeSerializer.isNewRelease,
        restriction: badgeSerializer.restricted ? 1 : 0,
        has_free_content: !badgeSerializer.isPremium,
        payment_type: badgeSerializer.getPaymentType(),
        badges: badgeSerializer.asVimaiBadges(),
    };
};
exports.mapOdxRecommendationProgramItemToRecommendationContent = mapOdxRecommendationProgramItemToRecommendationContent;
var mapOdxLiveTvChannelsToChannelLiveScheduleRes = function (channel) {
    var _a, _b, _c, _d;
    var identity = (0, content_utils_1.buildContentIdentity)({ id: channel.id, slug: '', type: 'live_channel' });
    return {
        id: identity,
        slug: identity,
        title: channel.title,
        number: channel.number,
        images: [
            {
                type: 'channel_wide_logo',
                url: (0, image_utils_1.getResizedUrl)('thumbnail', channel.images.logo),
            },
            {
                type: 'thumbnail',
                url: (0, image_utils_1.getResizedUrl)('thumbnail', (_a = channel.images) === null || _a === void 0 ? void 0 : _a.poster_landscape),
            },
            {
                type: 'backdrop',
                url: (0, image_utils_1.getResizedUrl)('backdrop', (_b = channel.images) === null || _b === void 0 ? void 0 : _b.poster_landscape),
            },
            {
                type: 'poster',
                url: (0, image_utils_1.getResizedUrl)('poster', (_c = channel.images) === null || _c === void 0 ? void 0 : _c.poster_portrait),
            },
        ],
        epgs: (_d = channel.epgs) === null || _d === void 0 ? void 0 : _d.map(exports.mapOdxEPGToEpgFeedRes),
        content_type: constants_1.ContentType.LIVE_CHANNEL,
        link_play: channel.stream_url,
        is_premium: false,
        restriction: 0,
    };
};
exports.mapOdxLiveTvChannelsToChannelLiveScheduleRes = mapOdxLiveTvChannelsToChannelLiveScheduleRes;
var mapOdxLiveTvToRibbonLiveSchedulesRes = function (liveTv) {
    var _a;
    return {
        id: constants_1.ODXKey.defaultUnknown,
        name: liveTv.title,
        slug: liveTv.slug,
        icon_url: null,
        channels: (_a = liveTv.channels) === null || _a === void 0 ? void 0 : _a.map(exports.mapOdxLiveTvChannelsToChannelLiveScheduleRes),
    };
};
exports.mapOdxLiveTvToRibbonLiveSchedulesRes = mapOdxLiveTvToRibbonLiveSchedulesRes;
var mapOdxLiveTvToPageLiveShedulesRes = function (liveTv) {
    return {
        ribbons: liveTv.map(exports.mapOdxLiveTvToRibbonLiveSchedulesRes),
    };
};
exports.mapOdxLiveTvToPageLiveShedulesRes = mapOdxLiveTvToPageLiveShedulesRes;
