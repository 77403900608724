import { store } from '~store';
import { UserContentApiInterface, ContentApiInterface } from '~vimai-api-adapter';
import { UserContentReviewsRequest } from '~vimai-api-adapter/api-adapters/api-adapter-vimai/models';
import { apiAdapter, config, axiosTakeDataFromResponse } from '~share';
import { IAxiosResponseData, Pagination } from '~core/models';

const { userContentService, contentService } = apiAdapter;

export const createReview = (params: {
  content_id: string;
  rating: number;
  comment: string;
}): Promise<IAxiosResponseData<UserContentApiInterface['createReviewOfContent']> | void> => {
  const { account } = (store.getState() as any).auth;
  if (!account) {
    return Promise.resolve();
  }

  const { tenantSlug = '', authorization, acceptLanguage } = config;

  const { content_id, rating, comment } = params;

  return userContentService
    .createReviewOfContent(
      content_id,
      tenantSlug,
      {
        rating,
        comment,
      },
      authorization,
      acceptLanguage,
    )
    .then(axiosTakeDataFromResponse);
};

export const getProgramShow = (
  idContent: string,
): Promise<IAxiosResponseData<ContentApiInterface['getParentProgramOfContent']>> => {
  const select = JSON.stringify({
    Content: ['id'],
  });
  const {
    tenantSlug = '',
    authorization,
    imageResolutionScale,
    imageFormat,
    acceptLanguage,
  } = config;
  return contentService
    .getParentProgramOfContent(
      idContent,
      tenantSlug,
      authorization,
      acceptLanguage,
      select,
      imageResolutionScale,
      imageFormat,
    )
    .then(axiosTakeDataFromResponse);
};

export const getReviews = (
  params: { content_id: string } & Pagination,
): Promise<IAxiosResponseData<ContentApiInterface['getContentUserReviews']>> => {
  const { content_id, page, limit } = params;
  const { tenantSlug = '', authorization, acceptLanguage } = config;
  return contentService
    .getContentUserReviews(content_id, tenantSlug, authorization, acceptLanguage, page, limit)
    .then(axiosTakeDataFromResponse);
};

export function updateReview(
  params: {
    id: string;
    review_id: string;
  } & UserContentReviewsRequest,
): Promise<IAxiosResponseData<ContentApiInterface['updateContentReviewDetail']>> {
  const { id, review_id, rating, comment } = params;
  const data: UserContentReviewsRequest = { rating, comment };
  const { tenantSlug = '', authorization, acceptLanguage } = config;
  return contentService
    .updateContentReviewDetail(id || review_id, tenantSlug, data, authorization, acceptLanguage)
    .then(axiosTakeDataFromResponse);
}
