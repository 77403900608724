Object.defineProperty(exports, "__esModule", { value: true });
exports.ParseProfileLoginInfo = exports.CombineTokenAndProfileToAccount = exports.CombineQrCheckAndProfileToLoginInfo = exports.ParseOdxSignInResultToLoginInfo = exports.ParseOdxQrInitializeToLoginSession = void 0;
var utils_1 = require("../utils");
var constants_1 = require("../constants");
var mappers_1 = require("../mappers");
var ParseOdxQrInitializeToLoginSession = function (response) {
    var verify_code = response.verify_code, redirect_url = response.redirect_url, qrcode = response.qrcode;
    var guide_url = redirect_url;
    var scan_qr = redirect_url;
    if (scan_qr.includes('?')) {
        scan_qr += "&verify_code=".concat(verify_code);
    }
    else {
        scan_qr += "?verify_code=".concat(verify_code);
    }
    return {
        code: verify_code,
        login_url: guide_url,
        qr_content: !qrcode ? scan_qr : qrcode,
        expired: '300',
    };
};
exports.ParseOdxQrInitializeToLoginSession = ParseOdxQrInitializeToLoginSession;
var _mapUserProfile = function (user) {
    if (!user) {
        return null;
    }
    var userInfo = user === null || user === void 0 ? void 0 : user.userinfo;
    var loginMethod = user === null || user === void 0 ? void 0 : user.login_type;
    var hasSubscription = (user === null || user === void 0 ? void 0 : user.subscription_type) && (user === null || user === void 0 ? void 0 : user.subscription_type) !== 'basic';
    var subscriptionUnit = ['month', 'year'].includes(user === null || user === void 0 ? void 0 : user.subscription_interval_unit)
        ? user.subscription_interval_unit
        : null;
    var getSubscriptionIcon = function (subscriptionType) {
        switch (subscriptionType) {
            case 'plus':
                return constants_1.SubscriptionIcon.PLUS;
            case 'premium':
                return constants_1.SubscriptionIcon.PREMIUM;
            case 'premium-box':
                return constants_1.SubscriptionIcon.BOX_PREMIUM;
            default:
                return constants_1.SubscriptionIcon.BASIC;
        }
    };
    return {
        id: (user === null || user === void 0 ? void 0 : user.id.toString()) || 'unknown-user-id',
        email: user === null || user === void 0 ? void 0 : user.email,
        email_confirmed: user === null || user === void 0 ? void 0 : user.is_email_verified,
        origin_email: user === null || user === void 0 ? void 0 : user.email,
        account_ref_code: user === null || user === void 0 ? void 0 : user.id.toString(),
        first_name: user === null || user === void 0 ? void 0 : user.name,
        linked_login_methods: [loginMethod],
        last_name: '',
        dob: userInfo === null || userInfo === void 0 ? void 0 : userInfo.birthday,
        gender: (0, utils_1.getGenderNumber)(userInfo === null || userInfo === void 0 ? void 0 : userInfo.gender),
        current_payment_provider: user === null || user === void 0 ? void 0 : user.subscription_provider,
        has_subscription: hasSubscription || false,
        subscription_plan_info: hasSubscription && {
            id: 'unknown',
            slug: 'unknown',
            subscription_type: user === null || user === void 0 ? void 0 : user.subscription_type,
            trial_end: user === null || user === void 0 ? void 0 : user.subscription_trial_end_date,
            expired_time: user === null || user === void 0 ? void 0 : user.subscription_expire_date,
            subscription_from: user === null || user === void 0 ? void 0 : user.subscription_provider,
            unit: subscriptionUnit,
        },
        subscription_metadata: {
            subscription_icon: getSubscriptionIcon(user.subscription_type),
        },
        metadata: {
            source: user,
        },
        multi_profile: {
            enabled: (user === null || user === void 0 ? void 0 : user.subscription_max_profile_count) > 0,
            max_profile_count: user.subscription_max_profile_count,
        },
    };
};
var _mapLoginInfo = function (token, user) {
    var loginMethod = user === null || user === void 0 ? void 0 : user.login_type;
    return {
        access_token: "ODX ".concat(token),
        refresh_token: '',
        expiry: 999999,
        profile: _mapUserProfile(user),
        login_method: loginMethod,
    };
};
var ParseOdxSignInResultToLoginInfo = function (response) {
    return _mapLoginInfo(response.token, response.user);
};
exports.ParseOdxSignInResultToLoginInfo = ParseOdxSignInResultToLoginInfo;
var CombineQrCheckAndProfileToLoginInfo = function (sessionResponse, profileResponse) {
    return _mapLoginInfo(sessionResponse.token, profileResponse);
};
exports.CombineQrCheckAndProfileToLoginInfo = CombineQrCheckAndProfileToLoginInfo;
var CombineTokenAndProfileToAccount = function (profileResponse) {
    return _mapUserProfile(profileResponse);
};
exports.CombineTokenAndProfileToAccount = CombineTokenAndProfileToAccount;
var ParseProfileLoginInfo = function (response) {
    return {
        token: "ODX ".concat(response.token),
        profile: (0, mappers_1.mapOdxProfileToAccountHybridProfile)(response.profile),
    };
};
exports.ParseProfileLoginInfo = ParseProfileLoginInfo;
