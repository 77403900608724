import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const GroupSocial = styled.div`
  display: flex;
  border: 1px solid #000000;
  width: 100%;
  height: 55px;
  padding: 16px 0;
  color: #000000;
  border-radius: 0;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin-bottom: 17px;
  &:hover,
  &:focus {
    cursor: pointer;
    background: #fff;
    color: ${({ theme }) => theme.primaryColor};
  }
  i {
    display: flex;
    padding: 14.5px 15px;
    width: 20%;
    svg,
    path {
      width: 27px;
      height: 30px;
    }
  }
  @media (max-width: 768px) {
    svg {
      width: 27px;
      /* height: 35px; */
    }
  }
`;

export const ButtonW = styled(Button)`
  && {
    font-size: 16px;
    font-family: var(--font);
    font-weight: 400;
    width: 80%;
    /* padding-right: 80px; */
    color: #000000;
    border: 0;
    text-align: center;
    &:hover,
    &:active:focus,
    &:focus-visible,
    &:active,
    &:focus {
      background: #fff;
      outline: none;
      box-shadow: unset;
    }
  }
`;
