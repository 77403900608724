import { createSlice, current, original, PayloadAction } from '@reduxjs/toolkit';
import { TVODPrice } from '~vimai-api-adapter/api-adapters/api-adapter-vimai/models';
import { ANONYMOUS_CHAT_USER } from '~core/constants';

export interface IGlobal {
  documents: {
    termsAndConditions: Document | {};
    privacyAndPolicy: Document | {};
  };
  menu: {
    isHidden: boolean;
  };
  page: {
    isHomePage: boolean;
    pageSlug: string;
    contentNavigation: string;
    namePage: string;
    SEO: string;
  };
  modal: {
    isWelcome: boolean;
  };
  userAction: {
    watchNowClick: boolean;
  };
  ActionLogin: {
    isShowLoginModal: boolean;
  };
  modalForgotPassword: {
    isShowModal_Forgot: boolean;
  };
  formSignIn: {
    isOpenSignInForm: boolean;
  };
  modalPaymentSuccess: {
    isSuccess: boolean;
  };
  statusRefund: {
    isPending: boolean;
  };
  chatLive: {
    open: boolean;
    nickname: string;
  };
  showTrailer: {
    isShowTrailer: boolean;
  };
  liveEventStatus: string;
  isShowVerifyEmailModal: boolean;
  isShowVerifyEmailPPV: boolean;
  isVerifiedEmail: boolean;
  newNamePage: string;
  isPageNotFound: boolean;
  pricesView: TVODPrice[];
  isContentUnpulish: boolean;
  openModalPPV: boolean;
  isHomePageClient: boolean;
  isLoadingConfirmPPV: boolean;
}

const initialState: IGlobal = {
  documents: {
    termsAndConditions: {},
    privacyAndPolicy: {},
  },
  menu: {
    isHidden: false,
  },
  page: {
    isHomePage: false,
    pageSlug: '',
    contentNavigation: 'default',
    namePage: 'WYS EN SCÈNE',
    SEO: '',
  },
  modal: {
    isWelcome: false,
  },
  userAction: {
    watchNowClick: false,
  },
  ActionLogin: {
    isShowLoginModal: true,
  },
  modalForgotPassword: {
    isShowModal_Forgot: false,
  },
  formSignIn: {
    isOpenSignInForm: true,
  },
  modalPaymentSuccess: {
    isSuccess: false,
  },
  statusRefund: {
    isPending: false,
  },
  chatLive: {
    open: true,
    nickname: '',
  },
  showTrailer: {
    isShowTrailer: false,
  },
  liveEventStatus: '',
  isShowVerifyEmailModal: false,
  isShowVerifyEmailPPV: false,
  isVerifiedEmail: false,
  newNamePage: '',
  isPageNotFound: false,
  pricesView: [],
  isContentUnpulish: false,
  openModalPPV: false,
  isHomePageClient: false,
  isLoadingConfirmPPV: false,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setTermsAndConditions(
      state,
      action: PayloadAction<IGlobal['documents']['termsAndConditions']>,
    ) {
      state.documents.termsAndConditions = action.payload;
    },
    setPrivacyAndPolicy(state, action: PayloadAction<IGlobal['documents']['privacyAndPolicy']>) {
      state.documents.privacyAndPolicy = action.payload;
    },
    hideMenu(state) {
      state.menu.isHidden = true;
    },
    showMenu(state) {
      state.menu.isHidden = false;
    },
    setIsHomePage(state, action: PayloadAction<IGlobal['page']['isHomePage']>) {
      state.page.isHomePage = action.payload;
    },
    setIsWelcome(state, action: PayloadAction<IGlobal['modal']['isWelcome']>) {
      state.modal.isWelcome = action.payload;
    },
    setWatchNowClick(state, action: PayloadAction<IGlobal['userAction']['watchNowClick']>) {
      state.userAction.watchNowClick = action.payload;
    },
    setTargerPlayer(state, action: PayloadAction<IGlobal['page']['contentNavigation']>) {
      state.page.contentNavigation = action.payload;
    },
    setNamePage(state, action: PayloadAction<IGlobal['page']['namePage']>) {
      if (current(state).page.namePage !== action.payload) {
        state.page.namePage = action.payload;
      } else {
        return original(state);
      }
    },
    setLoginModalShow(state, action: PayloadAction<IGlobal['ActionLogin']['isShowLoginModal']>) {
      state.ActionLogin.isShowLoginModal = action.payload;
    },
    setIsShowModal_Forgot(
      state,
      action: PayloadAction<IGlobal['modalForgotPassword']['isShowModal_Forgot']>,
    ) {
      state.modalForgotPassword.isShowModal_Forgot = action.payload;
    },
    setIsShowSignInForm(state, action: PayloadAction<IGlobal['formSignIn']['isOpenSignInForm']>) {
      state.formSignIn.isOpenSignInForm = action.payload;
    },
    setIsSuccess(state, action: PayloadAction<IGlobal['modalPaymentSuccess']['isSuccess']>) {
      state.modalPaymentSuccess.isSuccess = action.payload;
    },
    setStatusRefund(state, action: PayloadAction<IGlobal['statusRefund']['isPending']>) {
      state.statusRefund.isPending = action.payload;
    },
    toggleChatLive(state, action: PayloadAction<IGlobal['chatLive']['open']>) {
      const value = action.payload;
      state.chatLive.open = typeof value === 'boolean' ? value : !state.chatLive.open;
    },
    setIsShowTrailer(state, action: PayloadAction<IGlobal['showTrailer']['isShowTrailer']>) {
      state.showTrailer.isShowTrailer = action.payload;
    },
    setLiveEventStatus(state, action: PayloadAction<IGlobal['liveEventStatus']>) {
      state.liveEventStatus = action.payload;
    },
    setIsShowVerifyEmailModal(state, action: PayloadAction<IGlobal['isShowVerifyEmailModal']>) {
      state.isShowVerifyEmailModal = action.payload;
    },
    setIsShowVerifyEmailPPV(state, action: PayloadAction<IGlobal['isShowVerifyEmailPPV']>) {
      state.isShowVerifyEmailPPV = action.payload;
    },
    setCheckVerifiedEmail(state, action: PayloadAction<IGlobal['isVerifiedEmail']>) {
      state.isVerifiedEmail = action.payload;
    },
    setNewNamePage(state, action: PayloadAction<IGlobal['newNamePage']>) {
      state.newNamePage = action.payload;
    },
    setNicknameChatLive(state, action: PayloadAction<IGlobal['chatLive']['nickname']>) {
      state.chatLive.nickname =
        action.payload.toLowerCase() === ANONYMOUS_CHAT_USER ? '' : action.payload;
    },
    setIsPageNotFound(state, action: PayloadAction<IGlobal['isPageNotFound']>) {
      state.isPageNotFound = action.payload;
    },
    setPricesView(state, action: PayloadAction<IGlobal['pricesView']>) {
      state.pricesView = action.payload;
    },
    setIsContentUnpublish(state, action: PayloadAction<IGlobal['isContentUnpulish']>) {
      state.isContentUnpulish = action.payload;
    },
    setOpenModalPPV(state, action: PayloadAction<IGlobal['openModalPPV']>) {
      state.openModalPPV = action.payload;
    },
    setIsHomePageClient(state, action: PayloadAction<IGlobal['isHomePageClient']>) {
      state.isHomePageClient = action.payload;
    },
    setIsLoadingConfirmPPV(state, action: PayloadAction<IGlobal['isLoadingConfirmPPV']>) {
      state.isLoadingConfirmPPV = action.payload;
    },
  },
});

export const {
  setTermsAndConditions,
  setPrivacyAndPolicy,
  hideMenu,
  showMenu,
  setIsHomePage,
  setIsWelcome,
  setWatchNowClick,
  setTargerPlayer,
  setNamePage,
  setLoginModalShow,
  setIsShowModal_Forgot,
  setIsShowSignInForm,
  setIsSuccess,
  setStatusRefund,
  toggleChatLive,
  setIsShowTrailer,
  setLiveEventStatus,
  setIsShowVerifyEmailModal,
  setIsShowVerifyEmailPPV,
  setCheckVerifiedEmail,
  setNewNamePage,
  setNicknameChatLive,
  setIsPageNotFound,
  setPricesView,
  setIsContentUnpublish,
  setOpenModalPPV,
  setIsHomePageClient,
  setIsLoadingConfirmPPV,
} = globalSlice.actions;

export default globalSlice.reducer;
