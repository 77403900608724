/* tslint:disable */
/* eslint-disable */
/**
 * Vimai Core Service UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminDocumentApi = exports.AdminDocumentApiFactory = exports.AdminDocumentApiFp = exports.AdminDocumentApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * AdminDocumentApi - axios parameter creator
 * @export
 */
var AdminDocumentApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Create a document
         * @param {string} tenantId
         * @param {Document} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument: function (tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('createDocument', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createDocument', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/documents/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create a question
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {FAQQnAResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaqQuestion: function (tenantSlug, topicId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createFaqQuestion', 'tenantSlug', tenantSlug);
                            // verify required parameter 'topicId' is not null or undefined
                            (0, common_1.assertParamExists)('createFaqQuestion', 'topicId', topicId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createFaqQuestion', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/faq_topics/{topic_id}/questions/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)))
                                .replace("{".concat("topic_id", "}"), encodeURIComponent(String(topicId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create a faq topic
         * @param {string} tenantSlug
         * @param {FAQTopicResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaqTopic: function (tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('createFaqTopic', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('createFaqTopic', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/faq_topics/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a document
         * @param {string} id
         * @param {string} tenantId
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: function (id, tenantId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('deleteDocument', 'id', id);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteDocument', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/documents/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a question
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFaqQuestion: function (id, tenantSlug, topicId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('deleteFaqQuestion', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('deleteFaqQuestion', 'tenantSlug', tenantSlug);
                            // verify required parameter 'topicId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteFaqQuestion', 'topicId', topicId);
                            localVarPath = "/tenants/{tenant_slug}/faq_topics/{topic_id}/questions/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)))
                                .replace("{".concat("topic_id", "}"), encodeURIComponent(String(topicId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a faq topic
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFaqTopic: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('deleteFaqTopic', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('deleteFaqTopic', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/faq_topics/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get a document
         * @param {string} id
         * @param {string} tenantId
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: function (id, tenantId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getDocument', 'id', id);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getDocument', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/documents/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {string} tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTypeById: function (id, tenantId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getDocumentTypeById', 'id', id);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getDocumentTypeById', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/document_types/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTypes: function (tenantId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getDocumentTypes', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/document_types/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get a question
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqQuestion: function (id, tenantSlug, topicId, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getFaqQuestion', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getFaqQuestion', 'tenantSlug', tenantSlug);
                            // verify required parameter 'topicId' is not null or undefined
                            (0, common_1.assertParamExists)('getFaqQuestion', 'topicId', topicId);
                            localVarPath = "/tenants/{tenant_slug}/faq_topics/{topic_id}/questions/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)))
                                .replace("{".concat("topic_id", "}"), encodeURIComponent(String(topicId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get all question
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqQuestions: function (tenantSlug, topicId, search, page, pageSize, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getFaqQuestions', 'tenantSlug', tenantSlug);
                            // verify required parameter 'topicId' is not null or undefined
                            (0, common_1.assertParamExists)('getFaqQuestions', 'topicId', topicId);
                            localVarPath = "/tenants/{tenant_slug}/faq_topics/{topic_id}/questions/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)))
                                .replace("{".concat("topic_id", "}"), encodeURIComponent(String(topicId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get a faq topic
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqTopic: function (id, tenantSlug, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('getFaqTopic', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getFaqTopic', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/faq_topics/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get all faq topic
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqTopics: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getFaqTopics', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/faq_topics/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get all document
         * @param {string} tenantId
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListDocument: function (tenantId, search, page, pageSize, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('getListDocument', 'tenantId', tenantId);
                            localVarPath = "/tenants/{tenant_id}/documents/"
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (search !== undefined) {
                                localVarQueryParameter['search'] = search;
                            }
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (pageSize !== undefined) {
                                localVarQueryParameter['page_size'] = pageSize;
                            }
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get platform faq
         * @summary Get platform faq
         * @param {string} platformSlug
         * @param {string} tenantSlug
         * @param {boolean} [adsBlock] Get specify QnA resolve ads block
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformQnas: function (platformSlug, tenantSlug, adsBlock, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'platformSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPlatformQnas', 'platformSlug', platformSlug);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('getPlatformQnas', 'tenantSlug', tenantSlug);
                            localVarPath = "/tenants/{tenant_slug}/tenant_platforms/{platform_slug}/qnas/"
                                .replace("{".concat("platform_slug", "}"), encodeURIComponent(String(platformSlug)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (adsBlock !== undefined) {
                                localVarQueryParameter['ads_block'] = adsBlock;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Sort list questions
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {FAQQnAResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortFaqQuestions: function (tenantSlug, topicId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('sortFaqQuestions', 'tenantSlug', tenantSlug);
                            // verify required parameter 'topicId' is not null or undefined
                            (0, common_1.assertParamExists)('sortFaqQuestions', 'topicId', topicId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('sortFaqQuestions', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/faq_topics/{topic_id}/questions/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)))
                                .replace("{".concat("topic_id", "}"), encodeURIComponent(String(topicId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Sort list faq
         * @param {string} tenantSlug
         * @param {FAQTopicResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortFaqTopic: function (tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('sortFaqTopic', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('sortFaqTopic', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/faq_topics/"
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a document
         * @param {string} id
         * @param {string} tenantId
         * @param {Document} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument: function (id, tenantId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateDocument', 'id', id);
                            // verify required parameter 'tenantId' is not null or undefined
                            (0, common_1.assertParamExists)('updateDocument', 'tenantId', tenantId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateDocument', 'data', data);
                            localVarPath = "/tenants/{tenant_id}/documents/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_id", "}"), encodeURIComponent(String(tenantId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a question
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {FAQQnAResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaqQuestion: function (id, tenantSlug, topicId, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateFaqQuestion', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateFaqQuestion', 'tenantSlug', tenantSlug);
                            // verify required parameter 'topicId' is not null or undefined
                            (0, common_1.assertParamExists)('updateFaqQuestion', 'topicId', topicId);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateFaqQuestion', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/faq_topics/{topic_id}/questions/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)))
                                .replace("{".concat("topic_id", "}"), encodeURIComponent(String(topicId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a faq topic
         * @param {string} id
         * @param {string} tenantSlug
         * @param {FAQTopicResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaqTopic: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('updateFaqTopic', 'id', id);
                            // verify required parameter 'tenantSlug' is not null or undefined
                            (0, common_1.assertParamExists)('updateFaqTopic', 'tenantSlug', tenantSlug);
                            // verify required parameter 'data' is not null or undefined
                            (0, common_1.assertParamExists)('updateFaqTopic', 'data', data);
                            localVarPath = "/tenants/{tenant_slug}/faq_topics/{id}/"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("tenant_slug", "}"), encodeURIComponent(String(tenantSlug)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication AccessToken required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration)];
                        case 1:
                            // authentication AccessToken required
                            _a.sent();
                            if (authorization !== undefined && authorization !== null) {
                                localVarHeaderParameter['Authorization'] = String(authorization);
                            }
                            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(data, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AdminDocumentApiAxiosParamCreator = AdminDocumentApiAxiosParamCreator;
/**
 * AdminDocumentApi - functional programming interface
 * @export
 */
var AdminDocumentApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AdminDocumentApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Create a document
         * @param {string} tenantId
         * @param {Document} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument: function (tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createDocument(tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Create a question
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {FAQQnAResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaqQuestion: function (tenantSlug, topicId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createFaqQuestion(tenantSlug, topicId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Create a faq topic
         * @param {string} tenantSlug
         * @param {FAQTopicResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaqTopic: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createFaqTopic(tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a document
         * @param {string} id
         * @param {string} tenantId
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: function (id, tenantId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteDocument(id, tenantId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a question
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFaqQuestion: function (id, tenantSlug, topicId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteFaqQuestion(id, tenantSlug, topicId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a faq topic
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFaqTopic: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteFaqTopic(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get a document
         * @param {string} id
         * @param {string} tenantId
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: function (id, tenantId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getDocument(id, tenantId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {string} tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTypeById: function (id, tenantId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getDocumentTypeById(id, tenantId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTypes: function (tenantId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getDocumentTypes(tenantId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get a question
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqQuestion: function (id, tenantSlug, topicId, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFaqQuestion(id, tenantSlug, topicId, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get all question
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqQuestions: function (tenantSlug, topicId, search, page, pageSize, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFaqQuestions(tenantSlug, topicId, search, page, pageSize, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get a faq topic
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqTopic: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFaqTopic(id, tenantSlug, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get all faq topic
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqTopics: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFaqTopics(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get all document
         * @param {string} tenantId
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListDocument: function (tenantId, search, page, pageSize, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getListDocument(tenantId, search, page, pageSize, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get platform faq
         * @summary Get platform faq
         * @param {string} platformSlug
         * @param {string} tenantSlug
         * @param {boolean} [adsBlock] Get specify QnA resolve ads block
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformQnas: function (platformSlug, tenantSlug, adsBlock, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPlatformQnas(platformSlug, tenantSlug, adsBlock, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Sort list questions
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {FAQQnAResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortFaqQuestions: function (tenantSlug, topicId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sortFaqQuestions(tenantSlug, topicId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Sort list faq
         * @param {string} tenantSlug
         * @param {FAQTopicResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortFaqTopic: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sortFaqTopic(tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a document
         * @param {string} id
         * @param {string} tenantId
         * @param {Document} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument: function (id, tenantId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateDocument(id, tenantId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a question
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {FAQQnAResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaqQuestion: function (id, tenantSlug, topicId, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateFaqQuestion(id, tenantSlug, topicId, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a faq topic
         * @param {string} id
         * @param {string} tenantSlug
         * @param {FAQTopicResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaqTopic: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateFaqTopic(id, tenantSlug, data, authorization, acceptLanguage, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.AdminDocumentApiFp = AdminDocumentApiFp;
/**
 * AdminDocumentApi - factory interface
 * @export
 */
var AdminDocumentApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AdminDocumentApiFp)(configuration);
    return {
        /**
         *
         * @summary Create a document
         * @param {string} tenantId
         * @param {Document} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument: function (tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.createDocument(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Create a question
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {FAQQnAResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaqQuestion: function (tenantSlug, topicId, data, authorization, acceptLanguage, options) {
            return localVarFp.createFaqQuestion(tenantSlug, topicId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Create a faq topic
         * @param {string} tenantSlug
         * @param {FAQTopicResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaqTopic: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.createFaqTopic(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete a document
         * @param {string} id
         * @param {string} tenantId
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: function (id, tenantId, authorization, acceptLanguage, options) {
            return localVarFp.deleteDocument(id, tenantId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete a question
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFaqQuestion: function (id, tenantSlug, topicId, authorization, acceptLanguage, options) {
            return localVarFp.deleteFaqQuestion(id, tenantSlug, topicId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete a faq topic
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFaqTopic: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.deleteFaqTopic(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get a document
         * @param {string} id
         * @param {string} tenantId
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: function (id, tenantId, authorization, acceptLanguage, options) {
            return localVarFp.getDocument(id, tenantId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {string} tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTypeById: function (id, tenantId, options) {
            return localVarFp.getDocumentTypeById(id, tenantId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} tenantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTypes: function (tenantId, options) {
            return localVarFp.getDocumentTypes(tenantId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get a question
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqQuestion: function (id, tenantSlug, topicId, authorization, acceptLanguage, options) {
            return localVarFp.getFaqQuestion(id, tenantSlug, topicId, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get all question
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqQuestions: function (tenantSlug, topicId, search, page, pageSize, authorization, acceptLanguage, options) {
            return localVarFp.getFaqQuestions(tenantSlug, topicId, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get a faq topic
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqTopic: function (id, tenantSlug, authorization, acceptLanguage, options) {
            return localVarFp.getFaqTopic(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get all faq topic
         * @param {string} tenantSlug
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqTopics: function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
            return localVarFp.getFaqTopics(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get all document
         * @param {string} tenantId
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListDocument: function (tenantId, search, page, pageSize, authorization, acceptLanguage, options) {
            return localVarFp.getListDocument(tenantId, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get platform faq
         * @summary Get platform faq
         * @param {string} platformSlug
         * @param {string} tenantSlug
         * @param {boolean} [adsBlock] Get specify QnA resolve ads block
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformQnas: function (platformSlug, tenantSlug, adsBlock, options) {
            return localVarFp.getPlatformQnas(platformSlug, tenantSlug, adsBlock, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Sort list questions
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {FAQQnAResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortFaqQuestions: function (tenantSlug, topicId, data, authorization, acceptLanguage, options) {
            return localVarFp.sortFaqQuestions(tenantSlug, topicId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Sort list faq
         * @param {string} tenantSlug
         * @param {FAQTopicResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortFaqTopic: function (tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.sortFaqTopic(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update a document
         * @param {string} id
         * @param {string} tenantId
         * @param {Document} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument: function (id, tenantId, data, authorization, acceptLanguage, options) {
            return localVarFp.updateDocument(id, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update a question
         * @param {string} id
         * @param {string} tenantSlug
         * @param {string} topicId
         * @param {FAQQnAResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaqQuestion: function (id, tenantSlug, topicId, data, authorization, acceptLanguage, options) {
            return localVarFp.updateFaqQuestion(id, tenantSlug, topicId, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update a faq topic
         * @param {string} id
         * @param {string} tenantSlug
         * @param {FAQTopicResource} data
         * @param {string} [authorization] user access token for future use
         * @param {string} [acceptLanguage] For response message in difference language en/vi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaqTopic: function (id, tenantSlug, data, authorization, acceptLanguage, options) {
            return localVarFp.updateFaqTopic(id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AdminDocumentApiFactory = AdminDocumentApiFactory;
/**
 * AdminDocumentApi - object-oriented interface
 * @export
 * @class AdminDocumentApi
 * @extends {BaseAPI}
 */
var AdminDocumentApi = /** @class */ (function (_super) {
    __extends(AdminDocumentApi, _super);
    function AdminDocumentApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create a document
     * @param {string} tenantId
     * @param {Document} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.createDocument = function (tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).createDocument(tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Create a question
     * @param {string} tenantSlug
     * @param {string} topicId
     * @param {FAQQnAResource} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.createFaqQuestion = function (tenantSlug, topicId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).createFaqQuestion(tenantSlug, topicId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Create a faq topic
     * @param {string} tenantSlug
     * @param {FAQTopicResource} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.createFaqTopic = function (tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).createFaqTopic(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete a document
     * @param {string} id
     * @param {string} tenantId
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.deleteDocument = function (id, tenantId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).deleteDocument(id, tenantId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete a question
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} topicId
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.deleteFaqQuestion = function (id, tenantSlug, topicId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).deleteFaqQuestion(id, tenantSlug, topicId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete a faq topic
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.deleteFaqTopic = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).deleteFaqTopic(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get a document
     * @param {string} id
     * @param {string} tenantId
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.getDocument = function (id, tenantId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).getDocument(id, tenantId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {string} tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.getDocumentTypeById = function (id, tenantId, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).getDocumentTypeById(id, tenantId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} tenantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.getDocumentTypes = function (tenantId, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).getDocumentTypes(tenantId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get a question
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} topicId
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.getFaqQuestion = function (id, tenantSlug, topicId, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).getFaqQuestion(id, tenantSlug, topicId, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get all question
     * @param {string} tenantSlug
     * @param {string} topicId
     * @param {string} [search] A search term.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.getFaqQuestions = function (tenantSlug, topicId, search, page, pageSize, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).getFaqQuestions(tenantSlug, topicId, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get a faq topic
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.getFaqTopic = function (id, tenantSlug, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).getFaqTopic(id, tenantSlug, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get all faq topic
     * @param {string} tenantSlug
     * @param {string} [search] A search term.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.getFaqTopics = function (tenantSlug, search, page, pageSize, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).getFaqTopics(tenantSlug, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get all document
     * @param {string} tenantId
     * @param {string} [search] A search term.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.getListDocument = function (tenantId, search, page, pageSize, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).getListDocument(tenantId, search, page, pageSize, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get platform faq
     * @summary Get platform faq
     * @param {string} platformSlug
     * @param {string} tenantSlug
     * @param {boolean} [adsBlock] Get specify QnA resolve ads block
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.getPlatformQnas = function (platformSlug, tenantSlug, adsBlock, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).getPlatformQnas(platformSlug, tenantSlug, adsBlock, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Sort list questions
     * @param {string} tenantSlug
     * @param {string} topicId
     * @param {FAQQnAResource} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.sortFaqQuestions = function (tenantSlug, topicId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).sortFaqQuestions(tenantSlug, topicId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Sort list faq
     * @param {string} tenantSlug
     * @param {FAQTopicResource} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.sortFaqTopic = function (tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).sortFaqTopic(tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update a document
     * @param {string} id
     * @param {string} tenantId
     * @param {Document} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.updateDocument = function (id, tenantId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).updateDocument(id, tenantId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update a question
     * @param {string} id
     * @param {string} tenantSlug
     * @param {string} topicId
     * @param {FAQQnAResource} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.updateFaqQuestion = function (id, tenantSlug, topicId, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).updateFaqQuestion(id, tenantSlug, topicId, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update a faq topic
     * @param {string} id
     * @param {string} tenantSlug
     * @param {FAQTopicResource} data
     * @param {string} [authorization] user access token for future use
     * @param {string} [acceptLanguage] For response message in difference language en/vi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentApi
     */
    AdminDocumentApi.prototype.updateFaqTopic = function (id, tenantSlug, data, authorization, acceptLanguage, options) {
        var _this = this;
        return (0, exports.AdminDocumentApiFp)(this.configuration).updateFaqTopic(id, tenantSlug, data, authorization, acceptLanguage, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AdminDocumentApi;
}(base_1.BaseAPI));
exports.AdminDocumentApi = AdminDocumentApi;
